
import React, { useState } from 'react';

const FDInput = (props) => {
    const { handleAmount, handleRate, handleDuration, handleTenureUnit, calculateSI,calculateCI} = props;

    const [tenureUnit, setTenureUnit] = useState('years');

    const handleTenureUnitChange = (e) => {
        setTenureUnit(e.target.value);
        handleTenureUnit(e.target.value);
    };

    return (
        <div className="container mt-2 my-2 ">
            <h4 className="text-center mb-2 fs-2 font-weight-bold me-md-4">FD Calculator</h4>
            <div className="row justify-content-center">
                <div className="col-md-12 me-md-auto">
                    <div className="card shadow-sm border-1 custom-form-width">
                        <div className="card-body">
                            <form>
                                <div className="custom-form mb-3">
                                    <label htmlFor="principal">Total Investment:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        min="0"
                                        placeholder="Enter principal amount"
                                        onChange={(e) => handleAmount(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="interestRate">Interest Rate (%):</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        min="0"
                                        placeholder="Enter annual interest rate"
                                        onChange={(e) => handleRate(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="tenure">Time Period:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        min="0"
                                        placeholder="Enter time period"
                                        onChange={(e) => handleDuration(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="tenureUnit">Time Unit:</label>
                                    <select
                                        id="tenureUnit"
                                        value={tenureUnit}
                                        onChange={handleTenureUnitChange}
                                        className="form-control"
                                    >
                                        <option value="years">Years</option>
                                        <option value="months">Months</option>
                                        <option value="days">Days</option>
                                    </select>
                                </div>
                                <button type="submit" className="btn btn-primary btn-block"onClick={calculateSI}>
                                    Simple Interest FD
                                </button>
                                <button type="submit" className="btn btn-primary btn-block"onClick={calculateCI}>
                                    Compound Interest FD
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FDInput;
