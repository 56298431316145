// Loader.js
import React from 'react';
import './Loader.css'; // Import the CSS file for the loader

const Loader = () => {
    return (
        <div className="loader-container">
            <div className="loader-text">
                Loading<span className="loader-dots">
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                </span>
            </div>
        </div>
    );
};

export default Loader;