import React from "react";
import softwareIcon from "../../assets/images/Technology/111.png";
import fintechIcon from "../../assets/images/Technology/222.png";
import healthcareIcon from "../../assets/images/Technology/333.png";
import dataMiningIcon from "../../assets/images/Technology/444.png";
import machineLearningIcon from "../../assets/images/Technology/555.png";
import medicalIcon from "../../assets/images/Technology/666.png";
import entertainmentIcon from "../../assets/images/Technology/777.png";
import aiIcon from "../../assets/images/Technology/888.png";
import manufacturingIcon from "../../assets/images/Technology/999.png";
import logisticsIcon from "../../assets/images/Technology/1000.png";

import javaIcon from "../../assets/images/Technology/1.png";
import dynamoDBIcon from "../../assets/images/Technology/2.png";
import awsIcon from "../../assets/images/Technology/3.png";
import stormIcon from "../../assets/images/Technology/4.png";
import dockerIcon from "../../assets/images/Technology/5.png";
import tensorflowIcon from "../../assets/images/Technology/6.png";
import nodejsIcon from "../../assets/images/Technology/7.png";
import oracleIcon from "../../assets/images/Technology/8.png";
import jmeterIcon from "../../assets/images/Technology/9.png";
import mongoDBIcon from "../../assets/images/Technology/10.png";
import mysqlIcon from "../../assets/images/Technology/11.png";
import neo4jIcon from "../../assets/images/Technology/12.png";
import sparkIcon from "../../assets/images/Technology/13.png";
import pythonIcon from "../../assets/images/Technology/14.png";
import kibanaIcon from "../../assets/images/Technology/15.png";
import firebaseIcon from "../../assets/images/Technology/16.png";
import clouderaIcon from "../../assets/images/Technology/17.png";
import redshiftIcon from "../../assets/images/Technology/18.png";

const Technology = () => {
    return (
      <>
        <div class="rs-breadcrumbs img1">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Our Technologies</h1>
        </div>
      </div>
  
        {/* First Section - Industry Solutions */}
        <section
          className="solutions text-center"
          style={{
            padding: "40px 0",
            backgroundColor: "#ffffff",
          }}
        >
          
          <br />
          <h2
            style={{
              fontSize: "28px",
              marginBottom: "30px",
              fontWeight: "700",
              lineHeight: "1.4",
              color: "#0a0a0a",
              maxWidth: "490px",
              margin: "0 auto",
            }}
          >
            What Technology We Are Using
            <br /> For Our Valued Customers
          </h2>
          <br />
          <br />
          <div
            className="solution-cards"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr)", // Ensure 5 cards per row
              gap: "15px",
              justifyContent: "center",
              maxWidth: "1000px", // Width adjusted for correct card size and spacing
              margin: "0 auto",
              width:"100%",
              height:"100%",
            }}
          >
            {[
              { icon: javaIcon, text: "Software" },
              { icon: awsIcon, text: "Fintech" },
              { icon: dockerIcon, text: "Healthcare" },
              { icon: nodejsIcon, text: "Data Mining" },
              { icon: mongoDBIcon, text: "Machine Learning" },
              { icon: mysqlIcon, text: "Medical" },
              { icon: pythonIcon, text: "Entertainment" },
              { icon: firebaseIcon, text: "AI" },
              { icon: sparkIcon, text: "Manufacturing" },
              { icon: jmeterIcon, text: "Logistics" },
            ].map((item, index) => (
              <div
                key={index}
                className="card"
                style={{
                  border: "1px solid #f0f0f0",
                  borderRadius: "5px",
                  backgroundColor: "#ffffff",
                  padding: "10px 0",
                  boxShadow: "20px 20px 50px 20px rgba(0.04, 0.04, 0.04, 0.04)",
                  textAlign: "center",
                  boxSizing: "border-box",
                  width: "220px",
                  height:"170px",
                  float:"left",
                  //margin:"0px 20px 0px 20px",

                }}
              >
                <img
                  src={item.icon}
                  alt={item.text + " Icon"}
                  className="card-img-top"
                  style={{
                    width: "150px",
                    height: "70px",
                    marginBottom: "10px",
                    margin: "auto",
                    padding:"auto",
                    paddingTop:"20px",
                  }}
                />
                <div className="card-body">
                  <p
                    className="card-text"
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#000000",
                      margin: 0,
                      fontFamily: "'Livvic', sans-serif",
                    }}
                  >
                    {item.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </section>
<br />
        {/* Second Section - Technology Index */}
      <section
        className="technology-section text-center"
        style={{
          padding: "40px 0",
          backgroundColor: "#f8f9fa",
        }}
      >
        
        <div
          className="submit-btn-container"
          style={{
            marginTop: "40px",
          }}
        >
          
        </div>
      </section>
    </>
  );
};

export default Technology;