import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const FDChart = (props) => {
    const { interest, amount, tenureUnit } = props;
    const data = {
        labels: ['Total Investment', 'Total Returns'],
        datasets: [
            {
                data: [amount, interest],
                backgroundColor: ['#dae2e6', '#3f5bd4'],
            }
        ]
    };

    const options = {
        cutout: '50%', 
        responsive: true,
       
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    padding: 35, 
                },
                font: {
                    size: 16, // Adjust font size for legend labels
                }
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const label = tooltipItem.label || '';
                        const value = tooltipItem.raw;
                        return `${label}: ${value}`;
                    },
                    bodyFont: {
                        size: 14, 
                    },
                    titleFont: {
                        size: 16,
                    }
                }
            }
        }
    };

    return (
        <div className='text-center p-2'>
            <h4 className="fs-4 font-weight-bold">FD Chart ({tenureUnit})</h4>
            <br/>
            <Doughnut data={data} options={options}  />
        </div>
    );
};

export default FDChart;
