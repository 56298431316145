import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement);

const EMIPieChart = ({ principal, totalInterest }) => {
  const data = {
    labels: ['Principal Amount', 'Interest Amount'],
    datasets: [
      {
        label: 'EMI Breakdown',
        data: [principal, totalInterest],
        backgroundColor: ['#36A2EB', '#FF6384'],
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            return tooltipItem.label + ': ₹' + tooltipItem.raw;
          }
        }
      }
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="col-md-4 mb-4">
      <div className="card" style={{ height: '400px' }}> 
        <div className="card-header">
          <h5 className="card-title text-center">EMI Breakdown</h5>
        </div>
        <div className="card-body" style={{ height: '100%' }}>
          <Doughnut data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default EMIPieChart;
