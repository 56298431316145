import React from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const WebDevelopment = () => {
    const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/contact');
  };
    
  return (
    <>
    
        <div class="rs-breadcrumbs img3">
            <div class="breadcrumbs-inner text-center">
                <h1 class="page-title">Web Development</h1>
                {/* <ul>
                    <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                        <a class="active" href="index.html">Home</a>
                    </li>
                    <li title="Go To Services">
                        <a class="active" href="index.html">Services</a>
                    </li>
                    <li>Web Development</li>
                </ul> */}
            </div>
        </div>
        
        <div class="rs-services-single pt-120 pb-120 md-pt-80 md-pb-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 md-mb-50">
                        <div class="services-img">
                            <img src="..\assets\images\services\single/1.jpg" alt=""/>
                        </div>
                        <h2 class="mt-34">Responsive Pixel Perfect Design</h2>
                        <p>
                            Cras enim urna, interdum nec porttitor vitae, sollicitudin eu eros. Praesent eget mollis nulla, non lacinia urna. Donec sit amet neque auctor, ornare dui rutrum, condimentum justo. Duis dictum, ex accumsan eleifend eleifend, ex justo aliquam nunc, in ultrices ante quam eget massa. Sed scelerisque, odio eu tempor pulvinar, magna tortor finibus lorem, ut mattis tellus nunc ut quam. Curabitur quis ornare leo. Suspendisse bibendum nibh non turpis vestibulum pellentesque. 
                        </p>
                        <ul class="listing-style">                              
                            <li>
                                <i class="fa fa-check-circle"></i>
                                <span>Sed ut perspiciatis unde omnis iste natus error</span>
                            </li>
                            <li>
                                <i class="fa fa-check-circle"></i>
                                <span>Nemo enim ipsam voluptatem quia voluptas sit aspernatur</span>
                            </li>
                            <li>
                                <i class="fa fa-check-circle"></i>
                                <span>Accusamus et iusto odio dignissimos ducimus qui blanditiis</span>
                            </li>
                            <li>
                                <i class="fa fa-check-circle"></i>
                                <span>Nam libero tempore, cum soluta nobis est eligendi optio cumque</span>
                            </li>                                
                        </ul>
                        <h3 class="mt-34">Why Work With Us</h3>
                             
                        <div class="rs-skillbar style1 modify1">
                            <div class="cl-skill-bar">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <span class="skillbar-title">Software Development</span>
                                        <div class="skillbar" data-percent="92">
                                            <p class="skillbar-bar"></p>
                                            <span class="skill-bar-percent"></span> 
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <span class="skillbar-title">Web Development</span>
                                        <div class="skillbar" data-percent="90">
                                            <p class="skillbar-bar"></p>
                                            <span class="skill-bar-percent"></span> 
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <span class="skillbar-title">SEO Analysis</span>
                                        <div class="skillbar" data-percent="85">
                                            <p class="skillbar-bar"></p>
                                            <span class="skill-bar-percent"></span> 
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <span class="skillbar-title">Cyber Security</span>
                                        <div class="skillbar" data-percent="92">
                                            <p class="skillbar-bar"></p>
                                            <span class="skill-bar-percent"></span> 
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-6">
                                        <span class="skillbar-title">Clean Code</span>
                                        <div class="skillbar" data-percent="92">
                                            <p class="skillbar-bar"></p>
                                            <span class="skill-bar-percent"></span> 
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <span class="skillbar-title">App Development</span>
                                        <div class="skillbar" data-percent="92">
                                            <p class="skillbar-bar"></p>
                                            <span class="skill-bar-percent"></span> 
                                        </div>
                                    </div>
                                </div>                                   
                            </div>
                        </div>
                           
                        <h3 class="mt-30">24/7 Smart Support</h3>
                        <p class="mt-20 mb-50">
                            Cras enim urna, interdum nec porttitor vitae, sollicitudin eu eros. Praesent eget mollis nulla, non lacinia urna. Donec sit amet neque auctor, ornare dui rutrum, condimentum justo. Duis dictum, ex accumsan eleifend eleifend, ex justo aliquam nunc, in ultrices ante quam eget massa. Sed scelerisque, odio eu tempor pulvinar, magna tortor finibus lorem.
                        </p>
                        <div class="row mb-80">
                            <div class="col-lg-6 col-md-12 md-mb-30">
                                <img class="bdru-4" src="../assets/images/services/single/2.jpg" alt=""/>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <img class="bdru-4" src="../assets/images/services/single/3.jpg" alt=""/>
                            </div>
                        </div>
                            
                        <div class="rs-testimonial style5">
                            <div class="testi-item">
                                <div class="testi-img">
                                    <img src="assets/images/testimonial/main-home/quote-white2.png" alt=""/>
                                </div>
                                <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias data.</p>
                                <div class="testi-content">
                                    <div class="testi-img">
                                        <img src="../assets/images/testimonial/main-home/5.jpg" alt=""/>
                                    </div>
                                    <div class="author-part">
                                        <div class="name">Brick John</div>
                                        <span class="designation">CEO, Brick Consulting</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 pl-32 md-pl-15">
                        <ul class="services-list">
                            <li><a href="software-development.html">Software Development</a></li>
                            <li><a class="active" href="#">Web Development</a></li>
                            <li><a href="case-studies-single.html">Analytic Solutions</a></li>
                            <li><a href="cloud-and-devops.html">Cloud and DevOps</a></li>
                            <li><a href="product-design.html">Product Design</a></li>
                            <li><a href="#">Data Center</a></li>
                        </ul>
                        <div class="services-add mb-50 mt-50">
                            <div class="address-item mb-35">
                                <div class="address-icon">
                                    <i class="fa fa-phone"></i>
                                </div>
                            </div>
                            <h2 class="title">Have any Questions? <br></br> Call us Today!</h2>
                            <div class="contact">
                                <a href="tel:123222-8888">(123) 222-8888</a>
                            </div>
                        </div>
                        <div class="brochures">
                            <h3>Brochures</h3>
                            <p>
                                Cras enim urna, interdum nec por ttitor vitae, sollicitudin eu erosen. Praesent eget mollis nulla sollicitudin.
                            </p>
                            <div class="pdf-btn">
                            <button onClick={handleButtonClick} class="readon learn-more pdf">Download Now <i class="fa fa-file-pdf-o"></i></button>
                                {/* <a class="readon learn-more pdf" href="contact.html">Download Now<i class="fa fa-file-pdf-o"></i></a> */}
                            </div>
                        </div>
                    </div>
                </div> 
            </div> 
        </div>
        
        <div class="rs-cta style1 bg7 pt-80 pb-80">
            <div class="container">
                <div class="cta-wrap">
                    <div class="row align-items-center">
                        <div class="col-lg-9 col-md-12 md-mb-30">
                            <span>Plan to Start a Project</span>
                            <div class="title-wrap">
                                <h2 class="epx-title">Our Experts Ready to Help You</h2>
                            </div>
                        </div>
                        <div class="col-lg-3 text-right col-md-12">
                            <div class="button-wrap">
                            <button onClick={handleButtonClick} class="readon learn-more">Get In Touch</button>
                                {/* <a class="readon learn-more" href="contact.html">Get In Touch</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
  );
}
export default WebDevelopment;
