
import React, { useState } from 'react';
import FDInput from './FDInput';
import FDResult from './FDResult';
import FDChart from './FDChart';


const FD = () => {
    const [amount, setAmount] = useState(0);
    const [rate, setRate] = useState(0);
    const [duration, setDuration] = useState(0);
    const [interest, setInterest] = useState(0);
    const [tenureUnit, setTenureUnit] = useState('years');

    const calculateSimpleInterest = (e) => {
        e.preventDefault();
        let timeInYears;
        switch (tenureUnit) {
            case 'months':
                timeInYears = duration / 12;
                break;
            case 'days':
                timeInYears = duration / 365;
                break;
            default: 
                timeInYears = duration;
                break;
        }
        let tempInterest = amount * rate * timeInYears / 100;
        setInterest(tempInterest);
    };

    const calculateCompoundInterest = (e) => {
        e.preventDefault();
        let timeInYears;
        switch (tenureUnit) {
            case 'months':
                timeInYears = duration / 12;
                break;
            case 'days':
                timeInYears = duration / 365;
                break;
            default: 
                timeInYears = duration;
                break;
        }
        let tempInterest = amount * (Math.pow(1 + rate / 100, timeInYears) - 1);
        setInterest(tempInterest);
    };


    return (
        <div className="container mt-4 mb-4">
        <div className="border p-4">
            <div className="row">
                <div className="col-6">
                
                        <FDInput
                            handleAmount={setAmount}
                            handleRate={setRate}
                            handleDuration={setDuration}
                            handleTenureUnit={setTenureUnit}
                            calculateSI={calculateSimpleInterest}
                            calculateCI={calculateCompoundInterest}
                        />
                         <FDResult
                            interest={interest}
                            duration={duration}
                            amount={amount}
                            tenureUnit={tenureUnit}
                        />
                 
                </div>
                <div className="col-6">
                        <div className="chart-container" style={{ maxWidth: '400px', margin: '0 auto' }}>
                            <FDChart
                                interest={interest}
                                amount={amount}
                                tenureUnit={tenureUnit}
                            />
                    </div>
                </div>
                   
            </div>
            
        </div>
    </div>
    );
};

export default FD;
