import React, { useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import sample_blog_image from '../assets/img/blog/inner/1.jpg';
import axios from 'axios';

const Services = () => {
  const [recentPosts, setRecentPosts] = useState([]);
  const navigate = useNavigate();
  const fetchRecentPosts = async () => {
    const url = process.env.REACT_APP_API_URL + 'blog/recentblogs';

    try {
        const response = await axios.get(url);
        console.log(response);
        
        setRecentPosts(response.data);
    } catch (err) {
        console.error('Error in API Call:', err);
    }
};
const showBlogPage = (srnum, title) => {
  window.localStorage.setItem('blogid', srnum);
  //redirect to blog page with title in url
  navigate(`/blog/${title}`)
}


useEffect(() => {
  fetchRecentPosts();
}, []);

  return (
    <>



      <div className="rs-breadcrumbs img2">
        <div className="breadcrumbs-inner text-center">
          <h1 className="page-title">Services</h1>

        </div>
      </div>
      



      <div className="rs-services style3 pt-110 pb-120 md-pt-75 md-pb-80">
        <div className="container">
          <div className="sec-title2 text-center mb-45">
            {/* <span className="sub-text">Services</span> */}
            <h2 className="title testi-title">
              Our Featured Services
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="services-item">
                <div className="services-icon">
                  <div className="image-part">
                    <img className="main-img" src="assets/images/services/style2/main-img/1.png" alt="" />
                    <img className="hover-img" src="assets/images/services/style2/hover-img/1.png" alt="" />
                  </div>
                </div>
                <div className="services-content">
                  <div className="services-text">
                    <h3 className="title"><a href="#">Web Development</a></h3>
                  </div>
                  {/* <div className="services-desc">
                    <p>
                      The only way to be truly satisfied is to do what you believe is great work.
                    </p>
                  </div> */}
                  <div className="serial-number">
                    01
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="services-item pink-bg">
                <div className="services-icon">
                  <div className="image-part">
                    <img className="main-img" src="assets/images/services/style2/main-img/2.png" alt="" />
                    <img className="hover-img" src="assets/images/services/style2/hover-img/2.png" alt="" />
                  </div>
                </div>
                <div className="services-content">
                  <div className="services-text">
                    <h3 className="title"><a href="#">Digital Marketing</a></h3>
                  </div>
                  {/* <div className="services-desc">
                    <p>
                      Digital Solutions for all business domains.
                    </p>
                  </div> */}
                  <div className="serial-number">
                    02
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="services-item aqua-bg">
                <div className="services-icon">
                  <div className="image-part">
                    <img className="main-img" src="assets/images/services/style2/main-img/3.png" alt="" />
                    <img className="hover-img" src="assets/images/services/style2/hover-img/3.png" alt="" />
                  </div>
                </div>
                <div className="services-content">
                  <div className="services-text">
                    <h3 className="title"><a href="#">UI/UX Development</a></h3>
                  </div>
                  {/* <div className="services-desc">
                    <p>
                      All web development services including CMS and E-Commerce.
                    </p>
                  </div> */}
                  <div className="serial-number">
                    03
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="services-item paste-bg">
                <div className="services-icon">
                  <div className="image-part">
                    <img className="main-img" src="assets/images/services/style2/main-img/4.png" alt="" />
                    <img className="hover-img" src="assets/images/services/style2/hover-img/4.png" alt="" />
                  </div>
                </div>
                <div className="services-content">
                  <div className="services-text">
                    <h3 className="title"><a href="#">IT Consulting</a></h3>
                  </div>
                  {/* <div className="services-desc">
                    <p>
                      The requirement for an user experience is to meet the needs of customer.
                    </p>
                  </div> */}
                  <div className="serial-number">
                    04
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="services-item purple-bg">
                <div className="services-icon">
                  <div className="image-part">
                    <img className="main-img" src="assets/images/services/style2/main-img/5.png" alt="" />
                    <img className="hover-img" src="assets/images/services/style2/hover-img/5.png" alt="" />
                  </div>
                </div>
                <div className="services-content">
                  <div className="services-text">
                    <h3 className="title"><a href="#">Software Development</a></h3>
                  </div>
                  {/* <div className="services-desc">
                    <p>
                      The only way to be truly satisfied is to do what you believe is great work.
                    </p>
                  </div> */}
                  <div className="serial-number">
                    05
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="services-item green-bg">
                <div className="services-icon">
                  <div className="image-part">
                    <img className="main-img" src="assets/images/services/style2/main-img/6.png" alt="" />
                    <img className="hover-img" src="assets/images/services/style2/hover-img/6.png" alt="" />
                  </div>
                </div>
                <div className="services-content">
                  <div className="services-text">
                    <h3 className="title"><a href="#">Mobile App Development</a></h3>
                  </div>
                  {/* <div className="services-desc">
                    <p>
                      Responsive design is an approach that suggets the design to the behaviour.
                    </p>
                  </div> */}
                  <div className="serial-number">
                    06
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-20">
              <div className="services-item">
                <div className="services-icon">
                  <div className="image-part">
                    <img className="main-img" src="assets/images/services/style2/main-img/1.png" alt="" />
                    <img className="hover-img" src="assets/images/services/style2/hover-img/1.png" alt="" />
                  </div>
                </div>
                <div className="services-content">
                  <div className="services-text">
                    <h3 className="title"><a href="#">CMS and E-Commerce</a></h3>
                  </div>
                  {/* <div className="services-desc">
                    <p>
                      The only way to be truly satisfied is to do what you believe is great work.
                    </p>
                  </div> */}
                  <div className="serial-number">
                    07
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="services-item pink-bg">
                <div className="services-icon">
                  <div className="image-part">
                    <img className="main-img" src="assets/images/services/style2/main-img/2.png" alt="" />
                    <img className="hover-img" src="assets/images/services/style2/hover-img/2.png" alt="" />
                  </div>
                </div>
                <div className="services-content">
                  <div className="services-text">
                    <h3 className="title"><a href="#">ERP and CRM Develpment </a></h3>
                  </div>
                  {/* <div className="services-desc">
                    <p>
                      Digital Solutions for all business domains.
                    </p>
                  </div> */}
                  <div className="serial-number">
                    08
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="services-item aqua-bg">
                <div className="services-icon">
                  <div className="image-part">
                    <img className="main-img" src="assets/images/services/style2/main-img/3.png" alt="" />
                    <img className="hover-img" src="assets/images/services/style2/hover-img/3.png" alt="" />
                  </div>
                </div>
                <div className="services-content">
                  <div className="services-text">
                    <h3 className="title"><a href="#">Cloud and DevOps</a></h3>
                  </div>
                  {/* <div className="services-desc">
                    <p>
                      All web development services including CMS and E-Commerce.
                    </p>
                  </div> */}
                  <div className="serial-number">
                    09
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      {/* <div class="gray-color">
        <div class="rs-process modify1 pt-160 pb-120 md-pt-75 md-pb-80">
          <div class="shape-animation">
            <div class="shape-process">
              <img class="dance2" src="assets/images/process/circle.png" alt="images" />
            </div>
          </div>
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-4 pr-40 md-pr-15 md-pb-80">
                <div class="process-wrap md-center">
                  <div class="sec-title mb-30">
                    <div class="sub-text new">Working Process</div>
                    <h2 class="title white-color">
                      Our Working Process -  How We Work For Our Customers
                    </h2>
                  </div>
                  <div class="btn-part mt-40">
                    <a class="readon started" href="#">Contact Us</a>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 sm-pl-40 sm-pr-20">
                <div class="row">
                  <div class="col-md-6 mb-70">
                    <div class="rs-addon-number">
                      <div class="number-text">
                        <div class="number-area">
                          1
                        </div>
                        <div class="number-title">
                          <h3 class="title"> Discovery</h3>
                        </div>
                        <p class="number-txt">  Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-70">
                    <div class="rs-addon-number">
                      <div class="number-text">
                        <div class="number-area green-bg">
                          2
                        </div>
                        <div class="number-title">
                          <h3 class="title">Planning</h3>
                        </div>
                        <p class="number-txt">Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 sm-mb-70">
                    <div class="rs-addon-number">
                      <div class="number-text">
                        <div class="number-area plum-bg">
                          3
                        </div>
                        <div class="number-title">
                          <h3 class="title">Execute</h3>
                        </div>
                        <p class="number-txt">Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="rs-addon-number">
                      <div class="number-text">
                        <div class="number-area pink-bg">
                          4
                        </div>
                        <div class="number-title">
                          <h3 class="title">Deliver</h3>
                        </div>
                        <p class="number-txt">Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}







      <div class="rs-why-choose pt-120 pb-120 md-pt-75 md-pb-80">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 md-pb-60">
              <div class="sec-title mb-40">
                <div class="sub-text style4-bg">Why Choose Us</div>
                <h2 class="title pb-20">
                  We Create Result-Oriented Dynamic Applications
                </h2>
                <div class="desc">
                  Over 10 years working in IT services developing software applications and mobile apps for clients all over the world.
                </div>
              </div>
              <div class="services-wrap mb-25">
                <div class="services-icon">
                  <img src="assets/images/choose/icons/1.png" alt="" />
                </div>
                <div class="services-text">
                  <h3 class="title"><a href="#">First Growing Process</a></h3>
                  <p class="services-txt">  &nbsp;</p>
                </div>
              </div>
              <div class="services-wrap mb-25">
                <div class="services-icon">
                  <img src="assets/images/choose/icons/2.png" alt="" />
                </div>
                <div class="services-text">
                  <h3 class="title"><a href="#">Clean code & Well Documentation</a></h3>
                  <p class="services-txt">  &nbsp;</p>
                </div>
              </div>
              <div class="services-wrap">
                <div class="services-icon">
                  <img src="assets/images/choose/icons/3.png" alt="" />
                </div>
                <div class="services-text">
                  <h3 class="title"><a href="#">Stategy Analysis and Business Innovation</a></h3>
                  <p class="services-txt">  &nbsp;</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="image-part">
                <img src="assets/images/choose/1.png" alt="" />
              </div>
              <div class="animation">
                <div class="top-shape">
                  <img class="dance" src="assets/images/choose/dotted-2.png" alt="images" />
                </div>
                <div class="bottom-shape">
                  <img class="dance2" src="assets/images/choose/dotted-1.png" alt="images" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* technology section start */}
      <div class="rs-technology style2 bg11 pt-110 pb-115 md-pt-75 md-pb-80">
        <div class="container">
          <div class="sec-title2 text-center mb-45">
            <span class="sub-text white-color">Technology Index</span>
            <h2 class="title title2 white-color">
              What Technology We Are Using For Our Valued Customers
            </h2>
          </div>
          <div class="row">
            <div class="col-lg-2 col-md-4 col-sm-6 md-pb-30">
              <div class="technology-wrap">
                <div class="rs-grid-figure">
                  <div class="logo-img">
                    <a href="#">
                      <img class="hover-img" src="assets/images/technology/style4/hover-img/1.png" alt="hover-image" />
                      <img class="main-img" src="assets/images/technology/style4/main-img/1.png" alt="hover-image" />
                    </a>
                  </div>
                </div>
                <div class="logo-title">
                  <h4 class="title">Node Js</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 md-pb-30">
              <div class="technology-wrap">
                <div class="rs-grid-figure">
                  <div class="logo-img">
                    <a href="#">
                      <img class="hover-img" src="assets/images/technology/style4/hover-img/2.png" alt="grid-image" />
                      <img class="main-img" src="assets/images/technology/style4/main-img/2.png" alt="grid-image" />
                    </a>
                  </div>
                </div>
                <div class="logo-title">
                  <h4 class="title">Python</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 md-pb-30">
              <div class="technology-wrap">
                <div class="rs-grid-figure">
                  <div class="logo-img">
                    <a href="#">
                      <img class="hover-img" src="assets/images/technology/style4/hover-img/3.png" alt="grid-image" />
                      <img class="main-img" src="assets/images/technology/style4/main-img/3.png" alt="grid-image" />
                    </a>
                  </div>
                </div>
                <div class="logo-title">
                  <h4 class="title"> My Sql</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 sm-pb-30">
              <div class="technology-wrap">
                <div class="rs-grid-figure">
                  <div class="logo-img">
                    <a href="#">
                      <img class="hover-img" src="assets/images/technology/style4/hover-img/4.png" alt="grid-image" />
                      <img class="main-img" src="assets/images/technology/style4/main-img/4.png" alt="grid-image" />
                    </a>
                  </div>
                </div>
                <div class="logo-title">
                  <h4 class="title">Firebase</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 sm-pb-30">
              <div class="technology-wrap">
                <div class="rs-grid-figure">
                  <div class="logo-img">
                    <a href="#">
                      <img class="hover-img" src="assets/images/technology/style4/hover-img/5.png" alt="grid-image" />
                      <img class="main-img" src="assets/images/technology/style4/main-img/5.png" alt="grid-image" />
                    </a>
                  </div>
                </div>
                <div class="logo-title">
                  <h4 class="title">Tensorflow</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 sm-pb-30">
              <div class="technology-wrap">
                <div class="rs-grid-figure">
                  <div class="logo-img">
                    <a href="#">
                      <img class="hover-img" src="assets/images/technology/style4/hover-img/6.png" alt="grid-image" />
                      <imcd g class="main-img" src="assets/images/technology/style4/main-img/6.png" alt="grid-image" />
                    </a>
                  </div>
                </div>
                <div class="logo-title">
                  <h4 class="title">Docker</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* technology section ends */}

      {/* Get in touch start */}
      <div class="rs-cta style1 bg7 pt-80 pb-70">
        <div class="container">
          <div class="cta-wrap">
            <div class="row align-items-center">
              <div class="col-lg-9 col-md-12 md-mb-30">
                <span>Plan to Start a Project</span>
                <div class="title-wrap">
                  <h2 class="epx-title">Our Experts Ready to Help You</h2>
                </div>
              </div>
              <div class="col-lg-3 text-right md-text-left col-md-12">
                <div class="button-wrap">
                  <Link class="readon learn-more" to="/contact">Get In Touch</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Blog Section Start --> */}
            <div id="rs-blog" class="rs-blog style2 pt-100 pb-100 md-pt-80 md-pb-80">
                <div class="container">  
                    <div class="sec-title5 mb-60 text-center">
                        <div class="logo-icon">
                            <img src="assets/images/tips.png"alt=''></img>
                        </div>
                        <h2 class="title">Recent <span>Blogs</span> Updates</h2>
                        <p class="desc">
                            We've been building creative tools together for over a decade and have a deep appreciation for software applications and  AI tools.
                        </p>
                    </div>
                    <div class="container">
                    <div className="container">
      <div className="row">
      {recentPosts.slice(0, 3).map((post, index) => (
          <div className="col-md-4" key={index} onClick={() => showBlogPage(post.srno, post.posttitle)}>
            <div className="blog-item"> 
              <div className="image-wrap">
              <img 
                    src={sample_blog_image || post.featuredimag } 
                    className="img-fluid" 
                    alt={post.title} 
                  />
                <ul className="post-categories">
                  <li><a href="">{post.category}</a></li>
                </ul>
              </div>
              <div className="blog-content">
                <ul className="blog-meta list-unstyled">
                  <li className="date"><i className="fa fa-calendar-check-o"></i> {new Date(post.createdon).toLocaleDateString()}</li>
                  <li className="admin"><i className="fa fa-user-o"></i> {post.author}</li>
                </ul>
                <h3 className="blog-title">
                  <a href="">{post.posttitle}</a>
                </h3>
                <p className="desc">{post.postheading}</p>
                <div className="blog-button style2">
                  <a href="" className="btn btn-primary btn-sm">Learn More</a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
</div>

</div>

</div>
            {/* <!-- Blog Section End --> */}
      {/* Get in touch end */}

      {/* Request a free quote start */}
      {/* <div class="rs-video-wrap style3 pt-120 pb-120 md-pt-80 md-pb-80">
        <div class="container">
          <div class="row margin-0 grdiant-bg align-items-center"> */}
            {/* <div class="col-lg-6 padding-0">
                            <div class="video-item">
                                <div class="rs-videos">
                                    <div class="animate-border main-home style2">
                                        <a class="popup-border popup-videos" href="https://www.youtube.com/watch?v=YLN1Argi7ik">
                                            <i class="fa fa-play"></i>
                                        </a>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div class="col-lg-6 padding-0">
                            <div class="rs-requset">
                                <div class="sec-title2 mb-33">
                                    <span class="sub-text white-color">Let's Talk</span>
                                    <h2 class="title testi-title white-color">
                                       Request a Free Quote
                                    </h2>
                                </div>
                                <div id="form-messages"></div>
                                <form id="contact-form" method="post" action="https://rstheme.com/products/html/braintech/mailer.php">
                                    <fieldset>
                                        <div class="row">
                                            <div class="col-lg-6 mb-25 col-md-6 col-sm-6">
                                                <input class="from-control" type="text" id="name" name="name" placeholder="Name" required=""/>
                                            </div> 
                                            <div class="col-lg-6 mb-25 col-md-6 col-sm-6">
                                                <input class="from-control" type="text" id="email" name="email" placeholder="E-Mail" required=""/>
                                            </div>   
                                            <div class="col-lg-6 mb-25 col-md-6 col-sm-6">
                                                <input class="from-control" type="text" id="phone" name="phone" placeholder="Phone Number" required=""/>
                                            </div>   
                                            <div class="col-lg-6 mb-25 col-md-6 col-sm-6">
                                                <input class="from-control" type="text" id="website" name="website" placeholder="Your Website" required=""/>
                                            </div>
                                      
                                            <div class="col-lg-12 mb-35">
                                                <textarea class="from-control" id="message" name="message" placeholder="Your message Here" required=""></textarea>
                                            </div>
                                        </div>
                                        <div class="btn-part">
                                            <input class="readon learn-more submit" type="submit" value="Submit Now"/>
                                        </div> 
                                    </fieldset>
                                </form> 
                            </div>
                        </div> */}
          {/* </div>
        </div>
      </div> */}

      {/*Request a free quote end  */}

      {/* <div class="our-customer pt-200 pb-100 bg-curve">
                <div class="container-fluid">
                    <div class="our-customer-wrapper">
                        <div class="row d-flex justify-content-center">
                            <div class="col-xl-8">
                                <div class="section-tittle text-center">
                                    <h2>Our Technologies</h2>
                                </div>
                            </div>
                        </div>
                        <div class="row our-technologies">
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={angular} alt="" className='img-fluid' />
                                    <p>AngularJS</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={reactlogo} alt="" className='img-fluid' />
                                    <p>React</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={nextjs} alt="" className='img-fluid' />
                                    <p>NextJS</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={node} alt="" className='img-fluid' />
                                    <p>NodeJS</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={vue} alt="" className='img-fluid' />
                                    <p>VueJS</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={nuxt} alt="" className='img-fluid' />
                                    <p>NuxtJS</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={java} alt="" className='img-fluid' />
                                    <p>Java</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={python} alt="" className='img-fluid' />
                                    <p>Python</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={aws} alt="" className='img-fluid' />
                                    <p>AWS</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={googlecloud} alt="" className='img-fluid' />
                                    <p>Google Cloud</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={mongodb} alt="" className='img-fluid' />
                                    <p>MongoDB</p>
                                </div>
                            </div>
                            <div class="col-2">
                                <div className='tech-div'>
                                    <img src={mysql} alt="" className='img-fluid' />
                                    <p>MySQL</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div > */}



    </>
  )
}

export default Services
