
import React from 'react';

const FDResult = (props) => {
    const { interest, amount, tenureUnit, duration } = props;

    // Helper function to format duration based on unit
    const calculateTotalAmount = () => {
        return parseFloat(amount) + parseFloat(interest);
    };

    const totalAmount = calculateTotalAmount();
    const investedAmount = parseFloat(amount).toFixed(2);
    const estimatedReturns = parseFloat(interest).toFixed(2);
    const totalValue = totalAmount.toFixed(2);

    return (
        <div>
            <p className="mt-4 mb-1 text-center">
                  <span className="font-weight-bold fs-6">Invested Amount: </span>₹{' '}
                  {investedAmount}
                </p>
            <p className="mt-1 mb-1 text-center">
                    <span className="font-weight-bold fs-6">Estimated Returns: </span>₹{' '}
                    {estimatedReturns}
                  </p>
            
            <p className="mt-1 mb-1 text-center">
                    <span className="font-weight-bold fs-6">Total Value: </span>₹{' '}
                    {totalValue}
                  </p>
         
        </div>
    );
};

export default FDResult;
