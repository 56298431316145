import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import sample_blog_image from '../assets/img/blog/inner/1.jpg';

import RecentBlogs from './blog/RecentBlogs';
import BlogCategories from './blog/BlogCategories';
import Loader from './Loader'; // Import the Loader component

const Blog = () => {
    const [blogsData, setBlogsData] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state

    const navigate = useNavigate();

    const fetchBlogs = async () => {
        const url = process.env.REACT_APP_API_URL + 'blog/fetchblogsheading/0';

        try {
            const response = await axios.get(url);
            setBlogsData(response.data);
        } catch (err) {
            console.error('Error in API Call:', err);
        } finally {
            setLoading(false); // Set loading to false after fetching data
        }
    };

    const showBlogPage = (srnum, title) => {
        window.localStorage.setItem('blogid', srnum);
        navigate(`/blog/${title}`);
    }

    const stripHtmlTags = (html) => {
        const cleanHtml = html.replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>?/gi, '');
        return cleanHtml;
    };

    useEffect(() => {
        fetchBlogs();
    }, []);

    return (
        <>
            <div className="rs-breadcrumbs img4">
                <div className="breadcrumbs-inner text-center">
                    <h1 className="page-title">Blog - Thoughts, Ideas and Tech Articles</h1>
                </div>
            </div>

            <div className="rs-inner-blog pt-120 pb-120 md-pt-90 md-pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 order-last">
                            <div className="widget-area">
                                <BlogCategories />
                                <RecentBlogs />
                            </div>
                        </div>
                        <div className="col-lg-8 pr-35 md-pr-15">
                            {loading ? ( // Show loader while loading
                                <Loader />
                            ) : (
                                <div className="row">
                                    {blogsData.map((item, index) => (
                                        <div className="col-lg-12 mb-50" key={index}>
                                            <div className="blog-item">
                                                <div className="blog-img" onClick={() => showBlogPage(item.srno, item.posttitle)}>
                                                    <a>
                                                        <img
                                                            src={item.featuredimage || "../../assets/images/blog/inner/1.jpg"}
                                                            alt={item.featuredimage ? "Featured Image" : "Default Image"}
                                                            onError={(e) => {
                                                                e.target.style.display = "none";
                                                                const fallbackImage = document.createElement("img");
                                                                fallbackImage.src = "../../assets/images/blog/inner/1.jpg";
                                                                fallbackImage.alt = "Default Image";
                                                                e.target.parentNode.appendChild(fallbackImage);
                                                            }}
                                                        />
                                                    </a>
                                                    <ul className="post-categories" style={{ left: "20px" }}>
                                                        <li>
                                                            <a to='#' >
                                                                {item.category}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="blog-content">
                                                    <h3 className="blog-title">
                                                        <a to='#' onClick={() => showBlogPage(item.srno, item.posttitle)}>
                                                            {item.postheading}
                                                        </a>
                                                    </h3>
                                                    <div className="blog-meta">
                                                        <ul className="btm-cate">
                                                            <li>
                                                                <div className="blog-date">
                                                                    <i className="fa fa-calendar-check-o"></i> {new Date(item.createdon).toLocaleDateString()}
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="author">
                                                                    <i className="fa fa-user-o"></i> {item.author}
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="blog-button inner-blog">
                                                        <a className="blog-btn" onClick={() => showBlogPage(item.srno, item.posttitle)}>Continue Reading</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Blog;
