import React from "react";
import { FaPhoneVolume } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import TestimonialsCarousel from "./learning/TestimonialsCarousel";
import '../App.css'

const Learning = () => {
  return (
    <div>
      <>
        {/* Breadcrumbs Start */}
        <div className="rs-breadcrumbs img1">
          <div className="breadcrumbs-inner text-center">
            <h1 className="page-title">Learning</h1>
            {/* <ul>
              <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                <a className="active" href="index.html">
                  Home
                </a>
              </li>
              <li>Learning</li>
            </ul> */}
          </div>
        </div>
        {/* Breadcrumbs End */}
      </>


      <>
        {/* Services Section Start */}
        <div className="rs-services style3 pt-120 pb-0 md-pt-75 md-pb-80">
          <div className="container">
            <div className="sec-title2 text-center mb-45">
              {/*<span className="sub-text">Services</span>*/}
              <h2 className="title testi-title">Learning Opportunities @HashedBit</h2>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-20">
                <div className="services-item">
                  <div className="services-icon">
                    <div className="image-part">
                      <img
                        className="main-img"
                        src="assets/images/services/style2/main-img/1.png"
                        alt=""
                      />
                      <img
                        className="hover-img"
                        src="assets/images/services/style2/hover-img/1.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="services-content">
                    <div className="services-text">
                      <h3 className="title">
                        <a href="simplifiedInternships.html">
                          Simplified Internships
                        </a>
                      </h3>
                    </div>
                    <div className="services-desc">
                      <p>
                        Explore virtual internships in Web Dev, App Dev, Full Stack, ML, and Data Science; focused learning
                      </p>
                    </div>
                    <div className="serial-number">01</div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-20">
                <div className="services-item pink-bg">
                  <div className="services-icon">
                    <div className="image-part">
                      <img
                        className="main-img"
                        src="assets/images/services/style2/main-img/2.png"
                        alt=""
                      />
                      <img
                        className="hover-img"
                        src="assets/images/services/style2/hover-img/2.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="services-content">
                    <div className="services-text">
                      <h3 className="title">
                        <a href="buildyournetwork.html">Build your Network</a>
                      </h3>
                    </div>
                    <div className="services-desc">
                      <p>
                        We believe in networking to connect with other interns who are interested in the same things.
                      </p>
                    </div>
                    <div className="serial-number">02</div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-20">
                <div className="services-item aqua-bg">
                  <div className="services-icon">
                    <div className="image-part">
                      <img
                        className="main-img"
                        src="assets/images/services/style2/main-img/3.png"
                        alt=""
                      />
                      <img
                        className="hover-img"
                        src="assets/images/services/style2/hover-img/3.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="services-content">
                    <div className="services-text">
                      <h3 className="title">
                        <a href="building-student-trust.html">Building Student Trust</a>
                      </h3>
                    </div>
                    <div className="services-desc">
                      <p>
                        We ensure students success and build a trusted platform for valuable learning experiences for them.
                      </p>
                    </div>
                    <div className="serial-number">03</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </>

      <>
        <div className="rs-services style3 modify1 pt-120 pb-120 md-pt-80 md-pb-80">
          <div className="container">
            <div className="sec-title2 text-center mb-45">
              <h2 className="title title2">
                Explore Our Learning Programs
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-30">
                <Link to="/learning/mobile">
                  <div className="services-item paste2-bg">
                    <div className="services-icon">
                      <div className="image-part">
                        <img
                          className="main-img"
                          src="assets/images/services/style3/main-img/5.png"
                          alt=""
                        />
                        <img
                          className="hover-img"
                          src="assets/images/services/style3/hover-img/5.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="services-text">
                        <h3 className="title">
                          <a href="#">Mobile App Workshop</a>
                        </h3>
                      </div>
                      {/*<div className="services-desc">
                      <p>
                        At vero eos et accusamus etiusto odio praesentium
                        accusamus etiusto odio data center for managing
                        database.
                      </p>
                    </div>*/}
                      <div className="services-button">
                        <a href="web-development.html">Registration Started</a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-lg-4 col-md-6 mb-30">
                <Link to="/learning/mern">
                  <div className="services-item pink-light-bg">
                    <div className="services-icon">
                      <div className="image-part">
                        <img
                          className="main-img"
                          src="assets/images/services/style3/main-img/5.png"
                          alt=""
                        />
                        <img
                          className="hover-img"
                          src="assets/images/services/style3/hover-img/5.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="services-text">
                        <h3 className="title">
                          <a href="#">MERN Full Stack</a>
                        </h3>
                      </div>
                      {/*<div className="services-desc">
                      <p>
                        At vero eos et accusamus etiusto odio praesentium
                        accusamus etiusto odio data center for managing
                        database.
                      </p>
                    </div>*/}
                      <div className="services-button">
                        <a href="web-development.html">Registration Started</a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-lg-4 col-md-6 mb-30">
                <Link to="/learning/placement">
                  <div className="services-item blue2-bg">
                    <div className="services-icon">
                      <div className="image-part">
                        <img
                          className="main-img"
                          src="assets/images/services/style3/main-img/6.png"
                          alt=""
                        />
                        <img
                          className="hover-img"
                          src="assets/images/services/style3/hover-img/6.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="services-text">
                        <h3 className="title">
                          <a href="web-development.html">Placement Batch</a>
                        </h3>
                      </div>
                      {/*<div className="services-desc">
                      <p>
                        At vero eos et accusamus etiusto odio praesentium
                        accusamus etiusto odio data center for managing
                        database.
                      </p>
                    </div>*/}
                      <div className="services-button">
                        <a href="web-development.html">Registration Started</a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <Link to="/learning/internship">
                  <div className="services-item paste2-bg">
                    <div className="services-icon">
                      <div className="image-part">
                        <img
                          className="main-img"
                          src="assets/images/services/style3/main-img/7.png"
                          alt=""
                        />
                        <img
                          className="hover-img"
                          src="assets/images/services/style3/hover-img/7.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="services-text">
                        <h3 className="title">
                          <a href="web-development.html">
                            Tech Internship
                          </a>
                        </h3>
                      </div>
                      {/*<div className="services-desc">
                      <p>
                        At vero eos et accusamus etiusto odio praesentium
                        accusamus etiusto odio data center for managing
                        database.
                      </p>
                    </div>*/}
                      <div className="services-button">
                        <a href="web-development.html">Registration Started</a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <Link to="/learning/mbainternship">
                  <div className="services-item purple2-bg">
                    <div className="services-icon">
                      <div className="image-part">
                        <img
                          className="main-img"
                          src="assets/images/services/style3/main-img/8.png"
                          alt=""
                        />
                        <img
                          className="hover-img"
                          src="assets/images/services/style3/hover-img/8.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="services-text">
                        <h3 className="title">
                          <a href="web-development.html">MBA Internship</a>
                        </h3>
                      </div>
                      {/*<div className="services-desc">
                      <p>
                        At vero eos et accusamus etiusto odio praesentium
                        accusamus etiusto odio data center for managing
                        database.
                      </p>
                    </div>*/}
                      <div className="services-button">
                        <a href="web-development.html">Registration Started</a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              
              <div className="col-lg-4 col-md-6 mb-30">
                <Link to="/learning/collegeinternship">
                  <div className="services-item pink2-bg">
                    <div className="services-icon">
                      <div className="image-part">
                        <img
                          className="main-img"
                          src="assets/images/services/style3/main-img/10.png"
                          alt=""
                        />
                        <img
                          className="hover-img"
                          src="assets/images/services/style3/hover-img/10.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="services-text">
                        <h3 className="title">
                          <a href="web-development.html">College Internships</a>
                        </h3>
                      </div>
                      {/*<div className="services-desc">
                      <p>
                        At vero eos et accusamus etiusto odio praesentium
                        accusamus etiusto odio data center for managing
                        database.
                      </p>
                    </div>*/}
                      <div className="services-button">
                        <a href="web-development.html">Registration Started</a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-lg-4 col-md-6 mb-30">
                <Link to="/learning/mockinterview">
                  <div className="services-item cyan2-bg">
                    <div className="services-icon">
                      <div className="image-part">
                        <img
                          className="main-img"
                          src="assets/images/services/style3/main-img/9.png"
                          alt=""
                        />
                        <img
                          className="hover-img"
                          src="assets/images/services/style3/hover-img/9.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="services-text">
                        <h3 className="title">
                          <a href="web-development.html">
                            Mock Interview
                          </a>
                        </h3>
                      </div>
                      {/*<div className="services-desc">
                      <p>
                        At vero eos et accusamus etiusto odio praesentium
                        accusamus etiusto odio data center for managing
                        database.
                      </p>
                    </div>*/}
                      <div className="services-button">
                        <a href="web-development.html">Registration Started</a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-lg-4 col-md-6 mb-30">
                <Link to="/learning/collegeworkshop">
                  <div className="services-item pink2-bg">
                    <div className="services-icon">
                      <div className="image-part">
                        <img
                          className="main-img"
                          src="assets/images/services/style3/main-img/10.png"
                          alt=""
                        />
                        <img
                          className="hover-img"
                          src="assets/images/services/style3/hover-img/10.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="services-text">
                        <h3 className="title">
                          <a href="web-development.html">Workshops</a>
                        </h3>
                      </div>
                      {/*<div className="services-desc">
                      <p>
                        At vero eos et accusamus etiusto odio praesentium
                        accusamus etiusto odio data center for managing
                        database.
                      </p>
                    </div>*/}
                      <div className="services-button">
                        <a href="web-development.html">Know More</a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <Link to="/learning/corporatetraining">
                  <div className="services-item pink2-bg">
                    <div className="services-icon">
                      <div className="image-part">
                        <img
                          className="main-img"
                          src="assets/images/services/style3/main-img/10.png"
                          alt=""
                        />
                        <img
                          className="hover-img"
                          src="assets/images/services/style3/hover-img/10.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="services-text">
                        <h3 className="title">
                          <a href="web-development.html">Corporate Training</a>
                        </h3>
                      </div>
                      {/*<div className="services-desc">
                      <p>
                        At vero eos et accusamus etiusto odio praesentium
                        accusamus etiusto odio data center for managing
                        database.
                      </p>
                    </div>*/}
                      <div className="services-button">
                        <a href="web-development.html">Know More</a>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Services Section End */}
      </>

      <>
        {/* Call Action Section Start */}
        <div className="rs-call-action bg8 pt-120 pb-120 md-pt-75 md-pb-80">
          <div className="container">
            <div className="sec-title2 text-center">



              <h2 className="title title4 white-color">
                Be a part of our Campus Ambassador Program
              </h2>
              <div className="call-btn mt-30">
                <Link className="readon discover started" to="/learning/campusambassador">
                  Join Us
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Call Action Section End */}
      </>

      <>
        {/* About Section Start */}
        <div className="rs-about style2 modify1 pt-120 pb-120 md-pt-70 md-pb-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 hidden-md">
                <div className="images">
                  <img src="assets/images/about/about-5.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6 pl-60 md-pl-15">
                <div className="sec-title mb-30">
                  <div className="sub-text style4-bg">About Us</div>
                  <h2 className="title pb-20">
                    Get Specialised in these Domains
                  </h2>
                  <div className="desc">
                    At HashedBit Innovations, we excel in various domains to bring your ideas to life. Our skills include:
                  </div>
                </div>
                <div class="row">
                  <div className="col-lg-12">
                  {/* Skillbar Section Start */}
                  <div className="rs-skillbar style1 home4">
                    <div className="cl-skill-bar">
                      {/* Start Skill Bar */}
                      <span className="skillbar-title">Web Development Basic</span>
                      <div className="skillbar" data-percent={100} >
                        <p className="skillbar-bar" style={{"width":"70%"}} />
                        <span className="skill-bar-percent"> {70}% </span>
                      </div>
                      {/* Start Skill Bar */}
                      <span className="skillbar-title">Android Apps Development</span>
                      <div className="skillbar" data-percent={100}>
                        <p className="skillbar-bar paste-bg" style={{"width":"80%"}} />
                        <span className="skill-bar-percent"> {80}% </span>
                      </div>
                      {/* Start Skill Bar */}
                      <span className="skillbar-title">Full Stack Web Development </span>
                      <div className="skillbar" data-percent={100}>
                        <p className="skillbar-bar blue-bg" style={{"width":"93%"}} />
                        <span className="skill-bar-percent" > {93}% </span>
                      </div>
                      {/* Start Skill Bar */}
                      <span className="skillbar-title">Machine Learning</span>
                      <div className="skillbar" data-percent={100}>
                        <p className="skillbar-bar pink-bg" style={{"width":"50%"}} />
                        <span className="skill-bar-percent" > {50}% </span>
                      </div>
                      {/* Start Skill Bar */}
                      <span className="skillbar-title">Data Science</span>
                      <div className="skillbar" data-percent={100}>
                        <p className="skillbar-bar purple-bg" style={{"width":"65%"}} />
                        <span className="skill-bar-percent" > {60}% </span>
                      </div>
                      
                      {/*<div className="btn-part mt-45">
                      <a className="readon started" href="contact.html">
                        Get Started
                      </a>
                    </div>*/}
                    </div>
                  </div>
                  {/* Skillbar Section End */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About Section End */}
      </>

      <>
        {/* Call Action Section Start */}
        <div className="rs-call-action bg8 pt-120 pb-120 md-pt-75 md-pb-80">
          <div className="container">
            <div className="sec-title2 text-center">
              {/*<span className="sub-text white-color">Let’s Talk</span>*/}
              <h2 className="title title4 white-color">
                Talk to our Learning Consultant!
              </h2>
              <h3 className="title title9 white-color"><span className="icon">
                <FaPhoneVolume />
              </span>
                +91 96697-87936
              </h3>
              {/*<div className="call-btn mt-30">
                <a className="readon discover started" href="contact.html">
                  Get Started
                </a>
              </div>*/}
            </div>
          </div>
        </div>
        {/* Call Action Section End */}
      </>

      <>
        {/* Technology Section Start */}
        <div className="rs-technology gray-color pt-120 pb-100 md-pt-80 md-pb-80">
          <div className="container">
            <div className="sec-title2 text-center mb-45">
              {/*<span className="sub-text gold-color">Technology Index</span>*/}
              <h2 className="title title2">
                Our Learner Work At
              </h2>
              <h3>
                HashedBit Innovations alumni work at some of the leading and innovative organizations of today, solving core business problems.
              </h3>
            </div>
            <div className="row">
              <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                <div className="technology-item">
                  <a href="/">
                    <div className="logo-img">
                      <img src="assets/images/technology/style2/ibm.png" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                <div className="technology-item">
                  <a href="/">
                    <div className="logo-img">
                      <img src="assets/images/technology/style2/visa.png" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                <div className="technology-item">
                  <a href="/">
                    <div className="logo-img">
                      <img src="assets/images/technology/style2/walmart.png" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                <div className="technology-item">
                  <a href="/">
                    <div className="logo-img">
                      <img src="assets/images/technology/style2/paypal.png" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                <div className="technology-item">
                  <a href="/">
                    <div className="logo-img">
                      <img src="assets/images/technology/style2/tcs.png" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                <div className="technology-item">
                  <a href="/">
                    <div className="logo-img">
                      <img src="assets/images/technology/style2/wipro.png" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                <div className="technology-item">
                  <a href="/">
                    <div className="logo-img">
                      <img src="assets/images/technology/style2/infosys.png" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                <div className="technology-item">
                  <a href="/">
                    <div className="logo-img">
                      <img src="assets/images/technology/style2/capgemini.png" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                <div className="technology-item">
                  <a href="/">
                    <div className="logo-img">
                      <img src="assets/images/technology/style2/swiggy.png" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                <div className="technology-item">
                  <a href="/">
                    <div className="logo-img">
                      <img
                        src="assets/images/technology/style2/shiprocket.png"
                        alt=""
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                <div className="technology-item">
                  <a href="/">
                    <div className="logo-img">
                      <img
                        src="assets/images/technology/style2/upstox.png"
                        alt=""
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                <div className="technology-item">
                  <a href="/">
                    <div className="logo-img">
                      <img
                        src="assets/images/technology/style2/amdocs.png"
                        alt=""
                      />
                    </div>
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>

        <TestimonialsCarousel />
      </>
    </div>
  );
};

export default Learning;
