import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ShippingPolicy = () => {
    const [data, setData] = useState({})

    const fetchdata = async () => {
        try {
            const url = process.env.REACT_APP_API_URL + 'pages/fetchpagedata/shippingpolicy';
            const response = await axios.get(url);
            console.log(response.data[0]);
            setData(response.data[0])
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    useEffect(() => {
        fetchdata();
    }, [])

    return (
        <>

            <div className="rs-breadcrumbs img2">
                <div className="breadcrumbs-inner text-center">
                    <h1 className="page-title">Shipping Policy</h1>

                </div>
            </div>


          

            <div className="rs-services style3 pt-110 pb-120 md-pt-75 md-pb-80">
                <div className="container">
                    <div className="sec-title2 text-center mb-45">

                        <h2 className="title testi-title">
                        Shipping Policy
                        </h2>
                    </div>
                    <div className='row'>
                        <div className="col-lg-12 col-md-12 mb-20">
                            <div className="services-item">

                                <div className="services-content">

                                    <div className="services-desc">

                                        {
                                            data && <div dangerouslySetInnerHTML={{ __html: data.pagecontent }} />
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default ShippingPolicy
