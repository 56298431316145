import React, { useState, useEffect } from 'react';
import YouTubeEmbed from '../shared/YouTubeEmbed';
import axios from 'axios';
import { Link } from 'react-router-dom';

const LearningTestimonialVideosAllLoad = () => {
    // Initial video data
  const initialVideos = [
    { videoId: 'of0jFUbEPUU' },
    { videoId: 'rbSAcKSqvZk' },
    { videoId: 'FitPyYyOFUw' }
  ];

  // Additional videos to load
  const moreVideos = [
    { videoId: 'jUnHNHH8O4A' },
    { videoId: 'ly1p2PUmHsI' },
    { videoId: '50xTak0Gg0k' },
    { videoId: 'r4mPl5I-JkI' },
    { videoId: 'sG2ncNcbmcA' },
    { videoId: 'zRC6aYdjsic' },
  ];

  // State to manage loaded videos
  const [videos, setVideos] = useState(initialVideos);
  const [allLoaded, setAllLoaded] = useState(false);

  // Function to load more videos
  const loadMoreVideos = () => {
    if (!allLoaded) {
      setVideos([...videos, ...moreVideos]);
      setAllLoaded(true);
    }
  };

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const servicesUrl = `${process.env.REACT_APP_API_URL}testimonials/fetchtestimonialvideo`;
                const response = await axios.get(servicesUrl);
                setVideos(response.data);
            } catch (error) {
                console.error('Error fetching videos:', error);
            }
        };

        fetchVideos();
    }, []);

    return (
        <div class="rs-services style8 pb-150 md-pt-80 md-pb-80">
        <br></br>
        <div className="container">
          <div className="sec-title2 text-center mb-45">
            <h2 className="title testi-title">Our Learners Voice</h2>
            <div className="heading-line"></div>
          </div>
          <div className="row align-items-center">
            {videos.map((video, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 md-mb-30"
                style={{ marginBottom: "20px" }}
              >
                <YouTubeEmbed videoId={video.videoId} listType="playlist" />
              </div>
            ))}
          </div>
          <div className="col-lg-6 pl-55 md-pl-15 md-pt-60">
            {!allLoaded && (
              <div
                className="btn-part"
                style={{ position: "absolute", left: "33rem", top: "2rem" }}
              >
                <button
                  className="readon started get-new"
                  style={{ width: "175px" }}
                  onClick={loadMoreVideos}
                >
                  Load More
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
}

export default LearningTestimonialVideosAllLoad;