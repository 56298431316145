import React, { useState } from 'react';
import RDInput from './RDInput';
import RDResult from './RDResult';
import RDChart from './RDChart';

const RD = () => {
    const [monthlyAmount, setMonthlyAmount] = useState(0);
    const [rate, setRate] = useState(0);
    const [duration, setDuration] = useState(0);
    const [maturityAmount, setMaturityAmount] = useState(0);
    const [interest, setInterest] = useState(0);
    const [tenureUnit, setTenureUnit] = useState('months');
    const calculateRD = (e) => {
        e.preventDefault();
        let timeInMonths;
        switch (tenureUnit) {
            case 'years':
                timeInMonths = duration * 12;
                break;
            case 'days':
                timeInMonths = duration / 30;
                break;
            default:
                timeInMonths = duration;
                break;
        }

        const interest = monthlyAmount * timeInMonths * (timeInMonths + 1) * rate / 2400
        setInterest(interest);
        // let monthlyRate = rate / (12 * 100);
        // let tempMaturityAmount = monthlyAmount * (Math.pow(1 + monthlyRate, timeInMonths) - 1) / monthlyRate * (1 + monthlyRate);
        let tempMaturityAmount = (monthlyAmount * timeInMonths)+interest
        setMaturityAmount(tempMaturityAmount);
    };

    return (
        <div className="container mt-4 mb-4">
            <div className="border p-4">
                <div className="row">
                    <div className="col-6">
                        <RDInput
                            handleMonthlyAmount={setMonthlyAmount}
                            handleRate={setRate}
                            handleDuration={setDuration}
                            handleTenureUnit={setTenureUnit}
                            calculateRD={calculateRD}
                        />
                        <RDResult
                            maturityAmount={maturityAmount}
                            duration={duration}
                            monthlyAmount={monthlyAmount}
                            tenureUnit={tenureUnit}
                            interest={interest}
                        />
                    </div>
                    <div className="col-6">
                        <div className="chart-container" style={{ maxWidth: '400px', margin: '0 auto' }}>
                            <RDChart
                                maturityAmount={maturityAmount}
                                monthlyAmount={monthlyAmount}
                                tenureUnit={tenureUnit}
                                duration={duration}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RD;
