import React from "react";
import softwareIcon from "../../assets/images/Technology/111.png";
import fintechIcon from "../../assets/images/Technology/222.png";
import healthcareIcon from "../../assets/images/Technology/333.png";
import dataMiningIcon from "../../assets/images/Technology/444.png";
import machineLearningIcon from "../../assets/images/Technology/555.png";
import medicalIcon from "../../assets/images/Technology/666.png";
import entertainmentIcon from "../../assets/images/Technology/777.png";
import aiIcon from "../../assets/images/Technology/888.png";
import manufacturingIcon from "../../assets/images/Technology/999.png";
import logisticsIcon from "../../assets/images/Technology/1000.png";

const Domain = () => {
    return (
      <>
        <div class="rs-breadcrumbs img1">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Our Domains</h1>
          {/* <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">Home</a>
            </li>
            <li>About</li>
          </ul> */}
        </div>
      </div>
  
        {/* First Section - Industry Solutions */}
        <section
          className="solutions text-center"
          style={{
            padding: "40px 0",
            backgroundColor: "#ffffff",
          }}
        >
          
          <br />
          <h2
            style={{
              fontSize: "28px",
              marginBottom: "30px",
              fontWeight: "700",
              lineHeight: "1.4",
              color: "#0a0a0a",
              maxWidth: "490px",
              margin: "0 auto",
            }}
          >
            Best Solutions, For All
            <br /> Organizations
          </h2>
          <br />
          <br />
          <div
            className="solution-cards"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr)", // Ensure 5 cards per row
              gap: "15px",
              justifyContent: "center",
              maxWidth: "1000px", // Width adjusted for correct card size and spacing
              margin: "0 auto",
              width:"100%",
              height:"100%",
            }}
          >
            {[
              { icon: softwareIcon, text: "Software" },
              { icon: fintechIcon, text: "Fintech" },
              { icon: healthcareIcon, text: "Healthcare" },
              { icon: dataMiningIcon, text: "Data Mining" },
              { icon: machineLearningIcon, text: "Machine Learning" },
              { icon: medicalIcon, text: "Medical" },
              { icon: entertainmentIcon, text: "Entertainment" },
              { icon: aiIcon, text: "AI" },
              { icon: manufacturingIcon, text: "Manufacturing" },
              { icon: logisticsIcon, text: "Logistics" },
            ].map((item, index) => (
              <div
                key={index}
                className="card"
                style={{
                  border: "1px solid #f0f0f0",
                  borderRadius: "5px",
                  backgroundColor: "#ffffff",
                  padding: "10px 0",
                  boxShadow: "20px 20px 50px 20px rgba(0.04, 0.04, 0.04, 0.04)",
                  textAlign: "center",
                  boxSizing: "border-box",
                  width: "220px",
                  height:"170px",
                  float:"left",
                  //margin:"0px 20px 0px 20px",

                }}
              >
                <img
                  src={item.icon}
                  alt={item.text + " Icon"}
                  className="card-img-top"
                  style={{
                    width: "55px",
                    height: "70px",
                    marginBottom: "10px",
                    margin: "auto",
                    padding:"auto",
                    paddingTop:"20px",
                  }}
                />
                <div className="card-body">
                  <p
                    className="card-text"
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#000000",
                      margin: 0,
                      fontFamily: "'Livvic', sans-serif",
                    }}
                  >
                    {item.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </section>
<br />
     
    </>
  );
};

export default Domain;