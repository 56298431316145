import React from 'react';
import { Link } from 'react-router-dom';
import tickimage from '../../assets/img/learning/tick.svg';
 

const MBAPaidInternship = () => {
  return (
    <>

      <div class="rs-breadcrumbs img1">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Join us as Business Development Executive</h1>
          <h4 className='text-white'>Business Development</h4>
          {/* <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">Home</a>
            </li>
            <li><a class="active" href="/learning ">Learning</a></li>
            <li> MBA Paid Internship</li>
          </ul> */}
        </div>
      </div>

      
      <div class="rs-specialty bg18 pt-170 pb-230 md-pb-185">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="rs-feature">
                <div class="row">
                  <div class="col-md-6 mt-20 pr-6  md-pb-20">
                    <div class="icon-box-area mb-20">
                      <div class="box-inner">
                        <div class="icon-area">
                          <a href="#"><img src=".assets/images/features/icons/1.png" alt="" /></a>
                        </div>
                        <div class="content-part">
                          <h4 class="title"><a href="#">Lead Generation</a></h4>
                        </div>
                      </div>
                    </div>
                    <div class="icon-box-area purple-bg">
                      <div class="box-inner">
                        <div class="icon-area">
                          <a href="#"><img src=".assets/images/features/icons/3.png" alt="" /></a>
                        </div>
                        <div class="content-part">
                          <h4 class="title"><a href="#">Sales & Marketing</a></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="icon-box-area blue-bg mb-20">
                      <div class="box-inner">
                        <div class="icon-area">
                          <a href="#"><img src=".assets/images/features/icons/2.png" alt="" /></a>
                        </div>
                        <div class="content-part">
                          <h4 class="title"><a href="#">Client Relations</a></h4>
                        </div>
                      </div>
                    </div>
                    <div class="icon-box-area pink-bg">
                      <div class="box-inner">
                        <div class="icon-area">
                          <a href="#"><img src=".assets/images/features/icons/4.png" alt="" /></a>
                        </div>
                        <div class="content-part">
                          <h4 class="title"><a href="#">Strategy and Campaigning</a></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 pl-55 md-pl-15 md-pt-60">
              {/* <div class="sec-title6 mb-30">
                <div class="sub-text">Our Specialty</div>
                <h2 class="title pb-20">
                  What's so Special About Braintech
                </h2>
                <div class="desc">
                  Bring to the table win-win survival strategies to ensure dotted proactive domination. At the end of the day, going forward, a new normal that has evolved
                </div>
              </div> */}
              <ul class="rs-features-list">
                <li><i class="fa fa-check"></i><span>Freshers/experienced - IT Domain Knowledge preferred</span></li>
                <li><i class="fa fa-check"></i><span>2 Months Paid Internship - Stipend 5000 Per Month</span></li>
                <li><i class="fa fa-check"></i><span>PPO/Full Time Opportunity on satisfactory performance</span></li>
                <li><i class="fa fa-check"></i><span>Remote / Work from Home Opportunity</span></li>
                <li><i class="fa fa-check"></i><span>Timing - Normal Office Hours</span></li>
                <li><i class="fa fa-check"></i><span>Mandatory - Persoanl laptop & Basic Computer skills</span></li>
                <li><i class="fa fa-check"></i><span>Passionate for Business Development works</span></li>

              </ul>
              <div class="round-check-list-row">
                <h3>Selection Process</h3>
              </div>
              <ul class="rs-features-list">
                <li><i class="fa fa-check"></i><span>Step 1 - CV Shortlisting</span></li>
                <li><i class="fa fa-check"></i><span>Step 2 - Screening Interview</span></li>
                <li><i class="fa fa-check"></i><span>Step 3 - Business Development Screening Task</span></li>
                <li><i class="fa fa-check"></i><span>Step 4 - Offer Letter & Onboarding</span></li>


              </ul>

              <div class="btn-part">
                <Link class="readon started get-new" to="/learning/registration/mbapaidinternship">Enroll Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default MBAPaidInternship;
