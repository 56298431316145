import React from 'react';

import rahul from '../../assets/images/placement/rahul.jpeg';
import pulak from '../../assets/images/placement/pulak.jpeg';
import dhaneshwar from '../../assets/images/placement/dhaneshwar.jpeg';
import gajendra from '../../assets/images/placement/gajendra.jpeg';
import akansha from '../../assets/images/placement/akansha.jpeg';
import harendra from '../../assets/images/placement/Harendra.jpeg';
import saurabh from '../../assets/images/placement/saurabh.jpeg';
import satya from '../../assets/images/placement/satya.jpeg';

const LearningPlacementAlumni = () => {
  return (
    <>
    <div class="rs-industry pt-50 pb-120 md-pt-80 md-pb-80">
        <div class="container">
          <div class="sec-title2 text-center mb-45">
            {/* <span class="sub-text gold-color">Work For Any Industry</span> */}
            <h2 class="title">
              Some of our Alumni
            </h2>
          </div>
          <div class="all-services">
            <div class="services-item">
              <div class="services-wrap">
                <div class="services-icon">
                  <img src={rahul} alt="" />
                </div>
                <div class="services-text">
                  <h4 class="title">Rahul Parmar</h4>
                  <div>Lava - 10 LPA</div>
                </div>
              </div>
            </div>
            <div class="services-item">
              <div class="services-wrap">
                <div class="services-icon">
                  <img src={pulak} alt="" />
                </div>
                <div class="services-text">
                  <h4 class="title">Pulak Vyas</h4>
                  <div>Supplycopia - 6 LPA</div>
                </div>
              </div>
            </div>
            <div class="services-item">
              <div class="services-wrap">
                <div class="services-icon">
                  <img src={dhaneshwar} alt="" />
                </div>
                <div class="services-text">
                  <h4 class="title">Dhaneshwar</h4>
                  <div>ABIR Networks - 6 LPA</div>
                </div>
              </div>
            </div>
            <div class="services-item">
              <div class="services-wrap">
                <div class="services-icon">
                  <img src={gajendra} alt="" />
                </div>
                <div class="services-text">
                  <h4 class="title">Gajendra Pal</h4>
                  <div>HCL - 9 LPA</div>
                </div>
              </div>
            </div>
            <div class="services-item">
              <div class="services-wrap">
                <div class="services-icon">
                  <img src={akansha} alt="" />
                </div>
                <div class="services-text">
                  <h4 class="title">Akansha Dhoke</h4>
                  <div>CodeAce - 6 LPA</div>
                </div>
              </div>
            </div>
            <div class="services-item">

            </div>
            <div class="services-item">
              <div class="services-wrap">
                <div class="services-icon">
                  <img src={harendra} alt="" />
                </div>
                <div class="services-text">
                  <h4 class="title">Harendra Pal</h4>
                  <div>MERCER - 8 LPA</div>
                </div>
              </div>
            </div>
            <div class="services-item">
              <div class="services-wrap">
                <div class="services-icon">
                  <img src={saurabh} alt="" />
                </div>
                <div class="services-text">
                  <h4 class="title">Saurabh Shukla</h4>
                  <div>Magic Edtech - 11 LPA</div>
                </div>
              </div>
            </div>

            <div class="services-item">
              <div class="services-wrap">
                <div class="services-icon">
                  <img src={satya} alt="" />
                </div>
                <div class="services-text">
                  <h4 class="title">Satya Prakash</h4>
                  <div>PerfectionGeeks - 5 LPA</div>
                </div>
              </div>
            </div>
            {/*<div class="services-item">
              <div class="services-wrap">
                <div class="services-icon">
                  <img src="assets/images/technology/style3/9.png" alt=""/>
                </div>
                <div class="services-text">
                  <h4 class="title">Manufacturing</h4>
                </div>
              </div>
            </div>
            <div class="services-item">
              <div class="services-wrap">
                <div class="services-icon">
                  <img src="assets/images/technology/style3/10.png" alt=""/>
                </div>
                <div class="services-text">
                  <h4 class="title">Logistics</h4>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      </>
  )
}

export default LearningPlacementAlumni
