import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Image from '../assets/img/1.jpg';
import axios from 'axios';

import RecentBlogs from './blog/RecentBlogs';
import BlogCategories from './blog/BlogCategories';
import Loader from './Loader'; // Import the Loader component

const BlogDetail = () => {
    const blogid = window.localStorage.getItem('blogid');
    const [blogData, setBlogData] = useState({});
    const [loading, setLoading] = useState(true); // Add loading state

    const fetchBlogDetail = async (blogid) => {
        const url = process.env.REACT_APP_API_URL + 'blog/fetchblogdetail/' + blogid;
        try {
            const response = await axios.get(url);
            setBlogData(response.data.blog);
        } catch (err) {
            console.log('Error In API Call - ', err);
        } finally {
            setLoading(false); // Set loading to false after fetching data
        }
    }

    useEffect(() => {
        fetchBlogDetail(blogid);
    }, [blogid]);

    return (
        <div>
            <div className="rs-breadcrumbs img4">
                <div className="breadcrumbs-inner text-center">
                    <h1 className="page-title new-title pb-10">{blogData.postheading}</h1>
                </div>
            </div>

            <div className="rs-inner-blog pt-120 pb-120 md-pt-90 md-pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 order-last">
                            <div className="widget-area">
                                <BlogCategories />
                                <RecentBlogs />
                            </div>
                        </div>
                        <div className="col-lg-8 pr-35 md-pr-15">
                            {loading ? ( // Show loader while loading
                                <Loader />
                            ) : (
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="blog-details">
                                            <h2><b>{blogData.postheading}</b></h2>
                                            <div className="bs-img mb-35">
                                                <img src={Image} alt={blogData.postheading} />
                                            </div>
                                            <div className="blog-full">
                                                <ul className="single-post-meta">
                                                    <li>
                                                        <span className="p-date"> <i className="fa fa-user-o"></i>
                                                            <b>{blogData.author}</b>
                                                        </span>
                                                    </li>
                                                    <li className="Post-cate">
                                                        <div className="tag-line">
                                                            <i className="fa fa-book"></i>
                                                            <b>{blogData.category}</b>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <p>
                                                    <div dangerouslySetInnerHTML={{ __html: blogData.content }} />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogDetail;
