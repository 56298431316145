import React from 'react'
import { Link } from 'react-router-dom';





const Career = () => {



  return (
    <>
      <div class="rs-breadcrumbs img1">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Career</h1>
          <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">Home</a>
            </li>
            <li>Career</li>
          </ul>
        </div>
      </div>


      {/* <!-- Current openings section Start --> */}

      <div id="rs-services" class="rs-services style3 modify2 pt-120 pb-120 md-pt-80 md-pb-80">
        <div class="container">
          <div class="sec-title2 text-center mb-50">
            <h2 class="title testi-title">
              Current Opening
            </h2>

          </div>
          <div class="row">
            <div class="col-md-6 mb-20">
              <div class="services-item light-purple-bg">
                <div class="services-icon">
                  <div class="image-part">

                    <img class="hover-img" src="assets/images/services/style2/hover-img/6.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="web-development.html">Join as Bussiness Developement Executive</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Join our internship under most skilled and experience mentor and grow in real coin in real industry.
                    </p>
                  </div>
                  <div class="btn-part mt-3">
                    <Link to='/learning/mbapaidinternship'><a class="readon submit" href=" ">Apply</a></Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-20">
              <div class="services-item light-purple-bg">
                <div class="services-icon">
                  <div class="image-part">

                    <img class="hover-img" src="assets/images/services/style2/hover-img/1.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="web-development.html">Join Us As Technical Intern</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Learn, work and get experience of live project under experienced and skilled mentor and be a valuable assets for upcoming industry.
                    </p>
                  </div>
                  <div class="btn-part mt-3">
                    <Link to='/learning/internship'><a class="readon submit" href=" ">Apply</a></Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 sm-mb-20">
              <div class="services-item light-purple-bg">
                <div class="services-icon">
                  <div class="image-part">

                    <img class="hover-img" src="assets/images/services/style2/hover-img/5.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="web-development.html">Apply for Other Openings</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      If you have any other specialization and want to join our group of top notch people then fill the career form.
                    </p>
                  </div>
                  <div class="btn-part mt-3">
                    <Link to='/career/apply'><a class="readon submit" href=" ">Apply</a></Link>
                  </div>
                </div>
              </div>
            </div>



          </div>
          {/* <div class="col-lg-12 text-center">
            <div class="btn-part wow fadeinup mt-45">
              <a class="readon more-about wow fadeinup" href="about.html">View More</a>
            </div>
          </div> */}
        </div>

      </div>
      {/* <!-- Services Section End --> */}

      {/* <!-- Process Section Start --> */}
      <div class="rs-process style3 gray-color pt-120 pb-120 md-pt-75 md-pb-80">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5">
              <div class="process-wrap bg9">
                <div class="sec-title mb-30">
                  <div class="sub-text new">How We Works</div>
                  <h2 class="title title4 white-color pb-20">
                    We run on beliefs and ethics, as strong as our programmes' logical structures.
                  </h2>

                  <div class="btn-part mt-40">
                    <a class="readon discover started" href="contact.html">Learn More</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-7 pl-35 md-pt-40 md-pl-15">
              <div class="row">
                <div class="col-md-6 mb-20">
                  <div class="rs-addon-number">
                    <div class="number-text">
                      <div class="number-area">
                        01
                      </div>
                      <div class="number-title">
                        <h3 class="title">Responsibility and Ownership</h3>
                      </div>
                      <p class="number-txt">We stand true to what we create and each of us owns the products we make. </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-20">
                  <div class="rs-addon-number">
                    <div class="number-text">
                      <div class="number-area">
                        02
                      </div>
                      <div class="number-title">
                        <h3 class="title">Integrity</h3>
                      </div>
                      <p class="number-txt">In face of difficulties, we are stronger than ever. We are fueled by passion and motivation to create.</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 md-mb-20">
                  <div class="rs-addon-number">
                    <div class="number-text">
                      <div class="number-area">
                        03
                      </div>
                      <div class="number-title">
                        <h3 class="title">Collaboration</h3>
                      </div>
                      <p class="number-txt">Best ideas come when we create together. At Relinns, brainstorming is the staple .</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="rs-addon-number">
                    <div class="number-text">
                      <div class="number-area">
                        04
                      </div>
                      <div class="number-title">
                        <h3 class="title">Excellence</h3>
                      </div>
                      <p class="number-txt">All our values drive us to achieve excellence in our pursuits and our conscience .</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Process Section End --> */}




      {/* <!-- Choose Section Start --> */}
      <div class="rs-why-choose pt-120 pb-120 md-pt-75 md-pb-80">
        <div class="container">
          <div class="row">

            <div class=" col-lg-12 sec-title mb-40">
              <div class="sub-text style4-bg">Why Choose Us</div>
              <h2 class="title pb-20">
                We Create Result-Oriented Dynamic Applications
              </h2>
            </div>

            <div class=" col-lg-5 services-wrap mb-25 mx-3">
              <div class="services-icon">
                <img src="assets/images/choose/icons/1.png" alt="" />
              </div>
              <div class="services-text">
                <h3 class="title"><a href="#">Flexible working environment</a></h3>
                <p class="services-txt">We provide the flexibility to work at convenient hours and we focus on getting the work done rather than spending long hours in office.</p>
              </div>
            </div>

            <div class=" col-lg-5  services-wrap mb-25 mx-3">
              <div class="services-icon">
                <img src="assets/images/choose/icons/1.png" alt="" />
              </div>
              <div class="services-text">
                <h3 class="title"><a href="#">Work-life balance</a></h3>
                <p class="services-txt"> We understand and do everything to make sure our employees have proper work life balance. After all you will never feel truly satisfied by work until you are satisfied by life.</p>
              </div>
            </div>

            <div class=" col-lg-5  services-wrap mb-25 mx-3">
              <div class="services-icon">
                <img src="assets/images/choose/icons/1.png" alt="" />
              </div>
              <div class="services-text">
                <h3 class="title"><a href="#">Experts to learn from</a></h3>
                <p class="services-txt">We provide you the opportunity to be trained and mentored by Subject Matter Experts. Our mentors have high quotient of intelligence and empathy.</p>
              </div>
            </div>

            <div class=" col-lg-5  services-wrap mb-25 mx-3">
              <div class="services-icon">
                <img src="assets/images/choose/icons/1.png" alt="" />
              </div>
              <div class="services-text">
                <h3 class="title"><a href="#">Excellent career growth</a></h3>
                <p class="services-txt"> You don’t need to wait for specific period of time to get your work acknowledged. We always value your contributions to both strategy and execution parts.</p>
              </div>
            </div>

            <div class=" col-lg-5  services-wrap mb-25 mx-3">
              <div class="services-icon">
                <img src="assets/images/choose/icons/2.png" alt="" />
              </div>
              <div class="services-text">
                <h3 class="title"><a href="#">Open door management</a></h3>
                <p class="services-txt"> If you are facing any issue regarding anything our Management is there to hear your problems. Weekly meetings and get togethers are there to ensure you can speak your mind at any point of time.</p>
              </div>
            </div>

            <div class=" col-lg-5 services-wrap mb-25 mx-3">
              <div class="services-icon">
                <img src="assets/images/choose/icons/3.png" alt="" />
              </div>
              <div class="services-text">
                <h3 class="title"><a href="#"> Engaging work culture</a></h3>

                <p class="services-txt">All work and no play makes Jack a dull employee. We keep our people engaged in fun activities that enables them to communicate and understand each other in a better wa</p>
              </div>
            </div>


          </div>
        </div>
      </div>
      {/* <!-- Choose Section End --> */}

      {/* <!-- Counter Section Start --> */}
      {/* <div class="rs-contact-wrap bg5 pt-120 pb-390 md-pt-80">
        <div class="container">
          <div class="sec-title2 text-center mb-30">
            <span class="sub-text style-bg white-color">Contact</span>
            <h2 class="title white-color">
              Request A Free Consultation
            </h2>
          </div>
        </div>
      </div> */}
      {/* <!-- Counter Section End --> */}

      {/* <!-- Video Section End --> */}
      {/* <div class="rs-video-wrap style2 inner  md-pb-80">
        <div class="container">
          <div class="row margin-0 gray-color">
            <div class="col-lg-6 padding-0">
              <div class="video-item">
                <div class="rs-videos">
                  <div class="animate-border main-home style2">
                    <a class="popup-border popup-videos" href="https://www.youtube.com/watch?v=YLN1Argi7ik">
                      <i class="fa fa-play"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 padding-0">
              <div class="rs-requset">
                <div id="form-messages"></div>
                <form id="contact-form" method="post" action="https://rstheme.com/products/html/braintech/mailer.php">
                  <fieldset>
                    <div class="row">
                      <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                        <input class="from-control" type="text" id="name" name="name" placeholder="Name" required="" />
                      </div>
                      <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                        <input class="from-control" type="text" id="email" name="email" placeholder="E-Mail" required="" />
                      </div>
                      <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                        <input class="from-control" type="text" id="phone" name="phone" placeholder="Phone Number" required="" />
                      </div>
                      <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                        <input class="from-control" type="text" id="website" name="website" placeholder="Your Website" required="" />
                      </div>

                      <div class="col-lg-12 mb-45">
                        <textarea class="from-control" id="message" name="message" placeholder="Your message Here" required=""></textarea>
                      </div>
                    </div>
                    <div class="btn-part">
                      <input class="submit sub-small" type="submit" value="Submit Now" />
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Video Section End --> */}




    </>
  )
}

export default Career
