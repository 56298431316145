import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import tickimage from '../../assets/img/learning/tick.svg';
import YouTubeEmbed from '../shared/YouTubeEmbed';
import LearningTestimonialVideos from './LearningTestimonialVideos';



const MBAInternship = () => {
    // Initial video data
    const initialVideos = [
      { videoId: 'yR1wbqGz06c' },
      { videoId: '6stlCkUDG_s' },
      { videoId: 'dQw4w9WgXcQ' }
    ];
  
    // Additional videos to load
    const moreVideos = [
      { videoId: 'dQw4w9WgXcQ' },
      { videoId: 'yR1wbqGz06c' },
      { videoId: '6stlCkUDG_s' }
    ];
  
    // State to manage loaded videos
    const [videos, setVideos] = useState(initialVideos);
    const [allLoaded, setAllLoaded] = useState(false);
  
    // Function to load more videos
    const loadMoreVideos = () => {
      if (!allLoaded) {
        setVideos([...videos, ...moreVideos]);
        setAllLoaded(true); 
      }
    };
  return (
    <>

      <div class="rs-breadcrumbs img1">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Join us as MBA Intern</h1>
          <h4 className='text-white'>Recruitment / Sales / Marketing / Business Development / Digital Marketing Experience</h4>
          {/* <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">Home</a>
            </li>
            <li><a class="active" href="/learning ">Learning</a></li>
            <li> MBA Internship</li>
          </ul> */}
        </div>
      </div>

       
      <div class="rs-specialty bg18 pt-170 pb-230 md-pb-185">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="rs-feature">
                <div class="row">
                  <div class="col-md-6 mt-20 pr-6  md-pb-20">
                    <div class="icon-box-area mb-20">
                      <div class="box-inner">
                        {/* <div class="icon-area">
                          <a href="#"><img src=".assets/images/features/icons/3.png" alt="" /></a>
                        </div> */}
                            <div class="icon-area">
                              <a href="#">
                                <img
                                  src="../../assets/images/features/icons/3.png"
                                  alt=""
                                />
                              </a>
                            </div>
                        <div class="content-part">
                          <h4 class="title"><a href="#"> Recruitment</a></h4>
                        </div>
                      </div>
                    </div>
                    <div class="icon-box-area purple-bg">
                      <div class="box-inner">
                        {/* <div class="icon-area">
                          <a href="#"><img src=".assets/images/features/icons/2.png" alt="" /></a>
                        </div> */}
                            <div class="icon-area">
                              <a href="#">
                                <img
                                  src="../../assets/images/features/icons/2.png"
                                  alt=""
                                />
                              </a>
                            </div>
                        <div class="content-part">
                          <h4 class="title"><a href="#">Business Development</a></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="icon-box-area blue-bg mb-20">
                      <div class="box-inner">
                        {/* <div class="icon-area">
                          <a href="#"><img src=".assets/images/features/icons/2.png" alt="" /></a>
                        </div> */}
                            <div class="icon-area">
                              <a href="#">
                                <img
                                  src="../../assets/images/features/icons/2.png"
                                  alt=""
                                />
                              </a>
                            </div>
                        <div class="content-part">
                          <h4 class="title"><a href="#">Digital Marketing</a></h4>
                        </div>
                      </div>
                    </div>
                    <div class="icon-box-area pink-bg">
                      <div class="box-inner">
                        {/* <div class="icon-area">
                          <a href="#"><img src=".assets/images/features/icons/4.png" alt="" /></a>
                        </div> */}
                        <div class="icon-area">
                              <a href="#">
                                <img
                                  src="../../assets/images/features/icons/4.png"
                                  alt=""
                                />
                              </a>
                            </div>
                        <div class="content-part">
                          <h4 class="title"><a href="#">Finance & Investment</a></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 pl-55 md-pl-15 md-pt-60">
              {/* <div class="sec-title6 mb-30">
                <div class="sub-text">Our Specialty</div>
                <h2 class="title pb-20">
                  What's so Special About Braintech
                </h2>
                <div class="desc">
                  Bring to the table win-win survival strategies to ensure dotted proactive domination. At the end of the day, going forward, a new normal that has evolved
                </div>
              </div> */}
              <ul class="rs-features-list">
                <li><i class="fa fa-check"></i><span>For college freshers</span></li>
                <li><i class="fa fa-check"></i><span>2/6 months duration</span></li>
                <li><i class="fa fa-check"></i><span>Flexible timing</span></li>
                <li><i class="fa fa-check"></i><span>Complete WFH / Online Mode</span></li>
                <li><i class="fa fa-check"></i><span>Zero Charges</span></li>
                <li><i class="fa fa-check"></i><span>Free / Unpaid Internship for 2 months</span></li>
                <li><i class="fa fa-check"></i><span>Placement Assistance</span></li>
                <li><i class="fa fa-check"></i><span>PPO / Placement Opportunity</span></li>
                <li><i class="fa fa-check"></i><span>Mentorship by Industry Experts</span></li>

              </ul>

              <div class="btn-part">
                <Link class="readon started get-new" to="/learning/registration/mbainternship">Enroll Now</Link>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="rs-services style8 pt-120 pb-120 md-pt-80 md-pb-80">
        <br></br>
        <div className="container">
          <div className="sec-title2 text-center mb-50">
            <h2 className="title testi-title">Our Latest Videos</h2>
          </div>
          <div className="row align-items-center">
            {videos.map((video, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 md-mb-30"
                style={{ marginBottom: "20px" }}
              >
                <YouTubeEmbed videoId={video.videoId} />
              </div>
            ))}
          </div>
          <div className="col-lg-6 pl-55 md-pl-15 md-pt-60">
            {!allLoaded && (
              <div
                className="btn-part"
                style={{ position: "absolute", left: "33rem", top: "6rem" }}
              >
                <button
                  className="readon started get-new"
                  style={{ width: "175px" }}
                  onClick={loadMoreVideos}
                >
                  Load More
                </button>
              </div>
            )}
          </div>
        </div> */}
         <div class="rs-services style8 pt-120 pb-120 md-pt-80 md-pb-80">
              <br></br>
            <div className="container"></div>
            <LearningTestimonialVideos/>

        </div>
      </div>


    </>
  )
}

export default MBAInternship;