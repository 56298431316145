import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const RDChart = (props) => {
    const { maturityAmount, monthlyAmount, tenureUnit, duration } = props;
    // Calculate the total invested amount
    const calculateTotalInvestedAmount = () => {
        let totalDuration;
        switch (tenureUnit) {
            case 'years':
                totalDuration = duration * 12;
                break;
            case 'days':
                totalDuration = duration / 30;
                break;
            default:
                totalDuration = duration;
                break;
        }
        return totalDuration * monthlyAmount;
    };

    const investedAmount = calculateTotalInvestedAmount().toFixed(2);
    const totalMaturityValue = parseFloat(maturityAmount).toFixed(2);

    // Debugging: Log the props values
    console.log('RDChart Props:', { maturityAmount, monthlyAmount, tenureUnit });

    // Ensure amount and interest are numbers
    const validAmount = parseFloat(investedAmount) || 25000;
    const validInterest = parseFloat(totalMaturityValue) || 30000;

    const data = {
        labels: ['Total Investment', 'Maturity Amount'],
        datasets: [
            {
                data: [validAmount, validInterest],
                backgroundColor: ['#dae2e6', '#3f5bd4'],
            }
        ]
    };

    const options = {
        cutout: '50%',
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    padding: 35,
                },
                font: {
                    size: 16,
                }
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const label = tooltipItem.label || '';
                        const value = tooltipItem.raw;
                        return `${label}: ₹${value}`;
                    },
                    bodyFont: {
                        size: 14,
                    },
                    titleFont: {
                        size: 16,
                    }
                }
            }
        }
    };

    return (
        <div className='text-center p-2'>
            <h4 className="fs-4 font-weight-bold">RD Chart ({tenureUnit})</h4>
            <br />
            <Doughnut data={data} options={options} />
        </div>
    );
};

export default RDChart;
