import React, { useState, useEffect } from 'react';
import YouTubeEmbed from '../shared/YouTubeEmbed';
import axios from 'axios';
import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";

const LearningTestimonialVideos = () => {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const servicesUrl = `${process.env.REACT_APP_API_URL}testimonials/fetchtestimonialvideo`;
                const response = await axios.get(servicesUrl);
                setVideos(response.data);
            } catch (error) {
                console.error('Error fetching videos:', error);
            }
        };

        fetchVideos();
    }, []);

    return (
        <div>
            {/* <div className="sec-title2 text-center mb-10">
                <h2 className="title testi-title">Our Learners Voice</h2>
                <div className="heading-line"></div>
            </div> */}


            <div class="rs-services style8 pb-150 md-pt-80 md-pb-80">
                <br></br>
                <div className="container">
                    <div className="sec-title2 text-center mb-45">
                        <h2 className="title testi-title">Our Learners Voice</h2>
                        <div className="heading-line"></div>
                    </div>

                    <div className="col-lg-12 pt-60">
                        {videos.length > 0 ? (
                            <OwlCarousel
                                className="rs-carousel owl-carousel"
                                loop={true}
                                margin={30}
                                autoplay={true}
                                autoplayTimeout={5000}
                                smartSpeed={800}
                                dots={true}
                                nav={false}
                                responsive={{
                                    0: { items: 1, dots: false },
                                    768: { items: 2, dots: true },
                                    992: { items: 3, dots: true },
                                }}
                            >
                                {videos.map((video, index) => (
                                    <div key={index} className="item">
                                        <YouTubeEmbed videoId={video.videoId} />
                                    </div>
                                ))}
                            </OwlCarousel>
                        ) : (
                            <p className="text-center">No videos available at the moment.</p>
                        )}
                    </div>
                </div>
            </div>


        </div>
    );
}

export default LearningTestimonialVideos;