import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const BlogCategories = () => {
    const [categories, setCategories] = useState([]);

    const fetchCategories = async () => {
        const url = process.env.REACT_APP_API_URL + 'category/fetchallcategories';

        try {
            const response = await axios.get(url);
            setCategories(response.data);
        } catch (err) {
            console.error('Error in API Call:', err);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <>

            <div className="categories mb-50">
                <div className="widget-title">
                    <h3 className="title">Blog Categories</h3>
                </div>
                <ul>
                    {categories.map((category, index) => (
                        <li key={index + category.name}><Link to={`/blogs/category/${category.name}`}>{category.displayname}</Link></li>
                    ))}
                </ul>
            </div>

        </>
    )
}

export default BlogCategories
