import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import sample_blog_image from '../assets/img/blog/inner/1.jpg';

const BlogCareer = () => {
    const [blogsData, setBlogsData] = useState([]);
    // const [categories, setCategories] = useState([]);
    // const [recentPosts, setRecentPosts] = useState([]);

    const navigate = useNavigate();

    const fetchBlogs = async () => {
        const url = process.env.REACT_APP_API_URL + 'blog/fetchcareerblogsheading/0';

        try {
            const response = await axios.get(url);
            setBlogsData(response.data);
        } catch (err) {
            console.error('Error in API Call:', err);
        }
    };

    // const fetchCategories = async () => {
    //     const url = process.env.REACT_APP_API_URL + 'category/fetchallcategories';

    //     try {
    //         const response = await axios.get(url);
    //         setCategories(response.data);
    //     } catch (err) {
    //         console.error('Error in API Call:', err);
    //     }
    // };

    // const fetchRecentPosts = async () => {
    //     const url = process.env.REACT_APP_API_URL + 'blog/recentblogs';

    //     try {
    //         const response = await axios.get(url);
    //         setRecentPosts(response.data);
    //     } catch (err) {
    //         console.error('Error in API Call:', err);
    //     }
    // };

    const showBlogPage = (srnum, title) => {
        window.localStorage.setItem('blogid', srnum);
        //redirect to blog page with title in url
        navigate(`/blog/${title}`)
    }

    const stripHtmlTags = (html) => {
        // Remove all HTML tags except for some basic formatting tags like <b>, <i>, <p>, etc.
        const cleanHtml = html.replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>?/gi, '');
        return cleanHtml;
    };


    useEffect(() => {
        fetchBlogs();
        // fetchCategories();
        // fetchRecentPosts();
    }, []);

    return (
        <>
            <div className="rs-breadcrumbs img4">
                <div className="breadcrumbs-inner text-center">
                    <h1 className="page-title">Job Posts - Prepare & Apply for your dream company</h1>
                    {/* <ul>
                        <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                            <Link className="active" to="/home">Home</Link>
                        </li>
                        <li>Blog</li>
                    </ul> */}
                </div>
            </div>
            {/* <div className="services-area">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-tittle text-center mb-80">
                                <h2>Thoughts, Ideas and Tech Articles​</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="rs-inner-blog pt-120 pb-120 md-pt-90 md-pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 order-last">
                            <div className="widget-area">
                                {/* <div className="search-widget mb-50">
                                    <div className="search-wrap">
                                        <input type="search" placeholder="Searching..." name="s" className="search-input" value="" />
                                        <button type="submit" value="Search"><i className="flaticon-search"></i></button>
                                    </div>
                                </div> */}

                                <div className="categories mb-50">
                                    <Link class="readon started get-ready3" to="/learning/registration/mockinterview">Register for Mock Interview</Link>
                                </div>

                                <div className="categories mb-50">
                                    <Link class="readon started get-ready3" to="/learning/registration/placement">Join Placement Training</Link>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-8 pr-35 md-pr-15">
                            <div className="row">
                                {blogsData.map((item, index) => (
                                    <div className="col-lg-12 mb-50" key={index}>
                                        <div className="blog-item">
                                            <div className="blog-img" onClick={() => showBlogPage(item.srno, item.posttitle)}>
                                                <a>
                                                    <img
                                                        src={item.featuredimage || "../../assets/images/blog/inner/1.jpg"}
                                                        alt={item.featuredimage ? "Featured Image" : "Default Image"}
                                                        onError={(e) => {
                                                            e.target.style.display = "none";
                                                            const fallbackImage = document.createElement("img");
                                                            fallbackImage.src = "../../assets/images/blog/inner/1.jpg";
                                                            fallbackImage.alt = "Default Image";
                                                            e.target.parentNode.appendChild(fallbackImage);
                                                        }}
                                                    />
                                                </a>
                                                <ul className="post-categories" style={{ left: "20px" }}>
                                                    <li>
                                                        <a to='#' >
                                                            {item.category}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="blog-content">
                                                <h3 className="blog-title">
                                                    <a to='#' onClick={() => showBlogPage(item.srno, item.posttitle)}>
                                                        {item.postheading}
                                                    </a>
                                                </h3>
                                                <div className="blog-meta">
                                                    <ul className="btm-cate">
                                                        <li>
                                                            <div className="blog-date">
                                                                <i className="fa fa-calendar-check-o"></i> {new Date(item.createdon).toLocaleDateString()}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="author">
                                                                <i className="fa fa-user-o"></i> {item.author}
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {/* <div className="blog-desc" dangerouslySetInnerHTML={{ __html: item.content }}>
                                                </div> */}
                                                <div className="blog-button inner-blog">
                                                    {/* <button className="blog-btn" onClick={() => showBlogPage(item.srno, item.posttitle)} >Continue Reading</button> */}
                                                    <a className="blog-btn" onClick={() => showBlogPage(item.srno, item.posttitle)}>Continue Reading</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogCareer;
