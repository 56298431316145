import React, { useState, useEffect } from 'react';
import { transform } from 'typescript';
// import wa_icon from '../../assets/img/contact/whatsapp.png';
import wa_icon from '../../assets/img/contact/WhatsApp_icon.png.webp'

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  // const phoneNumber = '+9109669787936';
  // const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}}`;

  const connectwhatsapp = () => {
    const phoneNumber = '+919669787936';
    const message = `Hi.`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, '_blank').focus();

}

  const styles = {
    container: {
      position: 'fixed',
      bottom: '50px',
      right: '50px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: 1000,
    },
    scrollToTopButton: {
      width: '50px',
      height: '50px',
      padding: '10px 20px',
      fontSize: '18px',
      cursor: 'pointer',
      borderRadius: '50%',
      border: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
      marginTop: '10px',
      backgroundColor: '#fff',
    },
    arrow: {
      display: 'inline-block',
      animation: 'arrowUpward 1.6s infinite',
      fontWeight: 'bold',
      fontSize: '35px',
      transformOrigin: 'center',
    },
    image: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      objectFit: 'cover',
    },
    wabutton: {
      width: '50px',
      height: '50px',
      marginBottom: '10px',
      borderWidth: '0px',
      background:'transparent',
    }
  };

  return (
    <div>
      <style>
        {`
          @keyframes arrowUpward {
            0% { transform: translateY(0) scale(1.4); opacity: 1; }
            40% { transform: translateY(-30px) scale(1.6); opacity: 0; }
            60% { transform: translateY(30px) scale(1.6); opacity: 0; }
            80% { transform: translateY(15px) scale(1.4); opacity: 0.5; }
            100% { transform: translateY(0) scale(1.4); opacity: 1; }
          }
        `}
      </style>
      {isVisible && (
        <div style={styles.container}>
          <button onClick={connectwhatsapp} target="_blank" style={styles.wabutton}>
            <img src={wa_icon} alt="WhatsApp Icon" style={styles.image} />
          </button>
          <button
            className='readon submit'
            onClick={scrollToTop}
            style={styles.scrollToTopButton}
          >
            <span style={styles.arrow}>↑</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default ScrollToTopButton;
