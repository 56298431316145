import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";

const TestimonialsCarousel = () => {
  const [learningTestimonials, setLearningTestimonials] = useState([]);

  const fetchLearningTestimonials = async () => {
    try {
      const learningUrl = `${process.env.REACT_APP_API_URL}testimonials/fetchtestimonials_bycategory_limit/learning`;
      const learningResponse = await axios.get(learningUrl);
      const updatedLearningData = learningResponse.data.map((testimonial) => ({
        ...testimonial,
        image_url: `${process.env.REACT_APP_API_URL}${testimonial.image.replace(/\\/g, "/")}`,
      }));
      setLearningTestimonials(updatedLearningData);
    } catch (err) {
      console.error("Error in fetching learning testimonials: ", err);
    }
  };

  useEffect(() => {
    fetchLearningTestimonials();
  }, []);

  return (
    <div className="rs-testimonial main-home gray-color pt-10 pb-120 md-pt-20 md-pb-80">
      <div className="container">
        <div className="sec-title2 text-center mb-45 mt-50">
          <h2 className="title testi-title">What Our Learners are Saying</h2>
          <div className="heading-line"></div>
        </div>

        {learningTestimonials.length > 0 ? (
          <OwlCarousel
            className="rs-carousel owl-carousel"
            loop={true}
            margin={30}
            autoplay={true}
            autoplayTimeout={5000}
            smartSpeed={800}
            dots={true}
            nav={false}
            responsive={{
              0: { items: 1, dots: false },
              768: { items: 2, dots: true },
              992: { items: 3, dots: true },
            }}
          >
            {learningTestimonials.map((testimonial, index) => (
              <div className="testi-item" key={index}>
                <div className="testi-content">
                  <div
                    className="item-content-basic"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "auto",
                    }}
                  >
                    <p>{testimonial.testimonial}</p>
                  </div>
                  <div
                    className="mid"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <div className="images-wrap">
                      {testimonial.image_url && (
                        <img
                          src={testimonial.image_url}
                          alt={testimonial.name}
                          className="img-fluid rounded-circle"
                          style={{
                            width: "90px",
                            height: "100px",
                            objectFit: "cover",
                            border: "4px solid #eee",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        />
                      )}
                    </div>
                    <div className="testi-information">
                      <div className="testi-name">{testimonial.name}</div>
                      <span className="testi-title">{testimonial.name_bio}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        ) : (
          <div className="text-center">
            <p>No testimonials found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TestimonialsCarousel;
