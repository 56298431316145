import React from 'react';
import { Link } from 'react-router-dom';
import qr from '../../assets/images/hashedbitqr.jpg';

const EnrollmentProcess = () => {
    return (
        <>
            <div class="rs-breadcrumbs img1">
                <div class="breadcrumbs-inner text-center">
                    <h1 class="page-title">About</h1>
                    {/* <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">Home</a>
            </li>
            <li>About</li>
          </ul> */}
                </div>
            </div>





            {/* <!-- Process Section Start --> */}
            <div class="rs-process style2 pt-120 pb-120 md-pt-80 md-pb-73">
                <div class="container">
                    <div class="sec-title2 text-center mb-45">
                        <span class="sub-text style-bg">Course Enrollment Process</span>
                        <h2 class="title title2">
                            Course Enrollment Process
                        </h2>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-sm-6 md-mb-50">
                            <div class="addon-process">
                                <div class="process-wrap">
                                    <div class="process-img">
                                        <img src="assets/images/process/1.png" alt=""></img>
                                    </div>
                                    <div class="process-text">
                                        <h3 class="title">Online Registration</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 md-mb-50">
                            <div class="addon-process">
                                <div class="process-wrap">
                                    <div class="process-img">
                                        <img src="assets/images/process/2.png" alt=""></img>
                                    </div>
                                    <div class="process-text">
                                        <h3 class="title">Registration Fee</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="addon-process">
                                <div class="process-wrap">
                                    <div class="process-img">
                                        <img src="assets/images/process/3.png" alt=""></img>
                                    </div>
                                    <div class="process-text">
                                        <h3 class="title">Onboarding</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="addon-process">
                                <div class="process-wrap">
                                    <div class="process-img">
                                        <img src="assets/images/process/4.png" alt=""></img>
                                    </div>
                                    <div class="process-text">
                                        <h3 class="title">Sessions and Learning</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Process Section End --> */}




            <div class="rs-services style3 modify2 pb-100 md-pt-75 md-pb-80">
                <div class="container">
                    <div class="sec-title2 text-center mb-45">
                        <h2 class="title title7">
                            Registration Fee - 1000 (Payment QR)
                        </h2>
                        <div class="desc">
                            <img src={qr} alt='QR'/>
                        </div>
                    </div>
                    
                </div>
            </div>



            {/* <!-- Services Section Start --> */}
            {/* <div class="rs-services style3 modify2 pt-100 pb-100 md-pt-75 md-pb-80">
                <div class="container">
                    <div class="sec-title2 text-center mb-45">
                        <span class="sub-text gold-color">Why Choose Us</span>
                        <h2 class="title title7">
                            We Create Result-Oriented Dynamic Applications
                        </h2>
                        <div class="desc">
                            Over 10 years working in IT services developing software applications and mobile apps for clients all over the world.
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-20">
                            <div class="services-item">
                                <div class="services-icon">
                                    <div class="image-part">
                                        <img class="main-img" src="assets/images/services/style2/main-img/1.png" alt="" />
                                        <img class="hover-img" src="assets/images/services/style2/hover-img/1.png" alt="" />
                                    </div>
                                </div>
                                <div class="services-content">
                                    <div class="services-text">
                                        <h3 class="title"><Link>Development Team</Link></h3>
                                    </div>
                                    <div class="services-desc">
                                        <p>
                                            Development team consisting of graduates from NITs and IITs.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-20">
                            <div class="services-item pink-bg">
                                <div class="services-icon">
                                    <div class="image-part">
                                        <img class="main-img" src="assets/images/services/style2/main-img/2.png" alt="" />
                                        <img class="hover-img" src="assets/images/services/style2/hover-img/2.png" alt="" />
                                    </div>
                                </div>
                                <div class="services-content">
                                    <div class="services-text">
                                        <h3 class="title"><Link>Software Solutions</Link></h3>
                                    </div>
                                    <div class="services-desc">
                                        <p>
                                            Availability of all types of Web & Software Solutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-20">
                            <div class="services-item aqua-bg">
                                <div class="services-icon">
                                    <div class="image-part">
                                        <img class="main-img" src="assets/images/services/style2/main-img/3.png" alt="" />
                                        <img class="hover-img" src="assets/images/services/style2/hover-img/3.png" alt="" />
                                    </div>
                                </div>
                                <div class="services-content">
                                    <div class="services-text">
                                        <h3 class="title"><Link>Minimum Time & Cost</Link></h3>
                                    </div>
                                    <div class="services-desc">
                                        <p>
                                            Minimum development charge and time taken for the products as compared to others.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-20">
                            <div class="services-item paste-bg">
                                <div class="services-icon">
                                    <div class="image-part">
                                        <img class="main-img" src="assets/images/services/style2/main-img/4.png" alt="" />
                                        <img class="hover-img" src="assets/images/services/style2/hover-img/4.png" alt="" />
                                    </div>
                                </div>
                                <div class="services-content">
                                    <div class="services-text">
                                        <h3 class="title"><Link>Add On Services</Link></h3>
                                    </div>
                                    <div class="services-desc">
                                        <p>
                                            Free Add-On like SEO, Responsive, Unlimited Pages and Design.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 sm-mb-20">
                            <div class="services-item purple-bg">
                                <div class="services-icon">
                                    <div class="image-part">
                                        <img class="main-img" src="assets/images/services/style2/main-img/5.png" alt="" />
                                        <img class="hover-img" src="assets/images/services/style2/hover-img/5.png" alt="" />
                                    </div>
                                </div>
                                <div class="services-content">
                                    <div class="services-text">
                                        <h3 class="title"><Link>Latest Technologies</Link></h3>
                                    </div>
                                    <div class="services-desc">
                                        <p>
                                            Latest Technologies based services for Business Development available here.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 sm-mb-20">
                            <div class="services-item green-bg">
                                <div class="services-icon">
                                    <div class="image-part">
                                        <img class="main-img" src="assets/images/services/style2/main-img/6.png" alt="" />
                                        <img class="hover-img" src="assets/images/services/style2/hover-img/6.png" alt="" />
                                    </div>
                                </div>
                                <div class="services-content">
                                    <div class="services-text">
                                        <h3 class="title"><Link>Project Maintenance</Link></h3>
                                    </div>
                                    <div class="services-desc">
                                        <p>
                                            Proper responsibility of Project Maintenance by Company.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 sm-mb-20">
                            <div class="services-item pink-bg">
                                <div class="services-icon">
                                   <div class="image-part">
                                   <img class="main-img" src="assets/images/services/style2/main-img/6.png" alt="" />
                                   <img class="hover-img" src="assets/images/services/style2/hover-img/6.png" alt="" />
                                   </div>
                               </div>
                                <div class="services-content">
                                    <div class="services-text">
                                        <h3 class="title"><Link>Business Deveopment</Link></h3>
                                    </div>
                                    <div class="services-desc">
                                        <p>
                                            Business Development Consultation & Online Social Marketing.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 sm-mb-20">
                            <div class="services-item red-bg">
                                <div class="services-icon">
                                   <div class="image-part">
                                       <img class="main-img" src="assets/images/services/style2/main-img/5.png" alt=""/>
                                       <img class="hover-img" src="assets/images/services/style2/hover-img/5.png" alt=""/>
                                   </div>
                               </div>
                                <div class="services-content">
                                    <div class="services-text">
                                        <h3 class="title"><Link>Business Analytics</Link></h3>
                                    </div>
                                    <div class="services-desc">
                                        <p>
                                            Saving clients time and money by using company analytics & latest technologies.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <!-- Services Section End --> */}



        </>
    )
}

export default EnrollmentProcess;
