import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import wa_icon from '../assets/img/contact/whatsapp.png';
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { FiRefreshCw } from 'react-icons/fi';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact: '',
        subject: '',
        message: '',
        user_captcha_input: ""
    });
    const [submitMessage, setSubmitMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [errors, setErrors] = useState({
        name: '',
        contact: '',
        subject: '',
        message: ''
    });

    useEffect(() => {
        loadCaptchaEnginge(6, "yellow", "black", 'lower');
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const connectwhatsapp = () => {
        const phoneNumber = '+919669787936';
        const message = `Hi.`;

        const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
        window.open(whatsappLink, '_blank').focus();
    };

    const checkCaptcha = () => {
        const user_captcha_input = formData.user_captcha_input;
        return validateCaptcha(user_captcha_input);
    };

    const validateForm = () => {
        let isValid = true;
        let newErrors = { name: '', contact: '', subject: '', message: '' };

        if (!/^[A-Za-z\s]+$/.test(formData.name)) {
            newErrors.name = 'Name should only contain letters and spaces.';
            isValid = false;
        }

        if (!/^\d+$/.test(formData.contact)) {
            newErrors.contact = 'Contact should only contain numbers.';
            isValid = false;
        }

        if (formData.subject.length > 200) {
            newErrors.subject = 'Subject should be under 200 characters.';
            isValid = false;
        }

        if (formData.message.length > 1000) {
            newErrors.message = 'Message should be under 1000 characters.';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitMessage('');

        if (!validateForm()) {
            return;
        }

        if (!checkCaptcha()) {
            setSubmitMessage('Captcha not valid. Please try again.');
            setIsSuccess(false);
            return;
        }

        try {
            const url = process.env.REACT_APP_API_URL + 'contact/register';
            const response = await axios.post(url, {
                name: formData.name,
                email: formData.email,
                contact: formData.contact,
                subject: formData.subject,
                message: formData.message
            });
            setSubmitMessage('Form submitted successfully!');
            setIsSuccess(true);
            setFormData({
                name: '',
                email: '',
                contact: '',
                subject: '',
                message: '',
                user_captcha_input: ""
            });
        } catch (error) {
            console.error(error);
            setSubmitMessage('Form submission failed. Please try again.');
            setIsSuccess(false);
        }
    };

    return (
        <>
            <div className="rs-breadcrumbs img3">
                <div className="breadcrumbs-inner text-center">
                    <h1 className="page-title">Contact Us</h1>
                </div>
            </div>

            <div className="rs-why-choose style2 gray-color rs-rain-animate pt-120 pb-120 md-pt-70 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-7 md-mb-30">
                            <div className="sec-title mb-40">
                                <div className="sub-text style4-bg left">Let's Talk</div>
                                <h2 className="title pb-20">Speak With Expert Engineers.</h2>
                                <div className="desc">
                                    Have an inquiry or some feedback for us?<br />
                                    Fill out the form.
                                </div>
                            </div>
                            <div className="services-wrap mb-25">
                                <div className="services-icon">
                                    <img src="assets/images/choose/icons/style2/1.png" alt="Email Icon" />
                                </div>
                                <div className="services-text">
                                    <h3 className="title"><Link to="#">Email</Link></h3>
                                    <p className="services-txt">General - info@hashedbit.com</p>
                                    <p className="services-txt">General - hashedbit@gmail.com</p>
                                    <p className="services-txt">HR - hr@hashedbit.com</p>
                                </div>
                            </div>
                            <div className="services-wrap mb-25">
                                <div className="services-icon">
                                    <img src="assets/images/choose/icons/style2/2.png" alt="Call Us Icon" />
                                </div>
                                <div className="services-text">
                                    <h3 className="title"><Link to="#">Call Us</Link></h3>
                                    <p className="services-txt">+91 9669787936</p>
                                    <p className="services-txt">+91 9599171535</p>
                                </div>
                            </div>
                            <div className="services-wrap">
                                <div className="services-icon">
                                    <img src="assets/images/choose/icons/style2/3.png" alt="Office Address Icon" />
                                </div>
                                <div className="services-text">
                                    <h3 className="title"><Link to="#">Office Address</Link></h3>
                                    <p className="services-txt">Headquarter - Gurgaon</p>
                                </div>
                            </div>
                            <br />
                            <div className="services-wrap">
                                <div className="services-icon">
                                    <img src={wa_icon} alt="WhatsApp Icon" />
                                </div>
                                <div className="services-text" onClick={connectwhatsapp}>
                                    <h3 className="title"><a href="#">Whatsapp</a></h3>
                                    <p className="services-txt">+91 9669787936</p>
                                    <p className="services-txt">+91 9599171535</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5">
                            <div className="rs-contact mod1">
                                <div className="contact-wrap">
                                    <div className="content-part mb-25">
                                        <h2 className="title mb-15">Leave a message</h2>
                                    </div>
                                    <div id="appointment-messages"></div>
                                    <form id="appointment-form" onSubmit={handleSubmit}>
                                        <fieldset>
                                            <div className="row">
                                                <div className="col-lg-12 mb-15">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="name"
                                                        placeholder="Name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        pattern="[A-Za-z ]+"
                                                        title="Name should only contain letters"
                                                        required
                                                    />
                                                    {errors.name && <p className="text-danger">{errors.name}</p>}
                                                </div>
                                                <div className="col-lg-12 mb-15">
                                                    <input
                                                        className="form-control"
                                                        type="email"
                                                        name="email"
                                                        placeholder="Email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-lg-12 mb-15">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="contact"
                                                        placeholder="Contact"
                                                        value={formData.contact}
                                                        onChange={handleChange}
                                                        pattern="\d*"
                                                        title="Contact should only contain numbers"
                                                        required
                                                    />
                                                    {errors.contact && <p className="text-danger">{errors.contact}</p>}
                                                </div>
                                                <div className="col-lg-12 mb-25">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="subject"
                                                        placeholder="Subject"
                                                        value={formData.subject}
                                                        onChange={handleChange}
                                                        maxLength="200"
                                                        title="Subject should be under 200 characters"
                                                        required
                                                    />
                                                    {errors.subject && <p className="text-danger">{errors.subject}</p>}
                                                </div>
                                                <div className="col-lg-12 mb-25">
                                                    <textarea
                                                        className="form-control"
                                                        name="message"
                                                        placeholder="Message"
                                                        value={formData.message}
                                                        onChange={handleChange}
                                                        maxLength="1000"
                                                        rows="5"
                                                        title="Message should be under 1000 characters"
                                                        required
                                                    />
                                                    {errors.message && <p className="text-danger">{errors.message}</p>}
                                                </div>
                                                <div className="col-lg-12 mb-25">
                                                    <div className="d-flex">
                                                        <LoadCanvasTemplateNoReload />
                                                        <div
                                                            className="text-primary mb-2 ml-2"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <FiRefreshCw
                                                                className="text-primary"
                                                                size={20}
                                                                title="Reload Captcha"
                                                                onClick={() =>
                                                                    loadCaptchaEnginge(6, "yellow", "black", "lower")
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="user_captcha_input"
                                                        value={formData.user_captcha_input}
                                                        placeholder="Please Enter Captcha"
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group mb-0">
                                                <input className="submit-btn" type="submit" value="Submit Now" />
                                            </div>
                                            {submitMessage && (
                                                <p
                                                    className={`alert ${isSuccess ? "alert-success" : "alert-danger"} mt-3`}
                                                    role="alert"
                                                >
                                                    {submitMessage}
                                                </p>
                                            )}
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="line-inner">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </div>
        </>
    );
}

export default Contact;
