import React, { useState, useEffect } from "react";
import MemberImage from "../../assets/images/Portfolio/Images/1.jpg";
import axios from "axios";
import "../../assets/images/Portfolio/style.css";
import { Link } from "react-router-dom";

const Portfolio = () => {
  const [portfolios, setPortfolios] = useState([]);

  //http://localhost:4000/portfolio/getPortfolio
  useEffect(() => {
    const fetchPortfolios = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}portfolio/getPortfolio`
      );
      const updatedPortfolio = result.data.map((portfolio) => ({
        ...portfolio,
        image: `${process.env.REACT_APP_API_URL}${portfolio.image.replace(
          /\\/g,
          "/"
        )}`,
      }));
      setPortfolios(updatedPortfolio);
    };

    fetchPortfolios();
  }, []);

  return (
    <>
      <div class="rs-breadcrumbs img2">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Portfolio</h1>
          {/* <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">
                Home
              </a>
            </li>
            <li>Portfolio</li>
          </ul> */}
        </div>
      </div>

      {/* <!-- Portfolio Section Start --> */}
      <div class="rs-about pt-120 pb-40 md-pt-80 md-pb-80">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 pr-40 md-pr-15 md-mb-50">
              <div class="sec-title4">
                <span class="sub-text">Portfolio</span>
                <h2 class="title">
                  We Help to Implement Your Ideas into Automation
                </h2>
                <div class="heading-line"></div>
                {/* <div class="desc desc-big">
                  We donec pulvinar magna id leoersi pellentesque impered
                  dignissim rhoncus euismod euismod eros vitae best consulting &
                  financial services theme .
                </div>
                <div class="desc">
                  Business ipsum dolor sit amet nsectetur cing elit. Suspe
                  ndisse suscipit sagittis leo sit met entum is not estibulum
                  dignity sim posuere cubilia durae. Leo sit met entum cubilia
                  crae. At vero eos accusamus et iusto odio dignissimos
                  provident nam libero tempore, cum soluta.
                </div> */}
                <div class="btn-part mt-45">
                  <Link class="readon learn-more" to="/contact">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="software-img">
                <img src={MemberImage} alt="images" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Portfolio Section END --> */}


      {/* Previous Clients  start*/}
      <div class="section py-5">
        <div class="container reveal">
          <div class="section-title text-center mb-5">
            <h2 class="text-section-title text-neutral-5">Some of our Producst / Projects</h2>
            {/* <p class="text-section-subtitle text-neutral-4">
              Here are some of the clients I've worked with previously.
            </p> */}
          </div>
          <div class="row">
            {portfolios.map((portfolio, index) => (
              <div className="col-lg-4 col-md-6 mb-4" key={index}>
                <div className="previous-client-wrap reveal h-100 p-2 bg-white shadow-sm rounded d-flex flex-column justify-content-center align-items-center">
                  <div className="square-helper mb-3">
                    <img
                      src={portfolio.image}
                      alt={portfolio.name}
                      class="previous-client-logotype img-fluid rounded"
                    />
                  </div>
                  <h6 className="mt-2">{portfolio.name}</h6>
                  <p className="mt--18"><a href={portfolio.url} target="_blank">Website Link</a></p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Previous Clients  End */}
    </>
  );
};

export default Portfolio;
