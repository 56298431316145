import React from 'react';

import oes from './onlineexam.jpeg';

// icon import

import image1 from '../../assets/services/style2/main-img/1.png';
import imghover1 from '../../assets/services/style2/hover-img/1.png';
import image2 from '../../assets/services/style2/main-img/2.png';
import imghover2 from '../../assets/services/style2/hover-img/2.png';
import image3 from '../../assets/services/style2/main-img/3.png';
import imghover3 from '../../assets/services/style2/hover-img/3.png';
import img1  from '../../assets/services/style6/1.png';
import img2  from '../../assets/services/style6/2.png';
import img3  from '../../assets/services/style6/3.png';
import img4  from '../../assets/services/style6/4.png';
import img5  from '../../assets/services/style6/5.png';
import img6  from '../../assets/services/style7/1.png';
import img7  from '../../assets/services/style7/2.png';





const OnlineExamination = () => {
  return (
   <>
   
   <div class="rs-breadcrumbs img3">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title"> Online Examination System</h1>
          {/* <ul>
                        <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                            <a class="active" href="index.html">Home</a>
                        </li>
                        <li>Services</li>                       
                    </ul> */}
        </div>
      </div>

      {/* <div className="contact-section bg-home3 py-6">
        <div className="container my-5">
          <div className="row  ">
            <div className="col  ">
              <h1 className="text-start fw-bold  ">
                School <br /> Management System
              </h1>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>

            <div className="col-lg-6 right img-fluid ">
              <div className="pe-0">
                <img
                  src={sms}
                  alt="img"
                  className="img-responsive"
                  style={{ maxWidth: "90%", height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/*  */}
      {/* <section className="bg-light py-5 ">
        <div className="container">
          <div className="row  py-5">
            <div className="col  ">
              <div className="">
                <div className=" " style={{maxWidth: "640px"}}>
                  <span className="text-danger ">Product</span>
                  <h1 className="fw-bold mb-4 ">School Management System</h1>
                  <p className="text-wrap text-justify">
                    Transform your school's management process through our
                    school management system. It is a cloud-based system that
                    eases the management of all the data from student details to
                    exam results and tasks like admission and staff management.
                    It provides a dashboard to access data.
                  </p>
                  <p className="text-wrap text-justify">
                    School administration has the responsibility of managing all
                    the affairs, but it's a tedious task and errors are
                    inevitable in handling huge amounts of data. Our school's
                    management system is a boon for the management people, it
                    makes their work hassle-free and enhances work productivity.
                  </p>
                  <p className="text-wrap text-justify">
                    We curate management systems that suit the requirements of
                    the institute and then digitize their day-to-day processes.
                    With multiple modules and seamless functioning, and secure
                    operations, we deliver the best solution for school
                    management.
                  </p>
                  <p className="text-wrap text-justify">
                    It not only makes the process easy but also saves money by
                    effectively managing the fees and expenses and enabling the
                    management people to track the finances and analyze the
                    expenditures.
                  </p>
                </div>
              </div>
            </div> */}

      <div class="rs-why-choose style2 gray-color rs-rain-animate pt-120 pb-120 md-pt-70 md-pb-80">
        <div class="container">
          <div class="row">
            <div class="col-xl-6 col-lg-6 md-mb-30">
              <div class="sec-title mb-40">
                <div class="sub-text style4-bg left">Product</div>
                <h2 class="title pb-20">
                Online Examination System
                </h2>
                <div class="desc">
                  <p className="text-wrap text-justify">
                  Welcome to our state-of-the-art online examination system, engineered to elevate your institution's assessment capabilities. 
                  Whether you're in education, corporate training, or certification, our platform offers a comprehensive suite of features designed 
                  to streamline exam management and ensure secure, efficient assessments.
                  </p>
                  <p className="text-wrap text-justify">
                  Our online examination system is more than just a tool—it's a partner in transforming your assessment processes. Backed by cutting-edge 
                  technology and a commitment to user satisfaction, we empower you to conduct exams efficiently and securely. Whether you're conducting 
                  internal assessments or large-scale certification exams, our platform adapts to your needs with reliability and precision.
                  </p>
                   <p className="text-wrap text-justify">
                   Ready to revolutionise your assessment processes? Contact us  and see how our online examination system can enhance efficiency, security, 
                   and transparency in your exams.
                  </p>
                 

                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6">

              <img
                src={oes}
                alt="img"
                className="img-responsive"
              />

              {/* <div className="shadow-lg p-3 bg-white rounded-5 ">
                <h2 className="fw-bold mt-4">Schedule Appointment</h2>
                <p>We are here to help you 24/7 with experts</p>
                <form action="" method="">
                  <div className=" my-4">
                    <label>Name</label>
                    <input
                      type="text"
                      required=""
                      className="form-control"
                      name="contact_name"
                      placeholder="Name"
                    />
                  </div>
                  <div className=" mt-4">
                    <label>Email</label>
                    <input
                      type="email"
                      required=""
                      className="form-control"
                      name="contact_email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label>Phone</label>
                    <input
                      type="tel"
                      required=""
                      className="form-control"
                      name="contact_phone"
                      placeholder="+91"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label>Your Query</label>
                    <textarea
                      type="Subject"
                      required=""
                      className="form-control"
                      name="message"
                      placeholder="Your Query"
                    ></textarea>
                  </div>

                  <div
                    className="g-recaptcha mt-4"
                    data-sitekey="6Ld_yKMjAAAAAJpYQr_Vd8AflYwHsRfy7vKzy_9r"
                  >
                    <div style={{ width: "304px", height: "78px" }}>
                      <div>
                        <iframe
                          title="reCAPTCHA"
                          width="304"
                          height="78"
                          role="presentation"
                          name="a-tnvqec2kjuv"
                          frameborder="0"
                          scrolling="no"
                          sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                          src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Ld_yKMjAAAAAJpYQr_Vd8AflYwHsRfy7vKzy_9r&amp;co=aHR0cHM6Ly9jb3Jld2F2ZS5pbzo0NDM.&amp;hl=en&amp;v=1kRDYC3bfA-o6-tsWzIBvp7k&amp;size=normal&amp;cb=rwx58wbrxjrt"
                        ></iframe>
                      </div>
                      <textarea
                        id="g-recaptcha-response-1"
                        name="g-recaptcha-response"
                        className="g-recaptcha-response"
                        style={{
                          width: " 250px",
                          height: "40px",
                          border: "1px solid rgb(193, 193, 193)",
                          margin: " 10px 25px",
                          padding: " 0px",
                          resize: "none",
                          display: " none",
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <br />

                  <div className=" d-flex justify-content-center">
                    <input
                      type="submit"
                      className="btn btn-primary btn-lg mx-1 "
                      style={{ width: "280px" }}
                      name="submit_btn"
                      value="Submit Now"
                    />
                  </div>
                </form>
              </div> */}
            </div>

            {/* </div></div><div className="col mt-3 ms-3" style={{maxWidth: "500px"}}>
              <div className="shadow-lg p-3 bg-white rounded-5 ">
                <h2 className="fw-bold mt-4">Schedule Appointment</h2>
                <p>We here to help you 24/7 with experts</p>
                <form action="" method="">
                  <div className=" my-4">
                    <label>Name</label>
                    <input
                      type="text"
                      required=""
                      className="form-control"
                      name="contact_name"
                      placeholder="Name"
                    />
                  </div>
                  <div className=" mt-4">
                    <label>Email</label>
                    <input
                      type="email"
                      required=""
                      className="form-control"
                      name="contact_email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label>Phone</label>
                    <input
                      type="tel"
                      required=""
                      className="form-control"
                      name="contact_phone"
                      placeholder="+91"
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label>Your Query</label>
                    <textarea
                      type="Subject"
                      required=""
                      className="form-control"
                      name="message"
                      placeholder="Your Query"
                    ></textarea>
                  </div>

                  
                  <div
                    className="g-recaptcha mt-4"
                    data-sitekey="6Ld_yKMjAAAAAJpYQr_Vd8AflYwHsRfy7vKzy_9r"
                  >
                    <div style={{ width: "304px", height: "78px" }}>
                      <div>
                        <iframe
                          title="reCAPTCHA"
                          width="304"
                          height="78"
                          role="presentation"
                          name="a-tnvqec2kjuv"
                          frameborder="0"
                          scrolling="no"
                          sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                          src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Ld_yKMjAAAAAJpYQr_Vd8AflYwHsRfy7vKzy_9r&amp;co=aHR0cHM6Ly9jb3Jld2F2ZS5pbzo0NDM.&amp;hl=en&amp;v=1kRDYC3bfA-o6-tsWzIBvp7k&amp;size=normal&amp;cb=rwx58wbrxjrt"
                        ></iframe>
                      </div>
                      <textarea
                        id="g-recaptcha-response-1"
                        name="g-recaptcha-response"
                        className="g-recaptcha-response"
                        style={{
                          width: " 250px",
                          height: "40px",
                          border: "1px solid rgb(193, 193, 193)",
                          margin: " 10px 25px",
                          padding: " 0px",
                          resize: "none",
                          display: " none",
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <br />

                  <div className=" d-flex justify-content-center">
                    <input
                      type="submit"
                      className="btn btn-primary btn-lg mx-1 "
                      style={{ width: "280px" }}
                      name="submit_btn"
                      value="Submit Now"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> >*/}


          </div>
        </div>
        <div class="line-inner">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </div>

      {/* */}
      {/* <section className="section px-4 py-5 bg-dark">
        <div className="container">
          <div className=" mt-5 ">
            <h2 className="display-5 fw-bold text-white">
              Benefits of School management system
            </h2>
            <p className="opacity-20 py-4">
              Our school management system is advanced multi-capacity software
              that enables schools and educational institutes to streamline
              their processes and helps them manage each and every aspect of
              management. From fee collection to designing curriculum, tracking
              attendance, and generating payrolls, all can be done easily
              through our robust school management system. It is packed with
              features that transform traditional school management functions
              into a smart and error-free system.
            </p>
          </div>
          <div className="row gap-0 row-gap-5 py-5">
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content ">
                  <h5 className="text-white "> Effective Management</h5>
                  <p>Digitized data management</p>
                  <p>Easy data analysis&nbsp;</p>
                  <p>Student profile tracking</p>
                  <p>Graphic report generation</p>
                  <p>Monitor finances</p>
                  <p>Access data in multiple formats</p>
                  <p>Track staff activities</p>
                  <p>Simplify admission process</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid active">
                <div className="content">
                  <h5 className="text-white"> Improves teaching</h5>
                  <p>AI-assisted lesson plans</p>
                  <p>Track students performance</p>
                  <p>Analyze assessments and tests.</p>
                  <p>Understand learning gaps</p>
                  <p>Attendance management</p>
                  <p>Activity records in the classroom</p>
                  <p>Share educational resources</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 ">
              <div className="services-grid">
                <div className="content">
                  <h5 className="text-white"> Go Digital</h5>
                  <p>Online payments gateway</p>
                  <p>Online receipt generation</p>
                  <p>Fill Admission form online</p>
                  <p>Easy access to prospectus and curriculum</p>
                  <p>Access results&nbsp;</p>
                  <p>Convenient eLearning</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/*  */}
      <div class="rs-services style3 pt-110 pb-120 md-pt-75 md-pb-80">
        <div class="container">
          <div class="sec-title2 text-center mb-45">
            {/* <span class="sub-text">Services</span> */}
            <h2 class="title testi-title">
            Features 

            </h2>
            {/* <p className="opacity-20 py-4">
              Our school management system is advanced multi-capacity software
              that enables schools and educational institutes to streamline
              their processes and helps them manage each and every aspect of
              management. From fee collection to designing curriculum, tracking
              attendance, and generating payrolls, all can be done easily
              through our robust school management system. It is packed with
              features that transform traditional school management functions
              into a smart and error-free system.
            </p> */}
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 mb-20">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src={image1} alt="" />
                    <img class="hover-img" src={imghover1} alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="software-development.html"> User-friendly Interface </a></h3>
                  </div>
                  <div class="services-desc">
                
                   <p>Our system boasts an intuitive interface designed for ease of use across devices, ensuring smooth navigation 
                   for administrators, instructors, and students. Clear layouts and responsive design enhance usability, providing a 
                   seamless exam experience.</p>
                  </div>
                  {/* <div class="serial-number">
                                            01 
                                        </div> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-20">
              <div class="services-item pink-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src={image2} alt="" />
                    <img class="hover-img" src={imghover2} alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="web-development.html">Flexible Exam Creation </a></h3>
                  </div>
                  <div class="services-desc">
                    


                    <p>
                    Administrators can create diverse exams with ease, leveraging tools that support multiple 
                    question types (e.g., multiple-choice, essay) and customizable settings such as time limits and 
                    question randomization. This flexibility caters to varied assessment needs while maintaining 
                    exam integrity.
                    </p>
                  </div>
                  {/* <div class="serial-number">
                                            02 
                                        </div> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-20">
              <div class="services-item aqua-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src={image3} alt="" />
                    <img class="hover-img" src={imghover3} alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><a href="analytic-solutions.html">Comprehensive Question Bank</a></h3>
                  </div>
                  <div class="services-desc">
                  

                    <p>
                    A centralized repository allows for efficient management of questions categorized by subject, 
                    topic, or difficulty level. Powerful search and tagging features facilitate quick retrieval and 
                    reuse of questions, streamlining the exam creation process and ensuring content consistency.
                    </p>
                  </div>
                  {/* <div class="serial-number">
                                            03 
                                        </div> */}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* <section className=" py-5 bg-light">
        <div className="container ">
          <div className="col-lg-12 ">
            <h1 className="fw-bold py-5 ms-2 ">Modules</h1>
          </div>
          <div className="row mb-3 d-flex flex-wrap">
            <div className=" col-lg-6 m-3  p-3 bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3">Administration</h3>
              </div>
              <p className="">
                Just through this single module the administrators can easily
                manage the central database and control the activities and keep
                track of every process. This is a major management module that
                helps in maintaining the finances most efficiently. Also, the
                managers can track the school inventory and access records of
                all the items present. Our cloud-based school management system
                is one of the best software that ensures transparency and
                accuracy.
              </p>
            </div>

            <div className=" col  m-3  p-3   bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3"> Student</h3>
              </div>
              <p>
                This module contains all the information regarding the students
                making it easy to find all the details in one place. It stores
                student information, classwise performance details, and
                attendance all organized into groups and can be fetched with
                just one click. It has user-based access and is interlinked with
                other modules so provides hassle-free information.
              </p>
            </div>
          </div>
          <div className="row  mb-3 d-flex flex-wrap">
            <div className=" col-lg-6 m-3  p-3 bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3">Fees</h3>
              </div>
              <p>
                Our school management system makes fee collection and management
                easy. This module stores details about fee payments, late fees,
                fees due, and other related information. The module is
                integrated with accounting to ensure efficient management of
                funds. It has automated reminders to send SMS to parents for fee
                submission and helps schools easily trace defaulters.
              </p>
            </div>
            <div className="col  m-3  p-3  bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3"> Attendance</h3>
              </div>
              <p>
                This eliminates the need for the old pen and register attendance
                and enables quick and efficient attendance recording. It helps
                in digitally storing student attendance and enables easy access
                and report generation. The management can monitor absentee's
                records and can receive and approve leaves. Our cloud-based
                software comes with advanced features and can be integrated with
                biometric devices too. It provides organized and systematically
                stored data at any time. Safe and secured data storage Easy
                recording Organized data logs
              </p>
            </div>
          </div>
          <div className="row  mb-3 d-flex flex-wrap">
            <div className=" col-lg-6 m-3  p-3 bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3"> Exams</h3>
              </div>
              <p>
                Conducting exams will be easy in this module as it enables
                schools to plan and schedule exams. It also helps in creating
                accurate and well-designed report cards. Through this schools
                can generate results and mark sheets more efficiently and in
                less time. Students and their parents can also access markets
                and results through this module. It also provides performance
                analysis reports that help in understanding students' potential
                areas of strengths and weaknesses.
                <ul>
                  <li>Reliable results in no time</li>
                  <li>Digital mark sheets </li>
                  <li>Easy to use</li>
                </ul>
              </p>
            </div>
            <div className="col  m-3  p-3  bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3">Lesson plans</h3>
              </div>
              <p>
                This module helps in curating easy lesson plans with all the
                information about the syllabus and topics in one place. Teachers
                can strategize lesson plans based on class performance and can
                easily mark the progress. The modules teachers manage the
                syllabus for the best results.
                <ul>
                  <li>Manage lesson plans</li>
                  <li>Manage syllabus</li>
                  <li>List topics</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="row  mb-3 d-flex flex-wrap">
            <div className=" col-lg-6 m-3  p-3 bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3">Human resource</h3>
              </div>
              <p>
                Human resource management is made easy through our exceptional
                software which allows schools to manage every aspect of their
                teaching and non-teaching staff through this module. It stores
                information and details about the staff, records staff
                attendance, and assists in leave approvals, and other such
                management processes. It also helps in payroll management and
                stores payment transfer data to ensure accuracy in salary
                management. The software also helps online recruitment where the
                candidates can apply easily and the school can screen and select
                the right candidate.
              </p>
            </div>
            <div className="col  m-3  p-3  bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3">Online classes</h3>
              </div>
              <p>
                We help schools in transforming their education system through
                our software that provides the feature of online classes that
                allows more accessible and convenient learning. The student can
                join online classes, watch recorded lectures, and can
              </p>
            </div>
          </div>
          <div className="row  mb-3 d-flex flex-wrap">
            <div className=" col-lg-6 m-3  p-3 bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3">Communication</h3>
              </div>
              <p>
                This module enables school management can stay connected with
                the staff, student, and their parents. The AI-powered software
                allows for generating and sending automated SMS and emails to
                teachers and parents. The school can easily manage and update
                information on the notice board through this module.
                <ol>
                  <li>Send SMS</li>
                  <li>Send email</li>
                  <li>Notice board</li>
                </ol>
              </p>
            </div>
            <div className=" col  m-3  p-3  bg-white rounded-5 ">
              <div className="">
                <h3 className="py-3">Download Resources</h3>
              </div>
              <p>
                This module allows users to download resources like study
                material, assignments, and syllabi. Teachers and management
                staff can upload the content with ease, making it accessible for
                all. The software helps in the dissemination of resources and
                students can find all the resources in one place.
                <ol>
                  <li>Study material</li>
                  <li>Upload content</li>
                  <li>Assignment </li>
                  <li>Syllabus</li>
                </ol>
              </p>
            </div>
          </div>
        </div>
      </section>
       */}

       <div class="sec-title2 text-center mb-45">
            {/* <span class="sub-text">Services</span> */}
            <h2 class="title testi-title">
            Why Choose Us 
            </h2>
            </div>
            
       <div class="rs-services main-home style3 bg13 pt-120 pb-120 md-pt-80 md-pb-80">
        <div class="container pt-relative">
          {/* <div class="sec-left">
             <h2 class="title wow fadeInDown">Why Choose Us</h2> 
          </div>  */}



          <div class="row">
            <div class="col-lg-4 col-md-6 mb-25">
              <div class="services-item">
                 <div class="services-icon">
                  <div class="image-part">
                    <img src={img1} alt="" />
                  </div>
                </div> 
                
                
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html"> Security Measures </a></h3>
                  </div>
                  <div class="services-desc">
                 

                    <p>
                    We prioritize exam security with robust features like data encryption,
                     secure user authentication, and browser lockdown during exams to prevent 
                     unauthorized access and ensure confidentiality. These measures comply with 
                     stringent data protection standards, fostering trust among users.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-25">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src={img2} alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Real-time Monitoring </a></h3>
                  </div>
                  <div class="services-desc">
             
                    <p>
                    Administrators can monitor exam sessions in real-time, tracking participant 
                    activities and detecting anomalies promptly. Live session tracking and activity 
                    logs enable proactive management, maintaining fairness and integrity throughout the 
                    exam process.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-25">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src={img3} alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Automated Grading</a></h3>
                  </div>
                  <div class="services-desc">
                  <p>
                  Objective questions are automatically graded upon submission, providing instant feedback to students 
                  and saving instructors valuable time. This feature enhances efficiency in assessment while ensuring
                  accuracy and consistency in grading.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-25">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src={img4} alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Analytics and Reporting</a></h3>
                  </div>
                  <div class="services-desc">
                  
                  <p>
                  Detailed analytics dashboards offer insights into exam performance trends, participant 
                  engagement, and item analysis. Customizable reports empower administrators to make informed 
                  decisions and optimize exam outcomes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-25">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src={img5} alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Customization</a></h3>
                  </div>
                  <div class="services-desc">
                

                    <p>
                    Our platform supports branding customization, allowing organizations to personalize the 
                    interface with logos, colors, and themes. Configurable settings adapt to institutional 
                    requirements, enhancing the platform's adaptability and user engagement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-25">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src={img6} alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Accessibility</a></h3>
                  </div>
                  <div class="services-desc">
                 
                    <p>
                    Compliance with accessibility standards such as WCAG ensures inclusivity, 
                    providing features like screen reader compatibility and keyboard navigation. 
                    This commitment to accessibility promotes equal access to exam content for all participants.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-25">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src={img7} alt="" />
                  </div>
                </div>

                
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Integration</a></h3>
                  </div>
                  <div class="services-desc">
                  
                    <p>
                    Seamless integration with Learning Management Systems (LMS) and Student Information 
                    Systems (SIS) facilitates data synchronization and workflow efficiency. This interoperability 
                    streamlines administrative tasks and enhances user experience by centralizing educational processes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="col-lg-4 col-md-6">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src="assets/images/services/style6/6.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Online Classes</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      We help schools in transforming their education system through
                      our software that provides the feature of online classes that
                      allows more accessible and convenient learning. The student can
                      join online classes, watch recorded lectures, and can do assignments.
                    </p>
                  </div>
                </div>
              </div>
            </div> */}


            {/* <div class="col-lg-4 col-md-6">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src="assets/images/services/style6/6.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Communication</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      This module enables school management can stay connected with
                      the staff, student, and their parents. The AI-powered software
                      allows for generating and sending automated SMS and emails to
                      teachers and parents. The school can easily manage and update
                      information on the notice board through this module.
                      <ol>
                        <li>Send SMS</li>
                        <li>Send email</li>
                        <li>Notice board</li>
                      </ol>
                    </p>
                  </div>
                </div>
              </div>
            </div> */}


            {/* <div class="col-lg-4 col-md-6">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img src="assets/images/services/style6/6.png" alt="" />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="services-title"><a href="web-development.html">Download Resources</a></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      This module allows users to download resources like study
                      material, assignments, and syllabi. Teachers and management
                      staff can upload the content with ease, making it accessible for
                      all. The software helps in the dissemination of resources and
                      students can find all the resources in one place.
                      <ol>
                        <li>Study material</li>
                        <li>Upload content</li>
                        <li>Assignment </li>
                        <li>Syllabus</li>
                      </ol>
                    </p>
                  </div>
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </div>

      {/*  */}

      {/* <div className="  bg-info bg-gradient px-4 py-5  text-center">
        <p className="text-success-emphasis mb-2">Let's Work Together</p>
        <h1 className="display-5 fw-bold text-info text-body-emphasis py-5">
          Need A Succesful Project?
        </h1>
        <div className="col-lg-6 mx-auto py-2">
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <button type="button" className="btn btn-primary btn-lg px-5 gap-3">
              Estimate project
            </button>
          </div>
        </div>
      </div> */}

      <div class="rs-call-action bg8 pt-120 pb-120 md-pt-75 md-pb-80">
        <div class="container">
          <div class="sec-title2 text-center">
            <span class="sub-text white-color"> Let's Work Together</span>
            <h2 class="title title4 white-color">
              Need A Succesful Project?
            </h2>
            <div class="call-btn mt-30">
              <a class="readon discover started" href="contact.html">Estimate project</a>
            </div>
          </div>
        </div>
      </div>




 
</>
  );
};

export default   OnlineExamination;
