import React, { useState } from 'react';
import DueDateCalculator from './DueDateCalculator';
import DueDateChart from './DueDateChart';

const DueDate = () => {
  const [savedCalculations, setSavedCalculations] = useState([]);

  const handleSaveCalculation = (calculation) => {
    setSavedCalculations([...savedCalculations, calculation]);
  };

  const handleClearCalculations = () => {
    setSavedCalculations([]);
  };

  return (
    <div className="container mt-4 mb-4">
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div className="calculator-container p-3 border rounded"  style={{ boxShadow: '0 0 2px rgba(0, 0, 0, 0.2)' }}>
                <DueDateCalculator onSaveCalculation={handleSaveCalculation} />
              </div>
            </div>

            <div className="col-md-6">
              <div>
                <DueDateChart savedCalculations={savedCalculations} />
                <button className="btn btn-secondary mt-2" style={{ width: '100%',} } onClick={handleClearCalculations}>
                  Clear Calculations
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DueDate;