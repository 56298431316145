import React from 'react';

const RDResult = (props) => {
    const { maturityAmount, monthlyAmount, tenureUnit, duration, interest } = props;

    // Calculate the total invested amount
    const calculateTotalInvestedAmount = () => {
        let totalDuration;
        switch (tenureUnit) {
            case 'years':
                totalDuration = duration * 12;
                break;
            case 'days':
                totalDuration = duration / 30;
                break;
            default: 
                totalDuration = duration;
                break;
        }
        return totalDuration * monthlyAmount;
    };

    const investedAmount = calculateTotalInvestedAmount().toFixed(2);
    const totalMaturityValue = parseFloat(maturityAmount).toFixed(2);
    // Ensure amount and interest are numbers
    const validAmount = parseFloat(investedAmount) || 25000;
    const validInterest = parseFloat(totalMaturityValue) || 30000;

    return (
        <div>
            <p className="mt-4 mb-1 text-center">
                <span className="font-weight-bold fs-6">Total Invested Amount: </span>₹{' '}
                {validAmount}
            </p>
            <p className="mt-1 mb-1 text-center">
                <span className="font-weight-bold fs-6">Maturity Amount: </span>₹{' '}
                {validInterest}
            </p>
            <p className="mt-1 mb-1 text-center">
                <span className="font-weight-bold fs-6">Interest: </span>₹{' '}
                {interest || 3220}
            </p>
        </div>
    );
};

export default RDResult;
