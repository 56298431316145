import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import EMIPieChart from './EMIPieChart';
// import '.'
const EMICalculator = () => {
  const [principal, setPrincipal] = useState(500000);
  const [rate, setRate] = useState(7);
  const [tenure, setTenure] = useState(5);
  const [emi, setEmi] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    calculateEMI();
  }, [principal, rate, tenure]);

  const calculateEMI = () => {
    const monthlyRate = rate / (12 * 100);
    const tenureInMonths = tenure * 12;
    const emi = Math.round(
      (principal * monthlyRate * Math.pow(1 + monthlyRate, tenureInMonths)) /
      (Math.pow(1 + monthlyRate, tenureInMonths) - 1)
    );
    const totalAmount = Math.round(emi * tenureInMonths);
    const totalInterest = Math.round(totalAmount - principal);

    setEmi(emi);
    setTotalAmount(totalAmount);
    setTotalInterest(totalInterest);
  };

  return (
    <div className="container mt-5 mb-5 border p-5  ">
      <h2 className="text-center mb-4 ">EMI Calculator</h2>
      <div className="row">
        <div className="col-md-8">
          <div className="row mb-4">
            <div className="col-md-11">
              <label className="form-label">Principal Amount: ₹{principal}</label>
              <input
                type="range"
                className="form-range"
                min="100000"
                max="10000000"
                step="10000"
                value={principal}
                onChange={(e) => setPrincipal(e.target.value)}
              />
            </div>
            <div className="col-md-11">
              <label className="form-label">Annual Interest Rate: {rate}%</label>
              <input
                type="range"
                className="form-range"
                min="1"
                max="20"
                step="0.1"
                value={rate}
                onChange={(e) => setRate(e.target.value)}
              />
            </div>
            <div className="col-md-11">
              <label className="form-label">Loan Tenure: {tenure} Years</label>
              <input
                type="range"
                className="form-range"
                min="1"
                max="30"
                step="1"
                value={tenure}
                onChange={(e) => setTenure(e.target.value)}
              />
            </div>
          </div>
          <div className="card p-4 pt-sans-regular" >
            <div className="row">
              <div className="col-md-6 text-start">
                <p>Monthly EMI:</p>
                <p>Total Principal Paid:</p>
                <p>Total Interest Paid:</p>
                <p>Total Amount Paid:</p>
              </div>
              <div className="col-md-6 text-end">
                <p>₹{emi}</p>
                <p>₹{principal}</p>
                <p>₹{totalInterest}</p>
                <p>₹{totalAmount}</p>
              </div>
            </div>
          </div>
        </div>
        <EMIPieChart principal={principal} totalInterest={totalInterest} />
      </div>
    </div>
  );
};

export default EMICalculator;
