import React, { useState } from 'react';

const DueDateCalculator = ({ onSaveCalculation }) => {
  const [startDate, setStartDate] = useState('');
  const [durationValue, setDurationValue] = useState(0);
  const [durationUnit, setDurationUnit] = useState('days');
  const [dueDate, setDueDate] = useState('');

  const handleCalculate = () => {
    const startDateObj = new Date(startDate);
    let durationInMilliseconds;

    switch (durationUnit) {
      case 'days':
        durationInMilliseconds = durationValue * 24 * 60 * 60 * 1000;
        break;
      case 'weeks':
        durationInMilliseconds = durationValue * 7 * 24 * 60 * 60 * 1000;
        break;
      case 'months':
        durationInMilliseconds = durationValue * 30 * 24 * 60 * 60 * 1000;
        break;
      case 'years':
        durationInMilliseconds = durationValue * 365 * 24 * 60 * 60 * 1000;
        break;
      default:
        alert('Invalid duration unit');
        return;
    }

    const dueDateObj = new Date(startDateObj.getTime() + durationInMilliseconds);
    setDueDate(dueDateObj.toLocaleDateString());

    const calculation = {
      startDate: startDate,
      durationValue: durationValue,
      durationUnit: durationUnit,
      dueDate: dueDateObj.toLocaleDateString(),
    };

    onSaveCalculation(calculation);
  };

  return (
    <div className="container">
      <h2 className="text-center">DUE DATE CALCULATOR</h2>
      <form>
        <div className="form-group">
          <label>Start Date:</label>
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="form-control" />
        </div>
        <div className="form-group">
          <label>Duration:</label>
          <input type="number" value={durationValue} onChange={(e) => setDurationValue(e.target.valueAsNumber)} className="form-control" />
          <select value={durationUnit} onChange={(e) => setDurationUnit(e.target.value)} className="form-control">
            <option value="days">Days</option>
            <option value="weeks">Weeks</option>
            <option value="months">Months</option>
            <option value="years">Years</option>
          </select>
        </div>
        <button type="button" onClick={handleCalculate} className="btn btn-primary" style={{ width: '100%' }}>CALCULATOR DUE DATE</button>
      </form>
      <p className="text-center">DUE DATE: {dueDate}</p>
    </div>
  );
};

export default DueDateCalculator;