import React, { useState, useEffect } from "react";
import axios from "axios";

const Testimonials = () => {
  const [servicesTestimonials, setServicesTestimonials] = useState([]);
  const [learningTestimonials, setLearningTestimonials] = useState([]);
  const [visibleServiceCount, setVisibleServiceCount] = useState(3);
  const [visibleLearningCount, setVisibleLearningCount] = useState(3);
  const [showAllServices, setShowAllServices] = useState(false);
  const [showAllLearning, setShowAllLearning] = useState(false);

  const fetchServicesTestimonials = async () => {
    try {
      const servicesUrl = `${process.env.REACT_APP_API_URL}testimonials/fetchtestimonials_bycategory_limit/services`;
      const serviceResponse = await axios.get(servicesUrl);
      const updatedServiceData = serviceResponse.data.map((testimonial) => ({
        ...testimonial,
        image_url: `${process.env.REACT_APP_API_URL}${testimonial.image.replace(/\\/g, "/")}`,
      }));
      setServicesTestimonials(updatedServiceData);
    } catch (err) {
      console.error("Error in fetching services testimonials: ", err);
    }
  };

  const fetchLearningTestimonials = async () => {
    try {
      const learningUrl = `${process.env.REACT_APP_API_URL}testimonials/fetchtestimonials_bycategory_limit/learning`;
      const learningResponse = await axios.get(learningUrl);
      const updatedLearningData = learningResponse.data.map((testimonial) => ({
        ...testimonial,
        image_url: `${process.env.REACT_APP_API_URL}${testimonial.image.replace(/\\/g, "/")}`,
      }));
      setLearningTestimonials(updatedLearningData);
    } catch (err) {
      console.error("Error in fetching learning testimonials: ", err);
    }
  };

  useEffect(() => {
    fetchServicesTestimonials();
    fetchLearningTestimonials();
  }, []);

  const toggleShowMoreServices = () => {
    if (showAllServices) {
      setVisibleServiceCount(3); 
    } else {
      setVisibleServiceCount(servicesTestimonials.length); 
    }
    setShowAllServices(!showAllServices);
  };

  const toggleShowMoreLearning = () => {
    if (showAllLearning) {
      setVisibleLearningCount(4); 
    } else {
      setVisibleLearningCount(learningTestimonials.length);
    }
    setShowAllLearning(!showAllLearning);
  };

  return (
    <>
      <div className="rs-breadcrumbs img1">
        <div className="breadcrumbs-inner text-center">
          <h1 className="page-title">Testimonials</h1>
          {/* <ul>
            <li title="Home">
              <a className="active" href="index.html">Home</a>
            </li>
            <li>About</li>
          </ul> */}
        </div>
      </div>

      <div className="rs-testimonial style8 bg22 pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="sec-title6 mb-50 text-center">
            <span className="sub-text new-text">IT-Service Testimonials</span>
            <h2 className="title">What Customer Saying</h2>
          </div>

          <div className="testimonial-grid">
            {servicesTestimonials.slice(0, visibleServiceCount).length === 0 ? (
              <div className="text-center">
                <p>No testimonials found</p>
              </div>
            ) : (
              servicesTestimonials.slice(0, visibleServiceCount).map((testimonial, index) => (
                <div className="testi-item" key={index} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <div className="item-content-basic">
              <span>
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALgAAACUCAMAAAAXgxO4AAAA21BMVEX////7qhH6qBP7rxH5ohT4nhT3lRb3mRb7rBH8shD2khf8tQ////37rgD//vr9uw7//PP+vQD1iQDzggD/+u31jA7/9N36owD2jQD95Mv97Nr+9/D/9Nj+3H/+7sH/78n+1XP+3Zb+1X78xF/7tT37vVn80Zb4p0P4rmX6yp74tHT+8eX+45v/6rb+0WD/5KP+xy/+zkr+wx3/34/9ylL+xz39xEb9vy7+3qT91Yn9w1D9zGj9vTn7tCz8zHP94K77w3T94L76s073nyv7yIn3nDn71Kr5vob3pFKXxu70AAAEf0lEQVR4nO2WWXOiTBSGM44TE0A2AUGIiEuCGLNNEnGXJKL//xdNo4zScIBczM331XkuUinrqfO+dDfLxQWCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIP8/eL5U4cTvOFx5lFg+5ht1jig396XjrM64NLD9YJU5/OON8i/qxMOkp3bJWok30rNWMkd7kEpb2W/S4z+oEwc+S1KnJLH9Ikm/i3eQv5eEp3FxotKRpLeSbdFey+scAx1JKEsUHwRBeipOtF4FQZgXJ45fyJziBeAdMual9FwStDdiSg+FiWMhYlqUeAgUhMLE6PoF4c0ucqxXso7C8zdOeYeNEIqOnvJ6cAoTrcnBeSpKHAuHqG6Bw/8+OvclrTnxkT0ijPMeZrzWiZ13O2/Nee3tqFzPlRyHE8eTeI6j5ERxoiOU1SEbp9lrZ85ex7Bdv20pqcVQImd2ciZT37ZSqXzkTBenMTNnbWsZx2r73cnfOWzPJ04qSlTstn+uM+lEdYBF0JzVbMHW69cn6nX2vbd6PB92zp7O0w47ee9Nx+dMft2dzybXCYeMmczm3cSxUvxub8GydNRitnKs8+VZzqqXirpmF1SdGKder/9MQ36rnxPFeY7zfn6kK5Mc5/Vcyl7mOM55Qf28Ou10caV3BZK8c+w+qCz9xA76S9BZJFZcnP6ElHov8UrT5uCY+ip7WKze1Y8sK+r9aC8B5SrZ+0L0Iae/Th5ycZWNqjR71HvB6kFRK+gpbQ+alTSr1Ht9vcw4V1P6rlJurzJjlmva0VY/0kqzb1E3MPedOn/VfrOa1KrNQfrlyPup5tXKNL0I4rRCj6lW/PSTWttUKYf0zryqSB06KrUnlFql6GcvkPdppbLJbp65oZ3mbfZgaoNU1Lq0TnOQ+1nHb6q/ktwCjjaglH4ALgDlfECBazoKuP4LcUMpTahOzC1lfkKlUtPAUq0PyhlA787gk14jwOFCSvkF1YkZftYSgMVNL6nU7qDi2gflbKBvkYCKqg2B4nxIKWCd+BK/LpPUQsBRXMrZGoBj3FHOxwgqTimXX8DFmV81ygFugxjR05kEl1+A09onFQZchmBLOXctwBlSUYwHXJy5oRQ9/4wrLpmmqsx2y+hqNA1wjDuVKPoncYipMiHw0RYy6nHMlvxlVGhX+J0cR13q0RwXKu6SDqrOHKMYfZf7/T/ay2qDcUPDGLqyrOouYBpbWZYZLzCC3VaXVQY6nENGleXt7uDIJBPYFXMnq7K+HxpG6DLk3z2wK629rsqqF9XZR46X+93e2pLagUnWkBsFe7mxzz6juFBtbHdG1JZvDfeyvDOzc3ak9rAVOWKLVJeBe8V0G6objiLHNDymAe2KsW2oXhDN50bhnmnsoXvlwMg7VjqODlxgbzjDHbZOp8MM3RBY8dAdnjNaOxcoZQ7d4LSAvLHzgBUfeV5wmi4GHrRGcSuTamFCIm9Sh9qEzh3tcKBDD09N/X4dBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEH+q/wBUSWPVtgdukYAAAAASUVORK5CYII="
                  alt="star"
                  // className=""
                />
              </span>
              <p>{testimonial.testimonial}</p>
            </div>
            <div className="testi-information" style={{ marginTop: 'auto' }}>
              <div className="img-part">
                {testimonial.image_url && (
                  <img
                    src={testimonial.image_url}
                    alt={testimonial.name}
                    className="img-fluid rounded-circle"
                    style={{
                      width: "80px",
                      height: "80px",
                      objectFit: "cover",
                      border: "4px solid #eee",
                    }}
                  />
                )}
              </div>
              <div className="testi-content">
                <div className="testi-name">{testimonial.name}</div>
                <span className="testi-title">{testimonial.name_bio}</span>
              </div>
            </div>
          </div>
              ))
            )}
          </div>
          {servicesTestimonials.length > 3 && (
            <div className="text-center mt-4">
              <button onClick={toggleShowMoreServices} className="btn btn-primary">
                {showAllServices ? "Show Less" : "Load More"}
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="rs-testimonial style6 gray-color pt-100 pb-100 md-pt-80 md-pb-80 md-pl-15 md-pr-15">
      <div className="container">
        <div className="sec-title5 mb-60 text-center">
          <h2 className="title">Learning <span> Testimonials</span></h2>
          <p className="desc">
            We've been building creative tools together for over a decade and have a deep appreciation for software applications and AI tools.
          </p>
        </div>
        
        <div className="testimonial-grid">
          {learningTestimonials.slice(0, visibleLearningCount).length === 0 ? (
            <div className="text-center">
              <p>No testimonials found</p>
            </div>
          ) : (
            learningTestimonials.slice(0, visibleLearningCount).map((testimonial, index) => (
              <div className="testi-item" key={index}>
                <div className="testi-content">
                  <div className="images-wrap">
                    {testimonial.image_url && (
                      <img
                        src={testimonial.image_url}
                        alt={testimonial.name}
                        className="img-fluid rounded-circle"
                        style={{
                          width: "90px",
                          height: "100px",
                          objectFit: "cover",
                          border: "4px solid #eee",
                        }}
                      />
                    )}
                  </div>
                  <div className="testi-information">
                    <div className="testi-name">{testimonial.name}</div>
                    <span className="testi-title">{testimonial.name_bio}</span>
                    <div className="rating">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALgAAACUCAMAAAAXgxO4AAAA21BMVEX////7qhH6qBP7rxH5ohT4nhT3lRb3mRb7rBH8shD2khf8tQ////37rgD//vr9uw7//PP+vQD1iQDzggD/+u31jA7/9N36owD2jQD95Mv97Nr+9/D/9Nj+3H/+7sH/78n+1XP+3Zb+1X78xF/7tT37vVn80Zb4p0P4rmX6yp74tHT+8eX+45v/6rb+0WD/5KP+xy/+zkr+wx3/34/9ylL+xz39xEb9vy7+3qT91Yn9w1D9zGj9vTn7tCz8zHP94K77w3T94L76s073nyv7yIn3nDn71Kr5vob3pFKXxu70AAAEf0lEQVR4nO2WWXOiTBSGM44TE0A2AUGIiEuCGLNNEnGXJKL//xdNo4zScIBczM331XkuUinrqfO+dDfLxQWCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIP8/eL5U4cTvOFx5lFg+5ht1jig396XjrM64NLD9YJU5/OON8i/qxMOkp3bJWok30rNWMkd7kEpb2W/S4z+oEwc+S1KnJLH9Ikm/i3eQv5eEp3FxotKRpLeSbdFey+scAx1JKEsUHwRBeipOtF4FQZgXJ45fyJziBeAdMual9FwStDdiSg+FiWMhYlqUeAgUhMLE6PoF4c0ucqxXso7C8zdOeYeNEIqOnvJ6cAoTrcnBeSpKHAuHqG6Bw/8+OvclrTnxkT0ijPMeZrzWiZ13O2/Nee3tqFzPlRyHE8eTeI6j5ERxoiOU1SEbp9lrZ85ex7Bdv20pqcVQImd2ciZT37ZSqXzkTBenMTNnbWsZx2r73cnfOWzPJ04qSlTstn+uM+lEdYBF0JzVbMHW69cn6nX2vbd6PB92zp7O0w47ee9Nx+dMft2dzybXCYeMmczm3cSxUvxub8GydNRitnKs8+VZzqqXirpmF1SdGKder/9MQ36rnxPFeY7zfn6kK5Mc5/Vcyl7mOM55Qf28Ou10caV3BZK8c+w+qCz9xA76S9BZJFZcnP6ElHov8UrT5uCY+ip7WKze1Y8sK+r9aC8B5SrZ+0L0Iae/Th5ycZWNqjR71HvB6kFRK+gpbQ+alTSr1Ht9vcw4V1P6rlJurzJjlmva0VY/0kqzb1E3MPedOn/VfrOa1KrNQfrlyPup5tXKNL0I4rRCj6lW/PSTWttUKYf0zryqSB06KrUnlFql6GcvkPdppbLJbp65oZ3mbfZgaoNU1Lq0TnOQ+1nHb6q/ktwCjjaglH4ALgDlfECBazoKuP4LcUMpTahOzC1lfkKlUtPAUq0PyhlA787gk14jwOFCSvkF1YkZftYSgMVNL6nU7qDi2gflbKBvkYCKqg2B4nxIKWCd+BK/LpPUQsBRXMrZGoBj3FHOxwgqTimXX8DFmV81ygFugxjR05kEl1+A09onFQZchmBLOXctwBlSUYwHXJy5oRQ9/4wrLpmmqsx2y+hqNA1wjDuVKPoncYipMiHw0RYy6nHMlvxlVGhX+J0cR13q0RwXKu6SDqrOHKMYfZf7/T/ay2qDcUPDGLqyrOouYBpbWZYZLzCC3VaXVQY6nENGleXt7uDIJBPYFXMnq7K+HxpG6DLk3z2wK629rsqqF9XZR46X+93e2pLagUnWkBsFe7mxzz6juFBtbHdG1JZvDfeyvDOzc3ak9rAVOWKLVJeBe8V0G6objiLHNDymAe2KsW2oXhDN50bhnmnsoXvlwMg7VjqODlxgbzjDHbZOp8MM3RBY8dAdnjNaOxcoZQ7d4LSAvLHzgBUfeV5wmi4GHrRGcSuTamFCIm9Sh9qEzh3tcKBDD09N/X4dBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEH+q/wBUSWPVtgdukYAAAAASUVORK5CYII="
                  alt="star"
                      style={{
                        width: "80px",
                        height: "50px",
                        marginTop: "-10px",
                        // objectFit: 'contain'
                        }}/>
                    </div>
                  </div>
                </div>
                <div className="item-content-basic" style={{ display: 'flex', flexDirection: 'column', height: 'auto' }}>
  <p>{testimonial.testimonial}</p>
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', height: '100%' }}>
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFwAAABcCAMAAADUMSJqAAAAdVBMVEX///8Rcrn///0seLkAb7gAarYAbbeOss/A2OgAYrJck7+mxtx1o8wPcrvn7/MQc7js9vlGh74AZrTM4Oz0+vwAabA4gLiBqcqWttDF2eYNa6t9p8620eSNtNWnw9VUj72gwt2tx+FrnMlzo8fc7fY2fbpFiLksQw4HAAAC2UlEQVRoge2Y65KjIBCFEYFcDBLxkuvMZI3J+z/iglEgCgRr/uxWcaqm5tb9eYBuwAAQFRUVFRX17whC6P+3+c0WADz5Ig169Hq4L+IT/ePAQgZnHxY4stQh9j1wT2dXSHrxP3fFqUM8G1yvN5QiSwBKyM47LWCFE4ewhjNqD4nwCI/wCI/wCP9f4eIMhb+BQy/8qp27Bndw++6dc6RFEDLgawXHyJQB//LDv3+2ph7jBFC6KYcbT/keUig2xTcfe3ZZPBCdmQ9XxUlSxpUBOXPBdGjC01zHmEF/9Crh0n/Zm6hVM0qKyh5yU3DK8nCycJEqNmodUXc1ONRU/pvsO73ieshHR1CjBoe24cYF/KqHzEt7yL5WHSWKZYFz8NSLle6tbJBhBa/LBXDY6SpLtrZCEH8qsPyv/CKPalGtGFV+tcNPuEeLJkvwJbQQ5dtNt9HNJ2Zllilj9HImjlWxwUVmg/RaWV9IIDiOi0KZsxGsiRfMyLj/4c66VCdOxwgq9/tgrUSNjxs3vs92HTknZa3YfQcFmZZvnCvVPzShdTeNkA9bqxJn/XYftpwQ7FvxVqdL5TZ/PIBHrtmIWEvVqmuK9Zwk+GeaKH4tnxumTyTKug/v9sPLe7V+Sk/KOJmUofy53HKUUN1isg0+skVXfjVc1y4V/uvJLg3zrNgQHSKewV272rvyGhuHovTdnwAGXTQO1w0gl5uKgzlgU4Egn5zs6NyzTTgskDlrCfUf+ibcTGMMpx2YHZsFMWwz5D2WTVPCuXHhoXxbzU9k4dyYFH4+hRXhBI5ZZv0ERcMJqVvLjuaEj7NCCd7tgfWznwFOKcLNqc8Ldi57WdQI2XXA6huKOZfDE09vroFg5ZxQwuvHKnfmVaJaCMFJG+56hNe4Tg8lsLt+/bnhddJe92ARuy/Fe9a9WtWZVh1uZTWaWXJqBrhZjFuSC+Gi28lS/G/0C19RUVFRUW79BVfIK8sOWaUtAAAAAElFTkSuQmCC"
      alt="star"
      style={{ 
        width: '40px', 
        height: '40px', 
        objectFit: 'contain',
        marginBottom:'-80px',
        // marginTop:'-40px'
      }}
    />
  </div>
</div>
</div>
            ))
          )}
        </div>
        {learningTestimonials.length > 4 && (
          <div className="text-center mt-4">
            <button onClick={toggleShowMoreLearning} className="btn btn-primary">
              {showAllLearning ? "Show Less" : "Load More"}
            </button>
          </div>
        )}
      </div>
      </div>
    </>
  );
};

export default Testimonials;
