import React from 'react'
import { Link } from 'react-router-dom';

const Store = () => {
    return (
        <>

            <div class="rs-breadcrumbs img3">
                <div class="breadcrumbs-inner text-center">
                    <h1 class="page-title">Shop</h1>

                </div>
            </div>
            
            <div class="rs-shop-part pt-120 pb-120 md-pt-70 md-pb-80">
                <div class="container">
                    <div class="row align-items-center rs-vertical-middle shorting mb-50">
                        <div class="col-sm-6 col-12">
                            <p class="woocommerce-result-count">Our Courses</p>
                        </div>
                        {/* <div class="col-sm-6 col-12 sm-mt-20">
                            <select class="from-control">
                                <option>Default sorting</option>
                                <option>Sort by popularity</option>
                                <option>Sort by average rating</option>
                                <option>Sort by lates</option>
                                <option>Sort by price: low to high</option>
                                <option>Sort by price: high to low</option>
                            </select>
                        </div> */}
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 mb-80">
                            <div class="product-list">
                                <div class="image-product">
                                    <img src="assets/images/shop/mern.jpg" alt="" />
                                    <div class="overley">
                                        <Link to="#"><i class="flaticon-shopping-bag"></i></Link>
                                    </div>
                                </div>
                                <div class="content-desc text-center">
                                    <h2 class="loop-product-title pt-15"><Link to="#">Training - MERN Full Stack</Link></h2>
                                    <span class="price">INR 5000</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-80">
                            <div class="product-list">
                                <div class="image-product">
                                    <img src="assets/images/shop/php.jpg" alt="" />
                                    <div class="overley">
                                    <Link to="#"><i class="flaticon-shopping-bag"></i></Link>
                                    </div>
                                </div>
                                <div class="content-desc text-center">
                                    <h2 class="loop-product-title pt-15"><Link to="#">Training - PHP LAMP Stack</Link></h2>
                                    <span class="price">INR 5000</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-80">
                            <div class="product-list">
                                <div class="image-product">
                                    <img src="assets/images/shop/java.jpg" alt="" />
                                    <div class="overley">
                                    <Link to="#"><i class="flaticon-shopping-bag"></i></Link>
                                    </div>
                                    <span class="onsale">sale!</span>
                                </div>
                                <div class="content-desc text-center">
                                    <h2 class="loop-product-title pt-15"><Link to="#">Training - Java Full Stack</Link></h2>
                                    <span class="price">INR 5000</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-80">
                            <div class="product-list">
                                <div class="image-product">
                                    <img src="assets/images/shop/mobile.jpg" alt="" />
                                    <div class="overley">
                                    <Link to="#"><i class="flaticon-shopping-bag"></i></Link>
                                    </div>
                                </div>
                                <div class="content-desc text-center">
                                    <h2 class="loop-product-title pt-15"><Link to="#">Training - Mobile App Development</Link></h2>
                                    <span class="price">INR 5000</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-80">
                            <div class="product-list">
                                <div class="image-product">
                                    <img src="assets/images/shop/python.jpg" alt="" />
                                    <div class="overley">
                                    <Link to="#"><i class="flaticon-shopping-bag"></i></Link>
                                    </div>
                                    <span class="onsale">sale!</span>
                                </div>
                                <div class="content-desc text-center">
                                    <h2 class="loop-product-title pt-15"><Link to="#">Training - Python Development</Link></h2>
                                    <span class="price">INR 5000</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-80">
                            <div class="product-list">
                                <div class="image-product">
                                    <img src="assets/images/shop/web.jpg" alt="" />
                                    <div class="overley">
                                    <Link to="#"><i class="flaticon-shopping-bag"></i></Link>
                                    </div>
                                    <span class="onsale">sale!</span>
                                </div>
                                <div class="content-desc text-center">
                                    <h2 class="loop-product-title pt-15"><Link to="#">Training - Web Designing</Link></h2>
                                    <span class="price">INR 5000</span>
                                </div>
                            </div>
                        </div>
                        
                        {/* <div class="col-lg-12 text-center pt-40">
                            <ul class="pagination-part">
                                <li class="active"><a href="#">1</a></li>
                                <li><a href="#">2</a></li>
                                <li><a class="next-page" href="#">Next</a></li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default Store
