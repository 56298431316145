import React, { useEffect, useState } from "react";
import loadImage from "../../assets/img/giphy.gif";

const Verification = () => {
  const [certi, setCerti] = useState("");
  const [certidata, setCertiData] = useState({});

  const [apistatus, setApistatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);

  const verify = async () => {
    //console.log('verification starts........');
    setLoader(true);
    setApistatus(false);

    try {
      const url = process.env.REACT_APP_LMS_API_URL + "course/verify/" + certi;
      const response = await fetch(url);
      const data = await response.json();
      //console.log(data);
      let userid = await data.userid;

      if (userid) {
        const userdetailsurl =
          process.env.REACT_APP_LMS_API_URL + "course/userDetails/" + userid;

        const finalres = await fetch(userdetailsurl);
        const finaldata = await finalres.json();

        setCertiData(finaldata);
        //console.log(finalres)
        setLoader(false);
        setApistatus(true);
      } else {
        setCertiData({});
        setLoader(false);
        setApistatus(true);
      }
    } catch (err) {
      setLoader(false);
      console.log("Error In API Call - ", err);
    }
  };

  return (
    <div className="bg-opacity-10  text-center">
      <div class="rs-breadcrumbs img1">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Certificate Verification</h1>
        </div>
      </div>
      <div className="py-5">
        <div class="container mt-5">
          <h2 class="text-center">Verify Certificate Here</h2>
          <div class="row justify-content-center">
            <div class="col-md-6">
              <div class="form-group text-center">
                <input
                  type="text"
                  class="form-control center-placeholder small-input text-center"
                  id="certificateNumber"
                  placeholder="Enter certificate number"
                  onChange={(e) => setCerti(e.target.value)}
                  required
                />
              </div>
              <div class="text-center">
                <button type="button" class="btn btn-primary" onClick={verify}>
                  Verify
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && (
        <div className="loading">
          <img src={loadImage} alt="Loader" />
        </div>
      )}
      {certidata.user_id && (
        <>
          <div
            class="alert alert-success mt-3 hidden"
            style={{
              maxWidth: "600px",
              margin: "auto",
            }}
            role="alert"
          >
            <div
              className="border rounded p-3 rounded"
              style={{
                maxWidth: "600px",
                margin: "auto",
                backgroundImage:
                  "linear-gradient(90deg, #03228f 10%, #0e73e4 100%)",
              }}
            >
              <div className="text-center mb-3">
                <h2 class="title title4 white-color pb-20">
                  Certificate Verified
                </h2>
              </div>
              <div>
                <p class="white-color">
                  <strong>Name:</strong> {certidata.name}
                </p>
                <p class="white-color">
                  <strong>Address:</strong> {certidata.city}
                </p>
                <p class="white-color">
                  <strong>College:</strong> {certidata.college}
                </p>
                <p class="white-color">
                  <strong>Email:</strong> {certidata.email}
                </p>
                <p class="white-color">
                  <strong>Emp. ID:</strong> {certidata.employeeid}
                </p>
                <p class="white-color">
                  <strong>Certificate No:</strong> {certidata.certificateid}
                </p>
                <p class="white-color">
                  <strong>Start Date:</strong> {certidata.startdate}
                </p>
                <p class="white-color">
                  <strong>End Date:</strong> {certidata.enddate}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      {apistatus && !certidata.user_id && (
        <>
          <div
            className="alert alert-danger mt-3 hidden"
            style={{
              maxWidth: "600px",
              margin: "auto",
              marginBottom: "20px",
            }}
            role="alert"
          >
            <h5
              class="dark-color "
              style={{
                maxWidth: "600px",
                margin: "auto",
              }}
            >
              Data for this certificate number does not exist
            </h5>
          </div>
        </>
      )}
    </div>
  );
};

export default Verification;
