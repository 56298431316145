import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import sample_blog_image from '../../assets/img/blog/inner/1.jpg';

const RecentBlogs = () => {

    const [recentPosts, setRecentPosts] = useState([]);

    const navigate = useNavigate();

    const fetchRecentPosts = async () => {
        const url = process.env.REACT_APP_API_URL + 'blog/recentblogs';

        try {
            const response = await axios.get(url);
            setRecentPosts(response.data);
        } catch (err) {
            console.error('Error in API Call:', err);
        }
    };

    const showBlogPage = (srnum, title) => {
        window.localStorage.setItem('blogid', srnum);
        //redirect to blog page with title in url
        navigate(`/blog/${title}`)
    }


    useEffect(() => {
        fetchRecentPosts();
    }, []);


    return (
        <>

            <div className="recent-posts mb-50">
                <div className="widget-title">
                    <h3 className="title">Recent Blogs</h3>
                </div>

                {recentPosts.map((post, index) => (
                    <div className="recent-post-widget" key={index} onClick={() => showBlogPage(post.srno, post.posttitle)}>
                        <div className="post-img">
                            <a>
                                <img src={post.featuredimage || sample_blog_image} alt={post.title} />
                            </a>
                        </div>
                        <div className="post-desc">
                            <a>
                                {post.postheading}
                            </a>
                            <span className="date">
                                <i className="fa fa-calendar"></i> {new Date(post.createdon).toLocaleDateString()}
                            </span>
                        </div>
                    </div>
                ))}

            </div>

        </>
    )
}

export default RecentBlogs
