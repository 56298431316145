import React from 'react';
import { Link } from 'react-router-dom';


const About = () => {
  return (
    <>
      <div class="rs-breadcrumbs img1">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">About</h1>
          {/* <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">Home</a>
            </li>
            <li>About</li>
          </ul> */}
        </div>
      </div>


      {/* <div class="rs-about gray-color pt-120 pb-120 md-pt-80 md-pb-80">
        <div class="container">
          <div class="row align-items-center"> */}
      {/* <div class="col-lg-6 md-mb-30">
              <div class="rs-animation-shape">
                <div class="images">
                  <img src="assets/images/about/about-3.png" alt="" />
                </div>
                <div class="middle-image2">
                  <img class="dance3" src="assets/images/about/effect-1.png" alt="" />
                </div>
              </div>
            </div> */}
      {/* <div class="col-lg-6 pl-60 md-pl-15">
              <div class="contact-wrap">
                <div class="sec-title mb-30">
                  <div class="sub-text style-bg">About Us</div>
                  <h2 class="title pb-38">
                    We Are Increasing Business Success With Technology
                  </h2>
                  <div class="desc pb-35">
                    Over 25 years working in IT services developing software applications and mobile apps for clients all over the world.
                  </div>
                  <p class="margin-0 pb-15">
                  We are a bunch of highly technical & creative personnel with innovative ideas and having a crazy attitude towards our work; provides inexpensive yet effective array of total web and software solutions. Along with that we have a complete dedicated team for Training and Workshop purposes. We have expert team of professionals for different technologies who keep them updated with recent innovations in the IT industry.                  </p>
                </div>
                <div class="btn-part">
                  <a class="readon learn-more" href="contact.html">Learn-More</a>
                </div>
              </div>
            </div> */}
      {/* </div> */}
      {/* <div class="shape-image">
            <img class="top dance" src="assets/images/about/dotted-3.png" alt="" />
          </div> */}
      {/* </div>
      </div> */}




      <div id="rs-about" class="rs-about pt-120 md-pt-70">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5">
              <div class="sec-title mb-50">
                <div class="sub-text style4-bg">About Us</div>
                <h2 class="title pb-20">
                  Over 10+ Years Helping Brands to Reach Full Potential
                </h2>
                <div class="desc">
                  {/* Over 25 years working in IT services developing software applications and mobile apps for clients all over the world. From its medieval origins to the digital era, learn everything there is to know about the ubiquitous lorem ipsum passage. Creation timelines for the standard lorem ipsum passage vary, with some citing the 15th century */}
                  We are a bunch of highly technical & creative personnel with innovative ideas and having a crazy attitude towards our work; provides inexpensive yet effective array of total web and software solutions. Along with that we have a complete dedicated team for Training and Workshop purposes. We have expert team of professionals for different technologies who keep them updated with recent innovations in the IT industry.
                </div>
              </div>
            </div>



            <div class="col-lg-7">
              <div class="rs-services style3 md-pt-50">
                <div class="container">
                  <div class="row">
                    <div class="col-md-6 pr-10 pt-40 sm-pt-0 sm-pr-0 sm-pl-0">
                      <div class="services-item mb-20">
                        <div class="services-icon">
                          <div class="image-part">
                            <img class="main-img" src="assets/images/services/style3/main-img/1.png" alt="" />
                            <img class="hover-img" src="assets/images/services/style3/hover-img/1.png" alt="" />
                          </div>
                        </div>
                        <div class="services-content">
                          <div class="services-text">
                            <h3 class="title"><a href="web-development.html">High-quality Code</a></h3>
                          </div>
                          <div class="services-desc">
                            <p>
                              &nbsp;
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="services-item cyan-bg">
                        <div class="services-icon">
                          <div class="image-part">
                            <img class="main-img" src="assets/images/services/style3/main-img/2.png" alt="" />
                            <img class="hover-img" src="assets/images/services/style3/hover-img/2.png" alt="" />
                          </div>
                        </div>
                        <div class="services-content">
                          <div class="services-text">
                            <h3 class="title"><a href="web-development.html">Full-stack teams</a></h3>
                          </div>
                          <div class="services-desc">
                            <p>
                              &nbsp;
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 pl-10 sm-pr-0 sm-pl-0 sm-mt-20">
                      <div class="services-item gold-bg mb-20">
                        <div class="services-icon">
                          <div class="image-part">
                            <img class="main-img" src="assets/images/services/style3/main-img/3.png" alt="" />
                            <img class="hover-img" src="assets/images/services/style3/hover-img/3.png" alt="" />
                          </div>
                        </div>
                        <div class="services-content">
                          <div class="services-text">
                            <h3 class="title"><a href="web-development.html">Agile Approach</a></h3>
                          </div>
                          <div class="services-desc">
                            <p>
                              &nbsp;
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="services-item blue-bg">
                        <div class="services-icon">
                          <div class="image-part">
                            <img class="main-img" src="assets/images/services/style3/main-img/4.png" alt="" />
                            <img class="hover-img" src="assets/images/services/style3/hover-img/4.png" alt="" />
                          </div>
                        </div>
                        <div class="services-content">
                          <div class="services-text">
                            <h3 class="title"><a href="web-development.html">High-quality Code</a></h3>
                          </div>
                          <div class="services-desc">
                            <p>
                              &nbsp;
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>




          </div>
        </div>
      </div>

      {/* <!-- Choose Section Start --> */}
      {/* <div class="rs-why-choose pt-120 pb-120 md-pt-75 md-pb-80">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 md-pb-60">
                            <div class="sec-title mb-40">
                                <div class="sub-text style4-bg">Why Choose Us</div>
                                <h2 class="title pb-20">
                                    We Create Result-Oriented Dynamic Applications
                                </h2>
                                <div class="desc">
                                   Over 25 years working in IT services developing software applications and mobile apps for clients all over the world.
                                </div>
                            </div>
                            
                                <div class="services-wrap mb-25">
                                <div class="services-icon">
                                    <img src="assets/images/choose/icons/1.png" alt=""></img>
                                </div>
                                <div class="services-text">
                                    <h3 class="title"><a href="#">Development Team</a></h3>
                                    <p class="services-txt">Development team consisting of graduates from NITs and IITs.</p>
                                </div>
                            </div>
                            <div class="services-wrap mb-25">
                                <div class="services-icon">
                                <img src="assets/images/choose/icons/1.png" alt=""></img>
                                </div>
                                <div class="services-text">
                                    <h3 class="title"><a href="#">Software Solutions</a></h3>
                                    <p class="services-txt">Availability of all types of Web & Software Solutions.</p>
                                </div>
                            </div>
                            <div class="services-wrap mb-25">
                                <div class="services-icon">
                                <img src="assets/images/choose/icons/1.png" alt=""></img>
                                </div>
                                <div class="services-text">
                                    <h3 class="title"><a href="#">Minimum Time & Cost</a></h3>
                                    <p class="services-txt">Minimum development charge and time taken for the products as compared to others.</p>
                                </div>
                            </div>
                            <div class="services-wrap mb-25">
                                <div class="services-icon">
                                <img src="assets/images/choose/icons/1.png" alt=""></img>
                                </div>
                                <div class="services-text">
                                    <h3 class="title"><a href="#">Add On Services</a></h3>
                                    <p class="services-txt">Free Add-On like SEO, Responsive, Unlimited Pages and Design.</p>
                                </div>
                            </div>
                            <div class="services-wrap mb-25">
                                <div class="services-icon">
                                <img src="assets/images/choose/icons/1.png" alt=""></img>
                                </div>
                                <div class="services-text">
                                    <h3 class="title"><a href="#">Latest Technologies</a></h3>
                                    <p class="services-txt">Latest Technologies based services for Business Development available here.</p>
                                </div>
                            </div>
                            <div class="services-wrap mb-25">
                                <div class="services-icon">
                                <img src="assets/images/choose/icons/1.png" alt=""></img>
                                </div>
                                <div class="services-text">
                                    <h3 class="title"><a href="#">Project Maintenance</a></h3>
                                    <p class="services-txt">Proper responsibility of Project Maintenance by Company.</p>
                                </div>
                            </div>
                            <div class="services-wrap mb-25">
                                <div class="services-icon">
                                <img src="assets/images/choose/icons/1.png" alt=""></img>
                                </div>
                                <div class="services-text">
                                    <h3 class="title"><a href="#">Business Deveopment</a></h3>
                                    <p class="services-txt">Business Development Consultation & Online Social Marketing.</p>
                                </div>
                            </div>
                            <div class="services-wrap mb-25">
                                <div class="services-icon">
                                <img src="assets/images/choose/icons/1.png" alt=""></img>
                                </div>
                                <div class="services-text">
                                    <h3 class="title"><a href="#">Business Analytics</a></h3>
                                    <p class="services-txt">Saving clients time and money by using company analytics & latest technologies.</p>
                                </div>
                            </div>
                        </div> 
                           <div class="col-lg-6">
                           <div class="image-part">
                               <img src="assets/images/choose/1.png" alt=""></img>
                           </div>
                           <div class="animation">
                               <div class="top-shape">
                                   <img class="dance" src="assets/images/choose/dotted-2.png" alt="images"></img>
                               </div>
                               <div class="bottom-shape">
                               </div>
                           </div> 
                           
                        </div>  
                    </div>
                </div>
            </div>  */}

      {/* <!-- Choose Section End --> */}
      {/* <!-- Services Section Start --> */}
      <div class="rs-services style3 modify2 pt-100 pb-100 md-pt-75 md-pb-80">
        <div class="container">
          <div class="sec-title2 text-center mb-45">
            <span class="sub-text gold-color">Why Choose Us</span>
            <h2 class="title title7">
              We Create Result-Oriented Dynamic Applications
            </h2>
            <div class="desc">
              Over 10 years working in IT services developing software applications and mobile apps for clients all over the world.
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-20">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/1.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/1.png" alt="" />
                  </div>
                </div>
                {/* <div class="services-icon">
                                    <img src="assets/images/choose/icons/1.png" alt=""></img>
                               </div> */}
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><Link>Development Team</Link></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Development team consisting of graduates from NITs and IITs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-20">
              <div class="services-item pink-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/2.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/2.png" alt="" />
                  </div>
                </div>
                {/* <div class="services-icon">
                                <img src="assets/images/choose/icons/1.png" alt=""></img>
                               </div> */}
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><Link>Software Solutions</Link></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Availability of all types of Web & Software Solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-20">
              <div class="services-item aqua-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/3.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/3.png" alt="" />
                  </div>
                  {/* <div class="services-icon">
                                <img src="assets/images/choose/icons/1.png" alt=""></img>
                               </div> */}
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><Link>Minimum Time & Cost</Link></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Minimum development charge and time taken for the products as compared to others.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-20">
              <div class="services-item paste-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/4.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/4.png" alt="" />
                  </div>
                </div>
                {/* <div class="services-icon">
                                <img src="assets/images/choose/icons/1.png" alt=""></img>
                               </div> */}
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><Link>Add On Services</Link></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Free Add-On like SEO, Responsive, Unlimited Pages and Design.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 sm-mb-20">
              <div class="services-item purple-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/5.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/5.png" alt="" />
                  </div>
                </div>
                {/* <div class="services-icon">
                                <img src="assets/images/choose/icons/1.png" alt=""></img>
                               </div> */}
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><Link>Latest Technologies</Link></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Latest Technologies based services for Business Development available here.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 sm-mb-20">
              <div class="services-item green-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img class="main-img" src="assets/images/services/style2/main-img/6.png" alt="" />
                    <img class="hover-img" src="assets/images/services/style2/hover-img/6.png" alt="" />
                  </div>
                </div>
                {/* <div class="services-icon">
                                <img src="assets/images/choose/icons/1.png" alt=""></img>
                               </div> */}
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><Link>Project Maintenance</Link></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Proper responsibility of Project Maintenance by Company.

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 sm-mb-20">
              <div class="services-item pink-bg">
                {/* <div class="services-icon">
                                   <div class="image-part">
                                       <img class="main-img" src="assets/images/services/style2/main-img/5.png" alt=""/>
                                       <img class="hover-img" src="assets/images/services/style2/hover-img/5.png" alt=""/>
                                   </div>
                               </div> */}
                <div class="services-icon">
                  <img src="assets/images/choose/icons/1.png" alt=""></img>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><Link>Business Deveopment</Link></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Business Development Consultation & Online Social Marketing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 sm-mb-20">
              <div class="services-item red-bg">
                {/* <div class="services-icon">
                                   <div class="image-part">
                                       <img class="main-img" src="assets/images/services/style2/main-img/5.png" alt=""/>
                                       <img class="hover-img" src="assets/images/services/style2/hover-img/5.png" alt=""/>
                                   </div>
                               </div> */}
                <div class="services-icon">
                  <img src="assets/images/choose/icons/1.png" alt=""></img>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title"><Link>Business Analytics</Link></h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Saving clients time and money by using company analytics & latest technologies.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Services Section End --> */}


      {/* <!-- Process Section Start --> */}
      <div class="rs-process style2 pt-120 pb-120 md-pt-80 md-pb-73">
        <div class="container">
          <div class="sec-title2 text-center mb-45">
            <span class="sub-text style-bg">Process</span>
            <h2 class="title title2">
              Our Working Process
            </h2>
          </div>
          <div class="row">
            <div class="col-lg-3 col-sm-6 md-mb-50">
              <div class="addon-process">
                <div class="process-wrap">
                  <div class="process-img">
                    <img src="assets/images/process/1.png" alt=""></img>
                  </div>
                  <div class="process-text">
                    <h3 class="title">Discovery</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 md-mb-50">
              <div class="addon-process">
                <div class="process-wrap">
                  <div class="process-img">
                    <img src="assets/images/process/2.png" alt=""></img>
                  </div>
                  <div class="process-text">
                    <h3 class="title"> Planning</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="addon-process">
                <div class="process-wrap">
                  <div class="process-img">
                    <img src="assets/images/process/3.png" alt=""></img>
                  </div>
                  <div class="process-text">
                    <h3 class="title">Execute</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="addon-process">
                <div class="process-wrap">
                  <div class="process-img">
                    <img src="assets/images/process/4.png" alt=""></img>
                  </div>
                  <div class="process-text">
                    <h3 class="title">Deliver</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Process Section End --> */}
    </>
  )
}

export default About
