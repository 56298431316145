import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const DueDateChart = ({ savedCalculations }) => {
  const data = {
    labels: ['Less than 1 day', '1-3 days', '4-7 days', '1-2 weeks', 'More than 2 weeks'],
    datasets: [{
      label: 'Due Date Chart',
      data: getChartData(savedCalculations),
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)'
      ],
      borderWidth: 1
    }]
  };

  return (
    <div className="container">
      <h4 className="text-center">Due Date Chart</h4>
      <Doughnut data={data} />
      <ul>
        {savedCalculations.map((calculation, index) => (
          <li key={index}>
            Start Date: {calculation.startDate}, Duration: {calculation.durationValue} {calculation.durationUnit}, Due Date: {calculation.dueDate}
          </li>
        ))}
      </ul>
    </div>
  );
};

const getChartData = (calculations) => {
  if (!calculations) return [0, 0, 0, 0, 0]; // return default values if calculations is empty

  const chartData = [0, 0, 0, 0, 0];

  calculations.forEach((calculation) => {
    const dueDate = new Date(calculation.dueDate);
    const today = new Date();

    const diffInDays = Math.abs((dueDate - today) / (1000 * 60 * 60 * 24));

    if (diffInDays < 1) {
      chartData[0]++;
    } else if (diffInDays >= 1 && diffInDays <= 3) {
      chartData[1]++;
    } else if (diffInDays >= 4 && diffInDays <= 7) {
      chartData[2]++;
    } else if (diffInDays >= 8 && diffInDays <= 14) {
      chartData[3]++;
    } else {
      chartData[4]++;
    }
  });

  return chartData;
};

export default DueDateChart;