import React from 'react';
import { Link } from 'react-router-dom';


const Resources = () => {
  return (
    <>
      <div className="rs-breadcrumbs img2">
        <div className="breadcrumbs-inner text-center">
          <h1 className="page-title">Resources</h1>
        </div>
      </div>


      <div className="rs-services style3 pt-110 pb-120 md-pt-75 md-pb-80">
        <div className="container">
          <div className="sec-title2 text-center mb-45">
            <span className="sub-text">Resources</span>
            <h2 className="title testi-title">
              Our Featured Resources
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="services-item">
                <div className="services-icon">
                  <div className="image-part">
                    <img className="main-img" src="assets/images/services/style2/main-img/1.png" alt="" />
                    <img className="hover-img" src="assets/images/services/style2/hover-img/1.png" alt="" />
                  </div>
                </div>
                <Link to="/fd-calculator">
                <div className="services-content">
                  <div className="services-text">
                    <h3 className="title">FD Calculator</h3>
                  </div>
                  {/* <div className="services-desc">
                    <p>
                      The only way to be truly satisfied is to do what you believe is great work.
                    </p>
                  </div> */}
                  <div className="serial-number">
                    01
                  </div>
                </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="services-item pink-bg">
                <div className="services-icon">
                  <div className="image-part">
                    <img className="main-img" src="assets/images/services/style2/main-img/2.png" alt="" />
                    <img className="hover-img" src="assets/images/services/style2/hover-img/2.png" alt="" />
                  </div>
                </div>
                <Link to="/rd-calculator">
                <div className="services-content">
                  <div className="services-text">
                    <h3 className="title">RD Calculator</h3>
                  </div>
                  {/* <div className="services-desc">
                    <p>
                      Digital Solutions for all business domains.
                    </p>
                  </div> */}
                  <div className="serial-number">
                    02
                  </div>
                </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <div className="services-item aqua-bg">
                <div className="services-icon">
                  <div className="image-part">
                    <img className="main-img" src="assets/images/services/style2/main-img/3.png" alt="" />
                    <img className="hover-img" src="assets/images/services/style2/hover-img/3.png" alt="" />
                  </div>
                </div>
                <Link to="/due-date-calculator">
                <div className="services-content">
                  <div className="services-text">
                    <h3 className="title"><a href="#">Due Date Calculator</a></h3>
                  </div>
                  {/* <div className="services-desc">
                    <p>
                      All web development services including CMS and E-Commerce.
                    </p>
                  </div> */}
                  <div className="serial-number">
                    03
                  </div>
                </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 sm-mb-20">
              <div className="services-item paste-bg">
                <div className="services-icon">
                  <div className="image-part">
                    <img className="main-img" src="assets/images/services/style2/main-img/4.png" alt="" />
                    <img className="hover-img" src="assets/images/services/style2/hover-img/4.png" alt="" />
                  </div>
                </div>
                <div className="services-content">
                  <div className="services-text">
                    <h3 className="title"><a href="#">HRA Calculator</a></h3>
                  </div>
                  {/* <div className="services-desc">
                    <p>
                      The requirement for an user experience is to meet the needs of customer.
                    </p>
                  </div> */}
                  <div className="serial-number">
                    04
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 sm-mb-20">
              <div className="services-item purple-bg">
                <div className="services-icon">
                  <div className="image-part">
                    <img className="main-img" src="assets/images/services/style2/main-img/5.png" alt="" />
                    <img className="hover-img" src="assets/images/services/style2/hover-img/5.png" alt="" />
                  </div>
                </div>
                <Link to="/emi-calculator">
                <div className="services-content">
                  <div className="services-text">
                    <h3 className="title"><a href="#">EMI Calculator</a></h3>
                  </div>
                  {/* <div className="services-desc">
                    <p>
                      The only way to be truly satisfied is to do what you believe is great work.
                    </p>
                  </div> */}
                  <div className="serial-number">
                    05
                  </div>
                </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="services-item green-bg">
                <div className="services-icon">
                  <div className="image-part">
                    <img className="main-img" src="assets/images/services/style2/main-img/6.png" alt="" />
                    <img className="hover-img" src="assets/images/services/style2/hover-img/6.png" alt="" />
                  </div>
                </div>
                <div className="services-content">
                  <div className="services-text">
                    <h3 className="title"><a href="#">Income Tax Calculator</a></h3>
                  </div>
                  {/* <div className="services-desc">
                    <p>
                      Responsive design is an approach that suggets the design to the behaviour.
                    </p>
                  </div> */}
                  <div className="serial-number">
                    06
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}

export default Resources
