import React from 'react';


const Bde = () => {
  return (
    <>

      <div class="rs-breadcrumbs img1">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Bussiness developmen Executive</h1>
          <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">Home</a>
            </li>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">Career</a>
            </li>
            
            <li>Bussiness developmen Executive</li>
          </ul>
        </div>
      </div>

      


      {/* <!-- Specialty Section Start --> */}
      <div id="rs-about" class="rs-specialty bg18 pt-170 pb-230 md-pb-185">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              {/* <!-- Features Section Start --> */}
              <div class="rs-feature">
                <div class="row">
                  <div class="col-md-6 mt-20 pr-6  md-pb-20">
                    <div class="icon-box-area mb-20">
                      <div class="box-inner">
                        <div class="icon-area">
                          <a href="#"><img src="assets/images/features/icons/1.png" alt="" /></a>
                        </div>
                        <div class="content-part">
                          <h4 class="title"><a href="#">Lead Generation</a></h4>
                        </div>
                      </div>
                    </div>
                    <div class="icon-box-area purple-bg">
                      <div class="box-inner">
                        <div class="icon-area">
                          <a href="#"><img src="assets/images/features/icons/3.png" alt="" /></a>
                        </div>
                        <div class="content-part">
                          <h4 class="title"><a href="#">Client Relations</a></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="icon-box-area blue-bg mb-20">
                      <div class="box-inner">
                        <div class="icon-area">
                          <a href="#"><img src="assets/images/features/icons/2.png" alt="" /></a>
                        </div>
                        <div class="content-part">
                          <h4 class="title"><a href="#">Sales & Marketing</a></h4>
                        </div>
                      </div>
                    </div>
                    <div class="icon-box-area pink-bg">
                      <div class="box-inner">
                        <div class="icon-area">
                          <a href="#"><img src="assets/images/features/icons/4.png" alt="" /></a>
                        </div>
                        <div class="content-part">
                          <h4 class="title"><a href="#">Strategy and Campaigning</a></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Features Section Start --> */}
            </div>
            <div class="col-lg-6 pl-55 md-pl-15 md-pt-60">
              <div class="sec-title6 mb-10">
                
                <h2 class="title ">
                  Join us as Business Development Executive
                </h2>


              </div>
              <div class="round-text-wrapper">
                <div class="round-check-list-container">
                  <div class="round-check-list-row">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="blue" class="bi bi-check-circle-fill mr-2" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    Freshers/experienced - IT Domain Knowledge preferred
                  </div>
                </div>
                <div class="round-check-list-row">
                  <div class=" impossible-check-list-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="blue" class="bi bi-check-circle-fill mr-2" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    2 Months Paid Internship - Stipend 5000 Per Month
                  </div>
                </div>
                <div class="round-check-list-row">
                  <div class=" impossible-check-list-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="blue" class="bi bi-check-circle-fill mr-2" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    PO/Full Time Opportunity on satisfactory performance
                  </div>
                </div>
                <div class="round-check-list-row">
                  <div class=" impossible-check-list-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="blue" class="bi bi-check-circle-fill mr-2" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    Remote / Work from Home Opportunity
                  </div>
                </div>
                <div class="round-check-list-row">
                  <div class=" impossible-check-list-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="blue" class="bi bi-check-circle-fill mr-2" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    Timing - Normal Office Hours
                  </div>
                </div>
                <div class="round-check-list-row">
                  <div class=" impossible-check-list-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="blue" class="bi bi-check-circle-fill mr-2" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    Mandatory - Persoanl laptop &amp; Basic Computer skills
                  </div>
                </div>
                <div class="round-check-list-row">
                  <div class=" impossible-check-list-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="blue" class="bi bi-check-circle-fill mr-2" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    Passionate for Business Development works
                  </div>
                </div>
                <div class="mt-15 mb-10">
                  <h4 class="mb-0">Selection Process</h4>
                </div>
                <div class="round-check-list-row">
                  <div class=" impossible-check-list-text">

                    <span style={{ color: 'blue' }}  >Step 1</span> - CV Shortlisting
                  </div>
                </div>
                <div class="round-check-list-row">
                  <div class=" impossible-check-list-text">
                    <span style={{ color: 'blue' }}  >Step 2</span> - Screening Interview
                  </div>
                </div>
                <div class="round-check-list-row">
                  <div class=" impossible-check-list-text">
                    <span style={{ color: 'blue' }}  >Step 3</span> - Business Development Screening Task
                  </div>
                </div>
                <div class="round-check-list-row">
                  <div class=" impossible-check-list-text">
                    <span style={{ color: 'blue' }}  >Step 4</span> - Offer Letter &amp; Onboarding
                  </div>
                </div>
              </div>
              <a href="/learning/registration/mbapaidinternship">
                <div class="btn-part mt-3">
                  <a class="readon submit" href=" ">Enroll Now</a>
                </div>
              </a>
            </div>


          </div>
        </div>
      </div>

      {/* <!-- Specialty Section End --> */}


    </>
  )
}

export default Bde;
