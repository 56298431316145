
import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import tickimage from "../../assets/img/learning/tick.svg";
import axios from 'axios';
import image1 from "../../assets/img/workshop/iitk.jpg";
import image2 from "../../assets/img/workshop/ieeenpl2.jpg";
import image3 from "../../assets/img/workshop/nitb.jpg";
import image4 from "../../assets/img/workshop/rits.jpg";
import image5 from "../../assets/img/workshop/sagar1.jpg";
import image6 from "../../assets/img/workshop/sagar2.jpg";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";



const DynamicLearning = () => {
  let { learning_type, course_name } = useParams();
  const [blogsData, setBlogsData] = useState([]);
  // const [coursename, setCoursename] = useState([]);
  //console.log('learning_type:', learning_type);
  //console.log('course_name:', course_name);

  const fetchBlogs = async () => {
    const url = `${process.env.REACT_APP_API_URL}pages/fetchpagecontent`;
    const obj = { pagetype: learning_type, pagesubtype: course_name };
    //console.log('learning_type:', learning_type);
    try {
      const response = await axios.post(url, obj);
      setBlogsData(response.data[0]);
      //console.log('learning_type:', response);
    } catch (err) {
      console.error('Error in API Call:', err);

    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    //console.log('blogsData - ', blogsData)
  }, [blogsData]);


  return (
    <>
      <div class="rs-breadcrumbs img1">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">{blogsData.pagename}</h1>
          <h4 className="text-white">
            {blogsData.pagesubtitle}
          </h4>
          {/* <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">
                Home
              </a>
            </li>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">
                learning
              </a>
            </li>
            <li>CorporateTraining</li>
          </ul> */}
        </div>
      </div>

      <div dangerouslySetInnerHTML={{ __html: blogsData.pagecontent }} />
       
      <div class="rs-project bg6 style2 pt-120 pb-120 md-pt-80 md-pb-80">
        <div class="container">
          <div class="sec-title2 text-center mb-45">
            <h2 class="title title2 white-color">
              Our Corporate Training Program Glimpses
            </h2>
          </div>

          <div className="companies-images">
            <Swiper
              slidesPerView={3}
              pagination={{
                type: "fraction",
              }}
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img src={image1} alt="Logo 1" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={image2} alt="Logo 2" />
              </SwiperSlide>

              <SwiperSlide>
                <img src={image6} alt="Logo 6" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

    </>
  );
};

export default DynamicLearning;
