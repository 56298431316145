import React from "react";
import { useState,useEffect } from "react";
import { Formik, Field, Form } from "formik";
import axios from 'axios';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { FiRefreshCw } from 'react-icons/fi';


const CareerForm = () => {
  const [apistatus, setApistatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [resume, setResume] = useState(null);
  const [userCaptchaValue,setUserCaptchaValue] = useState(null)

  const formikInitialValues = {
    name: "",
    email: "",
    gender: "",
    mobile: "",
    whatsapp_mobile: "",
    city: "",
    hometown: "",
    college: "",
    university: "",
    passing_year: "",
    branch: "",
    linkedinid: "",
    githubid: "",
    referredby: "",
    currentcompany: "",
    yearsOfExperience: "",
    currentDesignation: "",
    noticePeriod: "",
    currentSalary: "",
    skills: "",
    dob: "",
    workLocation: "",
    typePartFull: "",
    typeFullIntern: "",
    jobType: "",
  };
  useEffect(() => {
    loadCaptchaEnginge(8,"yellow","black");
  }, []);

  const handleFileChange = (e) => {
    setResume(e.target.files[0]);
  };

  const submitCareer = async (values) => {
    setLoader(true);
    const url = process.env.REACT_APP_API_URL + "career/register";
    console.table(values);
    const userCaptchaValue = values.user_captcha_input;
    setUserCaptchaValue(userCaptchaValue)
    if (!validateCaptcha(userCaptchaValue)) {
      alert("Entered Captcha is Not Correct");
    } else{
    const formData = new FormData();
    for (const key in values) {
      formData.append(key, values[key]);
    }
    formData.append('resume', resume);

    try {
      const response = await axios.post(url,formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setApistatus(true);
        setLoader(false);
        return {
          error: false,
        };
      } else {
        setError(true);
        setLoader(false);
        return {
          error: true,
        };
      }
    } catch (err) {
      setError(true);
      setLoader(false);
      console.log("Error In API Call - ", err);
    }
  }
  };

  return (
    <>
      <div className="rs-breadcrumbs img1">
        <div className="breadcrumbs-inner text-center">
          <h1 className="page-title">Start Your Career With Us</h1>
          <h4 className="text-white">
            Please, register here. we will get back if we have any matching
            opportunity.
          </h4>
        </div>
      </div>

      <div className="rs-contact-wrap bg5 pt-120 pb-390 md-pt-80">
        <div className="container">
          <div className="sec-title2 text-center mb-30">
            <span className="sub-text style-bg white-color">Career</span>
            <h2 className="title white-color">Start Your Career With Us</h2>
          </div>
        </div>
      </div>

      <div className="rs-video-wrap style2 inner  md-pb-80">
        <div className="container">
          <div className="row margin-0 gray-color px-4 py-3 rounded-top ">
            <div className="col-lg-12 padding-0">
              <section className="contact-section">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <Formik
                        initialValues={formikInitialValues}
                        onSubmit={async (values, { resetForm }) => {
                          const { error } = await submitCareer(values);
                          if (!error) {
                            resetForm();
                          }
                        }}
                      >
                        <Form>
                          <br></br>
                          <div class="row">
                          <div className="form-group col-md-6">
                            <label className="col-sm-4 col-form-label">
                              Name 
                            </label>
                            <br/>
                            {/* <div className="form-control "> */}
                              <Field
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Enter Full Name"
                                required
                              />
                            {/* </div> */}
                          </div>
                          <div className="form-group col-md-6">
                            <label className="col-sm-4 col-form-label">
                              Email
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="email"
                                type="email"
                                className="form-control "
                                placeholder="Enter Gmail"
                                required
                              />
                            {/* </div> */}
                          </div>
                        </div>
                        <div class="row">
                          <div className="form-group col-md-6">
                            <span
                              id="my-radio-group"
                              className="col-sm-4 col-form-label"
                            >
                              Gender
                            </span>
                            <div className="col-sm-8">
                              <span
                                className="row ml-1"
                                role="group"
                                aria-labelledby="my-radio-group"
                              >
                                <div className="mr-4">
                                  <Field
                                    type="radio"
                                    name="gender"
                                    value="male"
                                    className="form-check-input mr-2 position-relative m-0"
                                  />
                                  <label>Male</label>
                                </div>

                                <div className="mr-4">
                                  <Field
                                    type="radio"
                                    name="gender"
                                    value="female"
                                    className="form-check-input mr-2 position-relative m-0"
                                  />
                                  <label>Female</label>
                                </div>
                              </span>
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <label className="col-sm-4 col-form-label">
                              Mobile
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="mobile"
                                type="text"
                                className="form-control"
                                placeholder="Enter Mobile No"
                                required
                              />
                            {/* </div> */}
                          </div>
                        </div>
                        <div class="row">
                          <div className="form-group col-md-6">
                            <label className="col-sm-4 col-form-label">
                              WhatsApp Mobile
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="whatsapp_mobile"
                                type="text"
                                className="form-control p-4
                                rounded-8
                                border border-info-subtle"
                                placeholder="Enter WhatsApp No"
                              />
                            {/* </div> */}
                          </div>

                          <div className="form-group col-md-6">
                            <label className="col-sm-4 col-form-label">
                              Date of Birth
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="dob"
                                type="date"
                                className="form-control p-4
                                rounded-8
                                border border-info-subtle"
                                placeholder=""
                              />
                            {/* </div> */}
                          </div>
                        </div>
                        <div class="row">
                          <div className="form-group col-md-6">
                            <label className="col-sm-4 col-form-label">
                              Present Address
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="city"
                                type="text"
                                className="form-control p-4
                                rounded-8
                                border border-info-subtle"
                                placeholder="Enter Present Address"
                                required
                              />
                            {/* </div> */}
                          </div>
                          <div className="form-group col-md-6">
                            <label className="col-sm-4 col-form-label">
                              Permanent Address
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="hometown"
                                type="text"
                                className="form-control p-4
                                rounded-8
                                border border-info-subtle"
                                placeholder="Enter Permanent Address"
                              />
                            {/* </div> */}
                          </div>
                        </div>
                        <div class="row">
                          <div className="form-group col-md-6">
                            <label className="col-sm-4 col-form-label">
                              College
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="college"
                                type="text"
                                className="form-control p-4
                                rounded-8
                                border border-info-subtle"
                                placeholder="College Name"
                                required
                              />
                            {/* </div> */}
                          </div>
                          <div className="form-group col-md-6">
                            <label className="col-sm-4 col-form-label">
                              University
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="university"
                                type="text"
                                className="form-control p-4
                                rounded-8
                                border border-info-subtle"
                                placeholder="University Name"
                              />
                            {/* </div> */}
                          </div>
                          </div>
                          <div class="row">
                          <div className="form-group col-md-6">
                            <label className="col-sm-4 col-form-label">
                              Passing Year
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="passing_year"
                                type="text"
                                className="form-control p-4
                                rounded-8
                                border border-info-subtle"
                                placeholder="College Passing Year"
                                required
                              />
                            {/* </div> */}
                          </div>
                          <div className="form-group col-md-6">
                            <label className="col-sm-4 col-form-label">
                              Branch / Stream
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="branch"
                                type="text"
                                className="form-control p-4
                                rounded-8
                                border border-info-subtle"
                                Enter
                                placeholder="Branch/Stream like B Tech/BCA/MCA/M Tech and Branch"
                                required
                              />
                            {/* </div> */}
                          </div>
                          </div>
                          <div class="row">
                          <div className="form-group col-md-6">
                            <label className="col-sm-4 col-form-label">
                              Linkedin Profile URL
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="linkedinid"
                                type="text"
                                className="form-control p-4
                                rounded-8
                                border border-info-subtle"
                                placeholder="Your Linkedin Profile URL (if any)"
                              />
                            {/* </div> */}
                          </div>
                          <div className="form-group col-md-6">
                            <label className="col-sm-4 col-form-label">
                              Github Profile URL
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="githubid"
                                type="text"
                                className="form-control p-4
                                rounded-8
                                border border-info-subtle"
                                placeholder="Your Github Profile URL (if any)"
                              />
                            {/* </div> */}
                          </div>
                          </div>
                          <div class="row">
                          <div className="form-group col-md-6">
                            <label className="col-sm-6 col-form-label">
                              Where Did You Hear About Us
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="referredby"
                                type="text"
                                className="form-control p-4
                                rounded-8
                                border border-info-subtle"
                                placeholder="LinkedIn/Instagram/WhatsApp/Others"
                              />
                            {/* </div> */}
                            </div>
                          
                          <div className="form-group col-md-6">
                            <label className="col-sm-4 col-form-label">
                              Current Company
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="currentcompany"
                                type="text"
                                className="form-control p-4
                                rounded-8
                                border border-info-subtle"
                                placeholder="Freshers can leave it blank"
                              />
                            {/* </div> */}
                          </div>
                          </div>
                          <div class="row">
                          <div className="form-group col-md-6">
                            <label className="col-sm-4 col-form-label">
                              Years of Experience
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="yearsOfExperience"
                                type="text"
                                className="form-control p-4
                                rounded-8
                                border border-info-subtle"
                                placeholder="e.g. - 1.3 Years"
                              />
                            {/* </div> */}
                          </div>
                          <div className="form-group col-md-6">
                            <label className="col-sm-4 col-form-label">
                              Current Designation
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="currentDesignation"
                                type="text"
                                className="form-control p-4
                                rounded-8
                                border border-info-subtle"
                                placeholder="Current Designation"
                              />
                            {/* </div> */}
                          </div>
                          </div>
                          <div class="row">
                          <div className="form-group col-md-6">
                            <label className="col-sm-6 col-form-label">
                              Notice Period (In Months)
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="noticePeriod"
                                type="text"
                                className="form-control p-4
                                rounded-8
                                border border-info-subtle"
                                placeholder="Notice Period (In Months)"
                              />
                            {/* </div> */}
                          </div>
                          <div className="form-group col-md-6">
                            <label className="col-sm-4 col-form-label">
                              Current Salary
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="currentSalary"
                                type="text"
                                className="form-control p-4
                                rounded-8
                                border border-info-subtle"
                                placeholder="In LPA"
                              />
                            {/* </div> */}
                          </div>
                          </div>
                          <div class="row">
                          <div className="form-group col-md-6">
                            <label className="col-sm-4 col-form-label">
                              Mention Your Skills
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="skills"
                                type="text"
                                className="form-control p-4
                                rounded-8
                                border border-info-subtle"
                                placeholder="Write name of your major skills"
                                required
                              />
                            {/* </div> */}
                          </div>
                          <div className="form-group col-md-6">
                            <label className="col-sm-6 col-form-label">
                              Work Location Preferences
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="workLocation"
                                type="text"
                                className="form-control p-4
                                rounded-8
                                border border-info-subtle"
                                placeholder="Write name of preferred cities. Write Remote if looking for WFH"
                              />
                            {/* </div> */}
                          </div>
                          </div>
                          <div class="row">
                          <div className="form-group col-md-6">
                            <label
                              id="my-radio-group"
                              className="col-sm-6 col-form-label font-weight-bold"
                            >
                              Looking for Full Time / Part Time
                            </label>

                            <div className="col-sm-8">
                              <span
                                className="row ml-1"
                                role="group"
                                aria-labelledby="my-radio-group"
                              >
                                <div className="mr-4">
                                  <Field
                                    type="checkbox"
                                    name="typePartFull"
                                    value="Full Time"
                                    className="form-check-input m-0 mr-2 p-5 position-relative"
                                  />
                                  <label className="m-0">Full Time</label>
                                </div>

                                <div>
                                  <Field
                                    type="checkbox"
                                    name="typePartFull"
                                    value="Part Time"
                                    className="form-check-input m-0 mr-2 ml-5 position-relative"
                                  />
                                  <label className="m-0">Part Time</label>
                                </div>
                              </span>
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <span
                              id="my-radio-group"
                              className="col-sm-6 col-form-label font-weight-bold"
                            >
                              Looking for Job / Internship
                            </span>
                            <div className="col-sm-8">
                              <span
                                className="row ml-1"
                                role="group"
                                aria-label="my-radio-group"
                              >
                                <div className="mr-4">
                                  <Field
                                    type="checkbox"
                                    name="typeFullIntern"
                                    value="Job"
                                    className="form-check-input m-0 mr-2 p-5 position-relative"
                                  />
                                  <label>Job</label>
                                </div>

                                <div>
                                  <Field
                                    type="checkbox"
                                    name="typeFullIntern"
                                    value="Internship"
                                    className="form-check-input m-0 mr-2 p-5 position-relative"
                                  />
                                  <label>Internship</label>
                                </div>
                              </span>
                            </div>
                          </div>
                          </div>
                        <div class="row">
                          <div className="form-group col-md-6">
                            <span
                              id="my-radio-group"
                              className="col-sm-6 col-form-label font-weight-bold"
                            >
                              Job Domain
                            </span>
                            <div className="col-sm-8">
                              <span
                                className="row ml-1"
                                role="group"
                                aria-label="my-radio-group"
                              >
                                <div className="mr-4">
                                  <Field
                                    type="radio"
                                    name="jobType"
                                    value="Technology"
                                    className="form-check-input m-0 mr-2 p-5 position-relative"
                                  />
                                  <label>Technology</label>
                                </div>

                                <div className="mr-4">
                                  <Field
                                    type="radio"
                                    name="jobType"
                                    value="Business Development"
                                    className="form-check-input m-0 mr-2 p-5 position-relative"
                                  />
                                  <label>Business Development</label>
                                </div>

                                <div className="mr-4">
                                  <Field
                                    type="radio"
                                    name="jobType"
                                    value="HR"
                                    className="form-check-input m-0 mr-2 p-5 position-relative"
                                  />
                                  <label>HR</label>
                                </div>

                                <div className="mr-4">
                                  <Field
                                    type="radio"
                                    name="jobType"
                                    value="Marketing"
                                    className="form-check-input m-0 mr-2 p-5 position-relative"
                                  />
                                  <label>Marketing</label>
                                </div>

                                <div className="mr-4">
                                  <Field
                                    type="radio"
                                    name="jobType"
                                    value="Trainer/Teaching"
                                    className="form-check-input m-0 mr-2 p-5 position-relative"
                                  />
                                  <label>Trainer/Teaching</label>
                                </div>
                              </span>
                            </div>
                           </div>
                           <div className="form-group col-md-5">
                            <label className="col-sm-2 col-form-label font-weight-bold">
                              Resume
                            </label>
                            {/* <div className="col-sm-8"> */}
                              <Field
                                name="resume"
                                type="file"
                                className="form-control form-control-lg"
                                onChange={handleFileChange}
                                accept=".pdf,.doc"
                                size="2"
                                required
                              />
                              <p className="text-danger" >* only pdf and doc file allowed *</p>
                            {/* </div> */}
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="form-group col-md-6 font-weight-bold">Captcha</label>
                        <div className="col-lg-8 mb-30 col-md-6 col-sm-6">
                        < LoadCanvasTemplateNoReload />
                        <div onClick={() => loadCaptchaEnginge(8,"yellow","black",'lower')} className="text-primary" style={{ cursor: "pointer"}}>
                          <FiRefreshCw className="text-primary" /> ReLoadCaptcha
                        </div>
                            <Field name="user_captcha_input" type="text" className="form-control" placeholder="Please Enter Captcha" required />
                        </div>
                      </div>                             
                          <br></br>
                          <button type="submit" className="btn btn-success btn-lg btn-block">
                            Register
                          </button>
                          <br></br>
                          <br></br>
                        </Form>
                      </Formik>

                      {apistatus && validateCaptcha(userCaptchaValue) && (
                        <div className="alert alert-success" role="alert">
                          You are successfully registered.
                        </div>
                      )}

                      {error && (
                        <div className="alert alert-danger" role="alert">
                          Server Error. Please, try after sometime.
                        </div>
                      )}
                    </div>

                    {loader && validateCaptcha(userCaptchaValue) && <div className="loading">Loading...</div>}
                    {loader && !validateCaptcha(userCaptchaValue) && <div className="alert alert-danger" role="alert">please enter valid Captcha</div>}

                    <div className="col-12 text-center">
                      <h3 className="contact-title">
                        <br></br>Or<br></br>Drop CV at
                        hashedbitinnovations@gmail.com<br></br>
                      </h3>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerForm;
